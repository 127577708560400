import { useState } from "react";
import {
    useFetchAdsAccountsQuery, useFetchProfilesQuery, useAddAdsAccountMutation,
    useUpdateAdsAccountMutation, useUnarchieveAdsAccountMutation
} from "../../store";
export default function AccountsPage() {
    const { data, error } = useFetchAdsAccountsQuery()
    const { data: dataProf, refetch: refetchProf } = useFetchProfilesQuery();
    const [addAccount] = useAddAdsAccountMutation()
    const [archieveAccount] = useUpdateAdsAccountMutation()
    const [unarchieveAccount] = useUnarchieveAdsAccountMutation()
    const [filter, setFilter] = useState(true)
    let accountsDiv = <div></div>

    const onFilterClick = () => {
        setFilter(!filter)
    }
    const onAuthorizeClick = async (accountId, accountName, marketplaceId, sellerId) => {
        //console.log(accountId, accountName, countryCode, sellerId)
        await addAccount({ accountId, accountName, marketplaceId, sellerId })
        refetchProf()
    }
    const onArchieveClick = async (accountId) => {
        await archieveAccount({ accountId })
        refetchProf()
    }
    const onUnarchieveClick = async (accountId) => {
        await unarchieveAccount({ accountId })
        refetchProf()
    }
    if (error) {
        console.log(error)
        if (error.data && error.data.error) {
            accountsDiv = <div className="text-red-600 font-bold">
                {error.data.error}
            </div>
        } else {
            accountsDiv = <div className="text-red-600 font-bold">
                Error!
            </div>
        }

    } else if (data && dataProf) {
        const sortedData = [...data].sort((a, b) => {
            const x = a.countryCode
            const y = b.countryCode
            const w = a.accountInfo.name
            const z = b.accountInfo.name
            const existing1 = dataProf.find(elem => elem.accountId === a.profileId)
            const existing2 = dataProf.find(elem => elem.accountId === b.profileId)
            if (existing1 && existing2) {
                return w.localeCompare(z)
            } else if (existing1) {
                return -1
            } else if (existing2) {
                return 1
            } else if (y !== x) {
                return y.localeCompare(x)
            } else {
                return w.localeCompare(z)
            }

        })
        //console.log(sortedData)
        accountsDiv = sortedData.map(account => {
            const existing = dataProf.find(elem => elem.accountId === account.profileId)
            if (existing && existing.isArchieved && filter) {
                return null
            }
            let divClass = "shadow m-3 p-3 rounded-xl border flex flex-row justify-between items-center"
            if (existing && existing.isArchieved) {
                divClass = divClass + " bg-orange-600 text-white"
            } else if (existing) {
                divClass = divClass + " bg-green-600 text-white"
            }
            const accountId = account.profileId
            const accountName = account.accountInfo.name + " - " + account.countryCode
            const marketplaceId = account.accountInfo.marketplaceStringId
            const sellerId = account.accountInfo.id
            return <div key={account.profileId} className={divClass}>
                <div>
                    <div>
                        {account.profileId}
                    </div>
                    <div className="font-bold">
                        {account.accountInfo.name}
                    </div>
                    <div className="text-xxs">
                        {account.accountInfo.id}
                    </div>
                    <div className="text-xxs">
                        {account.countryCode}
                    </div>
                </div>
                <div>
                    {!existing && <button onClick={() => onAuthorizeClick(accountId, accountName, marketplaceId, sellerId)} className="mx-1 p-1 p-2 bg-green-600 rounded-xl text-white text-xs hover:bg-green-700">Authorize</button>}
                    {(existing && !existing.isArchieved) && <button onClick={() => onArchieveClick(accountId)} className="mx-1 p-1 p-2 bg-white rounded-xl text-gray-900 text-xs hover:bg-gray-100">Archieve</button>}
                    {(existing && existing.isArchieved) && <button onClick={() => onUnarchieveClick(accountId)} className="mx-1 p-1 p-2 bg-white rounded-xl text-gray-900 text-xs hover:bg-gray-100">Unarchieve</button>}
                </div>
            </div>
        })
    }

    return (
        <div>
            <div className="flex justify-center m-3 py-2 rounded-xl font-bold shadow">Seller Accounts</div>
            <div className="flex flex-col m-2">
                <div className='px-5 py-2' >
                    {data &&
                        <div className="flex flex-row items-center">
                            <div className="m-3">
                                Ads Accounts List
                            </div>
                            <div>
                                <button className="p-1 px-2 rounded-xl text-xxs border hover:shadow" onClick={onFilterClick}>
                                    {filter ? "Show Archieved" : "Hide Archieved"}
                                </button>
                            </div>
                        </div>
                    }
                    <div className="overflow-scroll max-h-screen3/4">
                        {accountsDiv}
                    </div>
                </div>
            </div>
        </div>
    );
}

