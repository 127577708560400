import classNames from "classnames";
//import { useSelector } from "react-redux";
import useNavigation from '../hooks/use-navigation';

function Link({ to, children, className, activeClassName }) {
    const { navigate, currentPath } = useNavigation();
    //const accountId = useSelector(state => state.accounts.accountId);

    const classes = classNames('text-black', className,
        currentPath === to.split("?")[0] && activeClassName
    );

    const onClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            return;
        }

        event.preventDefault();
        navigate(to.split("?")[0]);
    };

    return <a className={classes} href={to} onClick={onClick} >{children}</a>
};

export default Link;