import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GoSync } from "react-icons/go";
import { FcOk, FcHighPriority } from "react-icons/fc";
import { useUpdateSkuPriceMutation } from "../../store";
import { ImCancelCircle, ImCheckmark, ImPencil } from "react-icons/im";

function EditForm({ target, char, sku, asin, productType, requestType }) {
    const [updateSkuPrice, response] = useUpdateSkuPriceMutation()
    const accountId = useSelector(state => state.accounts.accountId);
    const [formValue, setFormValue] = useState(target)
    const [showError, setShowError] = useState(false)

    const [submittable, setSubmittable] = useState(true);
    const [updateBidContentVisibility, setUpdateBidContentVisibility] = useState('edit-button');

    let startDate, endDate

    useEffect(() => {
        if (target) {
            setFormValue(target)
            setUpdateBidContentVisibility('edit-button')
        }
    }, [target])

    useEffect(() => {
        if (response.isError || (response.data && (response.data.errors || response.data.status === "INVALID"))) {
            setFormValue(target)
        }
    }, [response, target])

    const classname = 'w-[5vw] mr-1 border-r border-t border-b rounded-r-xl px-2 p-1 text-center focus:outline-0';
    const [inputClass, setInputClass] = useState(classname);

    const classname2 = 'flex justify-center bg-gray-100 border-l border-t border-b rounded-l-xl px-1 items-center w-[2vw]';
    const [inputClass2, setInputClass2] = useState(classname2);

    const onErrorClick = () => {
        console.log("error clicked")
        setShowError(!showError)
    }

    const onApproveClick = () => {
        if (submittable) {
            //create an api to update the existing target acos
            updateSkuPrice({ accountId, formValue, sku, asin, productType, requestType, startDate, endDate });
            setInputClass(classname);
            setInputClass2(classname2);
            setUpdateBidContentVisibility('edit-button');
        } else {
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
        }
    };

    const onCancelClick = () => {
        setInputClass(classname);
        setInputClass2(classname2);
        setUpdateBidContentVisibility('edit-button');
        setFormValue(target);
    };

    const onEditClick = () => {
        setUpdateBidContentVisibility('form');
    };

    const onChange = (event) => {
        const value = event.target.value;

        if (!value || value === '0' || value.slice(value.length - 1) === '.' || value < 10 || value > 9999) {
            //setDisabled(true);
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
            setSubmittable(false);
            //make input border red
        } else if (!isNaN(value) && value.length < 7) {
            //setDisabled(false);
            setInputClass((classname + ' border-green-600'));
            setInputClass2((classname2 + ' border-green-600'));
            setSubmittable(true);
            //make input border gray again
        }
        if (!isNaN(value) && value.length < 7) {
            setFormValue(value);
        }
    };

    return (
        <div>
            <div className="flex flex-row">
                {updateBidContentVisibility === 'edit-button'
                    ? <div className="flex items-center justify-center drop-shadow w-[7vw] h-7 mr-1 border rounded-xl bg-white px-2 p-1">
                        <div >
                            {char === '%' ? (formValue || "") + "" + char : (formValue && char + "" + formValue)}
                        </div>
                    </div>
                    : <div className="flex flex-row drop-shadow">

                        <div className={inputClass2}>
                            {char}
                        </div>
                        <input
                            type="number"
                            className={inputClass}
                            value={formValue}
                            onChange={onChange} >
                        </input>
                    </div>
                }
                <div className="flex items-center">
                    <button
                        onClick={onEditClick}
                        className='mx-1'
                        hidden={updateBidContentVisibility !== 'edit-button'}>
                        <ImPencil />
                    </button>
                    <button
                        onClick={onApproveClick}
                        className='mx-1'
                        hidden={updateBidContentVisibility !== 'form'}>
                        <ImCheckmark />
                    </button>
                    <button
                        onClick={onCancelClick}
                        className='mx-1'
                        hidden={updateBidContentVisibility !== 'form'}>
                        <ImCancelCircle />
                    </button>
                    {
                        response.isLoading
                            ? <div className="flex items-center ml-1"><GoSync className="animate-spin" /></div>
                            :
                            (response.isSuccess && response.data && response.data.status === "ACCEPTED")
                                ? <div className="flex items-center ml-1"><FcOk /></div>
                                : (response.isError || (response.data && (response.data.errors || response.data.status === "INVALID")))
                                    ? <div title={response.data.errors && response.data.errors[0].message} className="flex items-center ml-1 cursor-help" onClick={onErrorClick}><FcHighPriority /></div>
                                    : null
                    }
                </div>
            </div>
        </div>
    )
}

export default EditForm