import { useState } from "react";
import { useSelector } from "react-redux";
import { FiDownload } from "react-icons/fi";
import Modal from "../Modal";


export default function ListingPanel({ div, list, panelName }) {
    const accountName = useSelector(state => state.accounts.accountName);
    const [showModal, setShowModal] = useState(false)
    const onClick = () => {
        setShowModal(true);
    }

    const onDownloadClick = () => {
        //console.log("downloading the csv file")
        let headers = ['ASIN']
        let listingInfo = list.map(listing => {
            return listing.asin
        })
        let data = [...headers, ...listingInfo].join('\n')
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        const date = new Date().toISOString().split('T')[0]
        const fileName = `${panelName} ${accountName} ${date}.csv`;
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    function onChange() {
        setShowModal(false);
    };

    let modal = <Modal onChange={onChange} actionBar={<button onClick={() => setShowModal(false)} className="shadow p-2 rounded-lg">Close</button>}>
        <div className="overflow-scroll">
            {div}
        </div>
    </Modal>;

    return (
        <div className="flex items-center">
            {showModal && modal}
            <button className={list.length === 0
                ? "text-xs p-2 break-words rounded-xl bg-gray-200 text-white shadow"
                : "text-xs p-2 break-words rounded-xl bg-blue-400 text-white shadow"
            } disabled={list.length === 0} onClick={onClick}>{panelName + ": " + div.length}</button>
            <button className={list.length === 0
                ? "text-lg text-gray-300 mx-1"
                : "text-lg mx-1"
            } onClick={onDownloadClick} disabled={list.length === 0}><FiDownload></FiDownload></button>
        </div>
    )
}