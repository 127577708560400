//import { useSelector } from "react-redux";
import CampaignEnableToggle from "../CampaignEnableToggle2"
import { RiRefreshFill } from "react-icons/ri";
import { GoSync } from "react-icons/go";


function CampaignTableBulkUpdate({ selectedFlatRows, refetch, isFetching, campList, budgetUsage, portfolioId, searchText, length }) {
    //console.log("campaign bulk update page")
    //const isEnableCmpOnly = useSelector(state => state.accounts.isEnableCmpOnly);
    //console.log(isEnableCmpOnly)

    /*
    const onSubmitAllClick = async () => {
        console.log("submit clicked")
    };
    */

    //ADD CAMPAIGN BUDGET INFO DIV
    //Display campaigns budget total + used total

    let totalBudget, unfTotalBudget
    if (campList && campList.campaigns) {
        //console.log(campList.campaigns)
        totalBudget = 0
        unfTotalBudget = 0
        if (portfolioId && searchText) {
            campList.campaigns.filter(campaign => (campaign.portfolioId === portfolioId.toString() && campaign.name.includes(searchText))).forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        } else if (portfolioId) {
            campList.campaigns.filter(campaign => campaign.portfolioId === portfolioId.toString()).forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        } else if (searchText) {
            campList.campaigns.filter(campaign => campaign.name.includes(searchText)).forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        } else {
            campList.campaigns.forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        }
        campList.campaigns.forEach(campaign => {
            unfTotalBudget = unfTotalBudget + campaign.budget.budget
        })

    }

    /*
    const submitAllButton = <button className={selectedFlatRows.length === 0 ? 'p-2 m-2 bg-gray-100 text-gray-400 rounded-lg' : 'p-2 m-2 bg-sky-500 text-white rounded-lg'}
        onClick={onSubmitAllClick} >
        <div className="flex flex-row items-center">
            Submit All
        </div>
    </button>
    */

    let totalBudgetClass = "text-end h-6"
    let totalBudgetPercClass = "text-3xs text-gray-500 h-6 p-1"

    return (
        <div className='flex flex-row items-center p-1 mt-2 rounded-xl bg-white h-16 w-auto bg-yellow-50'>
            <div className='p-1 mx-1 w-28 text-gray-500 text-xxs' >{length} Campaigns</div>
            {
                //submitAllButton
            }
            <div className="mx-2"><CampaignEnableToggle></CampaignEnableToggle></div>
            <div onClick={() => refetch()} className="mx-3 text-2xl hover:cursor-pointer w-fit">
                {isFetching ? <GoSync className="animate-spin" /> : <RiRefreshFill></RiRefreshFill>}
            </div>
            {
                totalBudget
                    ? <div className="flex flex-row border items-center rounded-lg shadow px-3 mx-3">
                        <div className="flex flex-col">
                            <div className={totalBudgetClass}>
                                ${totalBudget.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                            </div>
                            <div className={totalBudgetClass + " text-gray-500"}>
                                ${budgetUsage.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className={totalBudgetPercClass}>
                                (%{(totalBudget / unfTotalBudget * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })})
                            </div>
                            <div className={totalBudgetPercClass}>
                                (%{(budgetUsage / totalBudget * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })})
                            </div>
                        </div>
                    </div>
                    : <div></div>
            }


        </div>
    );
}

export default CampaignTableBulkUpdate;