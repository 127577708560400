import { useState } from "react";
import { useFetchUser2Query, useAddSellerAccountMutation } from "../../store";
export default function OauthPage() {
    const { data } = useFetchUser2Query()
    const [search] = useState(window.location.search);
    const [addAccount] = useAddSellerAccountMutation(search)

    let addedUserGoogleId, addedUserGoogleName, addedUserGoogleEmail, spapi_oauth_code, state, selling_partner_id
    if (search && data) {
        console.log(data)
        addedUserGoogleId = data.googleId
        addedUserGoogleName = data.googleName
        addedUserGoogleEmail = data.googleEmails[0].value
        spapi_oauth_code = search.split("spapi_oauth_code=")[1].split("&")[0]
        state = search.split("state=")[1].split("&")[0]
        selling_partner_id = search.split("selling_partner_id=")[1].split("&")[0]
        console.log(spapi_oauth_code, " - ", state, " - ", selling_partner_id)
        console.log(addedUserGoogleId, " - ", addedUserGoogleName, " - ", addedUserGoogleEmail)
        //addAccount({ addedUserGoogleId, addedUserGoogleName, addedUserGoogleEmail, spapi_oauth_code, state, selling_partner_id })
        //if (response.isSuccess) {
        //TAKE US TO WELCOME PAGE --- PROVIDE FURTHER STEPS

        //} else if (response.isError) {
        //TAKE US TO ERROR PAGE AND EXPLAIN WHAT TO DO
        //GO BACK TO WELCOME PAGE AND ASK FOR AUTHORIZE
        //}
    }
    const onClick = () => {
        addAccount({ addedUserGoogleId, addedUserGoogleName, addedUserGoogleEmail, spapi_oauth_code, state, selling_partner_id })
    }

    return <div>
        <div className="m-2 shadow rounded-xl text-center p-1">
            <div>Oauth Page</div>
        </div>
        <div className="flex justify-center">
            <button className="border rounded-xl p-1 px-2" onClick={onClick}>Proceed</button>
        </div>
    </div>
}

/*
<AddSellerAccount addedUserGoogleEmail={addedUserGoogleEmail}
            addedUserGoogleId={addedUserGoogleId} addedUserGoogleName={addedUserGoogleName}
            state={state} selling_partner_id={selling_partner_id}
            spapi_oauth_code={spapi_oauth_code}></AddSellerAccount>
*/