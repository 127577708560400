import { IoCopyOutline } from "react-icons/io5";
import SkusDiv from "./SkusDiv";
import SalesRank from "../SalesRank";
export default function AsinDiv({ index1, asin }) {
    let classNameAsin = "text-xs p-2 basis-8p break-words overflow-hidden"
    const onCopyClick = () => {
        console.log("onCopyClicked")
        let text = document.getElementById(asin.asin)
        //text.select()
        //console.log(text)
        navigator.clipboard.writeText(text.id)
    }

    return <div key={index1} className="shadow border rounded-xl my-3">
        <div className="flex flex-row">
            <div className="text-xs p-2 w-[6vw] max-h-[12vh] rounded-xl overflow-hidden">
                <a href={"https://www.amazon.com/dp/" + asin.asin} rel='noreferrer' target="_blank">
                    <img src={asin.main_image} alt="main_image" className="rounded-xl shadow-lg" />
                </a>
            </div>
            <div className={classNameAsin}>
                <div className="w-fit">
                    <div className="flex items-center">
                        <div className="">
                            <a href={"https://www.amazon.com/dp/" + asin.asin} rel='noreferrer' target="_blank">
                                <div className='flex hover:text-sky-600 hover:cursor-pointer' id={asin.asin} >{asin.asin}</div>
                            </a>
                        </div>
                        <div className="hover:cursor-pointer w-fit mx-2" onClick={onCopyClick}><IoCopyOutline /></div>
                    </div>
                    <div className="border my-1 w-fit p-1 shadow rounded-md">
                        <div className="text-3xs text-gray-500">30-Day Sales</div>
                        <div className="text-5xs text-gray-500">{asin.startDate.substring(0, 10)} - {asin.endDate.substring(0, 10)}</div>
                        <div className="text-xs font-bold">${asin.totalSales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                        <div className="text-xxs">{asin.totalUnitsOrdered.toLocaleString(undefined, { maximumFractionDigits: 0 })} Units</div>
                    </div>
                    {
                        asin.totalQuantity === 0
                            ? <div className="text-red-600 font-bold">Out of Stock</div>
                            : <div>Quantity: {asin.totalQuantity}</div>
                    }
                </div>
            </div>
            <div className="text-xs p-2 basis-30p text-justify">
                <div className="mb-3">{asin.item_name}</div>
                <div className="flex flex-row">
                    <div className="rounded-xl border shadow p-1 px-2">
                        <div className="text-xxs">Product Type: {asin.product_type}</div>
                        <div className="text-xxs">Category: {asin.item_type_keyword}</div>
                        <div className="text-xxs">UPC/EAN: {asin.upc || asin.ean}</div>
                    </div>
                    <div className="mx-3">
                        <div className="rounded-xl border shadow p-1 px-2"><SalesRank salesRanks={asin.salesRanks} id={asin.asin}></SalesRank></div>
                    </div>
                </div>
            </div>
            <div className="text-xs p-2 basis-7p break-words overflow-hidden">{asin.parentAsin}</div>
            <div className="text-xs p-2 basis-7p break-words overflow-hidden">{asin.size}</div>
            <div className="text-xs p-2 basis-7p break-words overflow-hidden">{asin.color}</div>
            <div className="text-xs p-2 basis-7p break-words overflow-hidden">{asin.modelNumber}</div>
        </div>
        <div className="border-t">
            <SkusDiv asin={asin}></SkusDiv>
        </div>
    </div>
}