import { useState } from "react"
import { ImPencil } from "react-icons/im";
import Modal from "../../Modal"
import NewTask from "./EditNoteModal"

export default function EditNote({ task, refetch, campaignId, _id }) {
    const [showModal, setShowModal] = useState(false)
    console.log(task)

    function onChange() {
        setShowModal(false);
    }

    const modal = <Modal onChange={onChange} >
        <NewTask setShowModal={setShowModal} _id={_id} campaignId={campaignId} refetch={refetch} taskDefault={task}></NewTask>
    </Modal>
    return (
        <div>
            {showModal && modal}
            <button onClick={() => setShowModal(true)}>
                <div className="flex flex-col items-center justify-end">
                    <div className="text-lg"><ImPencil></ImPencil></div>
                    <div className="mr-1 text-3xs">Edit</div>
                </div>
            </button>
        </div>
    )
}