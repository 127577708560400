import { useSelector } from "react-redux";

function AcosDisplay({ value, spend, className }) {
    const target = useSelector(state => state.accounts.targetAcos);
    const avgPrice = useSelector(state => state.accounts.avgPrice);
    //const spend = row.original.spend_1
    const acos = Number(value)
    const targetAcos = Number(target)

    const acosClass = "flex justify-end p-1 font-bold border rounded-md bg-gray-50 " + className + " "

    let acosDiv = <div></div>

    if (acos === 0) {
        if (spend > avgPrice) acosDiv = <div className={acosClass + 'bg-red-700 text-white rounded-md'}>∞%</div>
        else if (spend > avgPrice / 2) acosDiv = <div className={acosClass + 'bg-red-500 text-white rounded-md'}>∞%</div>
        else if (spend > avgPrice / 5) acosDiv = <div className={acosClass + 'bg-yellow-200 rounded-md'}>∞%</div>
        else acosDiv = <div className={acosClass + 'rounded-md'}>-</div>
    }
    else if (acos > targetAcos * 3) acosDiv = <div className={acosClass + 'bg-red-700 text-white rounded-md'}>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 2) acosDiv = <div className={acosClass + 'bg-red-500 text-white rounded-md'}>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 1.5) acosDiv = <div className={acosClass + 'bg-red-400 text-white rounded-md'}>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 1.3) acosDiv = <div className={acosClass + 'bg-yellow-200 rounded-md'}>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 1.2) acosDiv = <div className={acosClass + 'bg-yellow-100 rounded-md'}>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos < targetAcos * 0.6) acosDiv = <div className={acosClass + 'bg-green-600 text-white rounded-md'}>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos < targetAcos * 0.8) acosDiv = <div className={acosClass + 'bg-green-400 text-white rounded-md'}>{acos === 0 ? '-' : (value + "%")}</div>
    else acosDiv = <div className={acosClass + ''}>{acos === 0 ? '-' : (value + "%")}</div>

    return acosDiv

}

export default AcosDisplay

//ACOS kuralları
/*
acos > 50
        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
        : (acos < 20 && acos > 0
            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
            : (acos === 0 && row.original.spend_1 > avgPrice/2
                ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '∞%' : (value + "%")}</div>
                : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>)
        )
*/