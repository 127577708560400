import {
    useFetchCampaignsQuery, useFetchSbCampaignsQuery, useFetchSdCampaignsQuery,
    useFetchCampaignsListQuery,
    useFetchCampaignsReportQuery,
    useFetchSbCampaignsReportQuery,
    useFetchSdCampaignsReportQuery
} from "../../store"
import { useSelector } from "react-redux"
import Skeleton from '../Skeleton'
import Panel from "../Panel"
import CampaignXTable from "./CampaignX/CampaignXTable"
import CampaignTableBulkUpdate from "./CampaignTableBulkUpdate"

export default function CampaignsList({ portfolioId, pickedDate, accountId, searchText, type }) {
    const { data: dataSpList, error: errorSpList, isFetching: isFetchingSpList } = useFetchCampaignsListQuery(accountId)

    const { data, isFetching } = useFetchCampaignsReportQuery(accountId)
    const { data: dataSb } = useFetchSbCampaignsReportQuery(accountId)
    const { data: dataSd } = useFetchSdCampaignsReportQuery(accountId)


    const { data: dataCmpList, refetch, isFetching: isFetchingCmpList } = useFetchCampaignsQuery(accountId)
    const { data: dataCmpListSb } = useFetchSbCampaignsQuery(accountId)
    const { data: dataCmpListSd } = useFetchSdCampaignsQuery(accountId)

    let dataCmpListAll, dataAll

    const isEnabledOnly = useSelector(state => state.accounts.isEnableCmpOnly)

    const dateNow = new Date();
    const offset = new Date().getTimezoneOffset()
    let dateArray = [], dateStartArray = [], dateEndArray = [];
    for (let i = 0; i < 180; i++) {
        let start = new Date();
        let end = new Date();
        start.setDate(dateNow.getDate() - (i));
        end.setDate(dateNow.getDate() - (i));
        start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
        end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
        dateStartArray[i] = start.toISOString().split('T')[0];
        dateEndArray[i] = end.toISOString().split('T')[0];
        dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
        //console.log('Day ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
    }
    let columns = [{ label: '4 Weeks', content: null }, { label: '8 Weeks', content: null }, { label: '13 Weeks', content: null }]
    let tableContent, performanceContent, finalContent
    let campaignList = []
    if (isFetchingSpList) {
        tableContent = <Skeleton times={5} className="h-12 w-full" />
    } else if (errorSpList) {
        tableContent = <div>Error!!! Please contact Patara</div>
    } else if (dataSpList && dataCmpList && dataCmpListSb && dataCmpListSd) {
        dataCmpListAll = dataCmpList.campaigns.concat(dataCmpListSb.campaigns, dataCmpListSd)
        //console.log(dataSpList)

        campaignList = dataCmpListAll.filter(campaign => {
            return campaign.portfolioId === portfolioId.toString()
        }).map(item => {
            return item.campaignId
        })


        let dataEnabled, dataFiltered;
        if (portfolioId !== 0) {
            dataFiltered = dataSpList.filter(campaign => {
                return campaignList.includes(campaign.campaignId.toString());
            });
        } else {
            dataFiltered = dataSpList
        }

        if (searchText) {
            dataFiltered = dataFiltered.filter(campaign => {
                return campaign.campaignName.includes(searchText);
            });
        }

        if (isEnabledOnly) {
            dataEnabled = dataFiltered.filter(campaign => {
                return campaign.campaignStatus === "ENABLED" || campaign.campaignStatus === "enabled";
            });
        } else {
            dataEnabled = dataFiltered
        }

        dataEnabled = dataEnabled.map(campaign => {
            let campaignType
            let camp

            if (campaign.campaignName.includes("- SB -") || campaign.campaignType === "SB") {
                campaignType = "SB"
                camp = dataCmpListSb.campaigns.find(e => e.campaignId === campaign.campaignId.toString())
            } else if (campaign.campaignName.includes("- SD -") || campaign.campaignType === "SD") {
                campaignType = "SD"
                camp = dataCmpListSd.find(e => e.campaignId === campaign.campaignId)
            } else if (campaign.campaignName.includes("- SPM -") || campaign.campaignName.includes("- SPA -") || campaign.campaignType === "SP") {
                campaignType = "SP"
                camp = dataCmpList.campaigns.find(e => e.campaignId === campaign.campaignId.toString())
            } else {
                camp = dataCmpList.campaigns.find(e => e.campaignId === campaign.campaignId.toString())
            }


            let bidTos = 0
            let bidPdp = 0
            let bidRest = 0
            let bidB2B = 0
            if (camp && camp.dynamicBidding) {
                const placementCount = camp.dynamicBidding.placementBidding.length
                for (let i = 0; i < placementCount; i++) {
                    if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_TOP') {
                        bidTos = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_PRODUCT_PAGE') {
                        bidPdp = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_REST_OF_SEARCH') {
                        bidRest = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'SITE_AMAZON_BUSINESS') {
                        bidB2B = camp.dynamicBidding.placementBidding[i].percentage
                    }
                }
            }
            //const dynBid1 = camp.dynamicBidding.placementBidding[0].percentage
            //const dynBid2 = camp.dynamicBidding.placementBidding[1].percentage
            //const dynBid3 = camp.dynamicBidding.placementBidding[2].percentage


            let currBudget
            if (camp && camp.budget) {
                currBudget = camp.budget.budget || camp.budget
            }
            else {
                console.log("Bütçe Amazondan alınamadı. DB verisi kullanılacak - campaign list")
                currBudget = (campaign.campaignBudgetAmount || campaign.campaignBudget) + 3000
            }
            return {
                ...campaign,
                currBudget,
                bidPdp,
                bidRest,
                bidTos,
                bidB2B,
                campaignType
            }
        })
        //console.log(dataCmpListSd)

        if (type) {
            dataEnabled = dataEnabled.filter(campaign => campaign.campaignType && campaign.campaignType.toLowerCase() === type)
        }

        dataEnabled.sort((a, b) => {
            if (a.performance30days && b.performance30days)
                return b.performance30days.spend30 - a.performance30days.spend30
            else return 0
        })

        if (dataEnabled.length > 0) {
            tableContent = (
                <CampaignXTable data={dataEnabled} refetch={refetch} isFetching={isFetchingCmpList} pickedDate={pickedDate} />
                //<StickyTable data={tableData} />
            )
        } else {
            tableContent = <Panel >
                <div className="flex flex-row overflow-scroll justify-between">
                    No Active Campaigns to Display
                </div>
            </Panel >
        }
    }

    if (isFetching) {
        finalContent = <Skeleton times={1} className="h-24 w-full mx-1" />

    } else if (data && dataSb && dataSd) {
        dataAll = data.concat(dataSb, dataSd)
        let dataEnabled, dataFiltered;
        if (portfolioId !== 0) {
            dataFiltered = dataAll.filter(campaign => {
                return campaignList.includes(campaign.campaignId.toString());
            });
        } else {
            dataFiltered = dataAll
        }

        if (searchText) {
            dataFiltered = dataFiltered.filter(campaign => {
                return campaign.campaignName.includes(searchText);
            });
        }

        if (isEnabledOnly) {
            dataEnabled = dataFiltered.filter(campaign => {
                return campaign.campaignStatus === "ENABLED" || campaign.campaignStatus === "enabled";
            });
        } else {
            dataEnabled = dataFiltered
        }

        if (type) {
            dataEnabled = dataEnabled.filter(campaign => campaign.campaignType && campaign.campaignType.toLowerCase() === type)
        }

        dataEnabled = dataEnabled.map(campaign => {
            let campaignType
            let camp

            if (campaign.campaignName.includes("- SB -") || campaign.campaignType === "SB") {
                campaignType = "SB"
                camp = dataCmpListSb.campaigns.find(e => e.campaignId === campaign.campaignId.toString())
            } else if (campaign.campaignName.includes("- SD -") || campaign.campaignType === "SD") {
                campaignType = "SD"
                camp = dataCmpListSd.find(e => e.campaignId === campaign.campaignId)
            } else if (campaign.campaignName.includes("- SPM -") || campaign.campaignName.includes("- SPA -") || campaign.campaignType === "SP") {
                campaignType = "SP"
                camp = dataCmpList.campaigns.find(e => e.campaignId === campaign.campaignId.toString())
            } else {
                camp = dataCmpList.campaigns.find(e => e.campaignId === campaign.campaignId.toString())
            }


            let bidTos = 0
            let bidPdp = 0
            let bidRest = 0
            let bidB2B = 0
            if (camp && camp.dynamicBidding) {
                const placementCount = camp.dynamicBidding.placementBidding.length
                for (let i = 0; i < placementCount; i++) {
                    if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_TOP') {
                        bidTos = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_PRODUCT_PAGE') {
                        bidPdp = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_REST_OF_SEARCH') {
                        bidRest = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'SITE_AMAZON_BUSINESS') {
                        bidB2B = camp.dynamicBidding.placementBidding[i].percentage
                    }
                }
            }
            //const dynBid1 = camp.dynamicBidding.placementBidding[0].percentage
            //const dynBid2 = camp.dynamicBidding.placementBidding[1].percentage
            //const dynBid3 = camp.dynamicBidding.placementBidding[2].percentage


            let currBudget
            if (camp && camp.budget) {
                currBudget = camp.budget.budget || camp.budget
            }
            else {
                console.log("Bütçe Amazondan alınamadı. DB verisi kullanılacak - campaign list")
                currBudget = (campaign.campaignBudgetAmount || campaign.campaignBudget) + 3000
            }
            return {
                ...campaign,
                currBudget,
                bidPdp,
                bidRest,
                bidTos,
                bidB2B,
                campaignType
            }
        })
        //console.log(dataCmpListSd)

        if (dataEnabled.length > 0) {
            //DATE PICKER ÜZERİNDEN GELEN TARİHLERİ BURADA HESAPLA
            //console.log(pickedDate);
            let startIndex = 0;
            let endIndex = 30;
            startIndex = Math.floor(((dateNow.getTime() + (((offset - 360) * 60 * 1000))) - pickedDate.endDate.getTime()) / (1000 * 60 * 60 * 24));
            endIndex = Math.floor(((dateNow.getTime() + (((offset - 360) * 60 * 1000))) - pickedDate.startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
            console.log("start index: ", startIndex, " - end index: ", endIndex)


            const tableData = dataEnabled.map(targeting => {
                const isSbSd = (targeting.campaignType === "SB" || targeting.campaignType === "SD" || targeting.campaignName.includes("- SB -") || targeting.campaignName.includes("- SD -"))


                let updatedTargeting = {
                    ...targeting,
                    spend_1: 0,
                    spendTOS_1: 0,
                    spendROS_1: 0,
                    spendDP_1: 0,
                    spendB2B_1: 0,
                    sales_1: 0,
                    salesTOS_1: 0,
                    salesROS_1: 0,
                    salesDP_1: 0,
                    salesB2B_1: 0,
                    sales1_1: 0,
                    sales7_1: 0,
                    sales30_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                }

                //BURAYA YENİ HESAPLAMA NOKTASI EKLE - SEÇİLEN TARİHİ İÇERECEK
                //DİĞER ZAMAN DİLİMLERİ KALMAK ZORUNDA ÇÜNKÜ ÜST TARAFTAKİ ÖZETİN BİLGİSİ ORADAN GELİYOR. GEREKSİZ NOKTALARI ÇIKARABİLİRİZ

                targeting.performance.forEach(performance => {

                    for (let i = startIndex; i < endIndex; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            if (isSbSd) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_1: updatedTargeting.spend_1 + performance.cost || 0,
                                    sales_1: updatedTargeting.sales_1 + performance.attributedSales14d || 0,
                                    impressions_1: updatedTargeting.impressions_1 + performance.impressions || 0,
                                    clicks_1: updatedTargeting.clicks_1 + performance.clicks || 0,
                                    orders_1: updatedTargeting.orders_1 + performance.attributedConversions14d || 0,
                                }
                            } else {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_1: updatedTargeting.spend_1 + performance.spend || 0,
                                    sales_1: updatedTargeting.sales_1 + performance.sales14d || 0,
                                    sales1_1: updatedTargeting.sales1_1 + performance.sales1d || 0,
                                    sales7_1: updatedTargeting.sales7_1 + performance.sales7d || 0,
                                    sales30_1: updatedTargeting.sales30_1 + performance.sales30d || 0,
                                    impressions_1: updatedTargeting.impressions_1 + performance.impressions || 0,
                                    clicks_1: updatedTargeting.clicks_1 + performance.clicks || 0,
                                    orders_1: updatedTargeting.orders_1 + performance.purchases14d || 0,
                                }
                            }
                        }
                        //if (targeting.campaignId === 217187174790982)
                        //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                    }
                });

                if (targeting.performanceTOS) {
                    targeting.performanceTOS.forEach(performance => {

                        for (let i = startIndex; i < endIndex; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                if (isSbSd) {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendTOS_1: updatedTargeting.spendTOS_1 + performance.spend || 0,
                                        salesTOS_1: updatedTargeting.salesTOS_1 + performance.sales14d || 0,
                                    }
                                } else {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendTOS_1: updatedTargeting.spendTOS_1 + performance.spend || 0,
                                        salesTOS_1: updatedTargeting.salesTOS_1 + performance.sales14d || 0,
                                    }
                                }
                            }
                            //if (targeting.campaignId === 217187174790982)
                            //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                        }
                    });
                }

                if (targeting.performanceROS) {
                    targeting.performanceROS.forEach(performance => {

                        for (let i = startIndex; i < endIndex; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                if (isSbSd) {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendROS_1: updatedTargeting.spendROS_1 + performance.spend || 0,
                                        salesROS_1: updatedTargeting.salesROS_1 + performance.sales14d || 0,
                                    }
                                } else {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendROS_1: updatedTargeting.spendROS_1 + performance.spend || 0,
                                        salesROS_1: updatedTargeting.salesROS_1 + performance.sales14d || 0,
                                    }
                                }
                            }
                            //if (targeting.campaignId === 217187174790982)
                            //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                        }
                    });
                }

                if (targeting.performanceDP) {
                    targeting.performanceDP.forEach(performance => {

                        for (let i = startIndex; i < endIndex; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                if (isSbSd) {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendDP_1: updatedTargeting.spendDP_1 + performance.spend || 0,
                                        salesDP_1: updatedTargeting.salesDP_1 + performance.sales14d || 0,
                                    }
                                } else {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendDP_1: updatedTargeting.spendDP_1 + performance.spend || 0,
                                        salesDP_1: updatedTargeting.salesDP_1 + performance.sales14d || 0,
                                    }
                                }
                            }
                            //if (targeting.campaignId === 217187174790982)
                            //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                        }
                    });
                }

                if (targeting.performanceB2B) {
                    targeting.performanceB2B.forEach(performance => {

                        for (let i = startIndex; i < endIndex; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                if (isSbSd) {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_1: updatedTargeting.spendB2B_1 + performance.spend || 0,
                                        salesB2B_1: updatedTargeting.salesB2B_1 + performance.sales14d || 0,
                                    }
                                } else {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_1: updatedTargeting.spendB2B_1 + performance.spend || 0,
                                        salesB2B_1: updatedTargeting.salesB2B_1 + performance.sales14d || 0,
                                    }
                                }
                            }
                            //if (targeting.campaignId === 217187174790982)
                            //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                        }
                    })

                    updatedTargeting = {
                        ...updatedTargeting,
                        spendB2B_4: 0,
                        salesB2B_4: 0,
                        impressionsB2B_4: 0,
                        clicksB2B_4: 0,
                        ordersB2B_4: 0,
                    }

                    targeting.performanceB2B.forEach(performance => {
                        for (let i = 0; i < 30; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                if (isSbSd) {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_4: updatedTargeting.spendB2B_4 + performance.spend || 0,
                                        salesB2B_4: updatedTargeting.salesB2B_4 + performance.sales14d || 0,
                                    }
                                } else {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_4: updatedTargeting.spendB2B_4 + performance.spend || 0,
                                        salesB2B_4: updatedTargeting.salesB2B_4 + performance.sales14d || 0,
                                    }
                                }
                            }
                            //if (targeting.campaignId === 217187174790982)
                            //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                        }
                    })

                    updatedTargeting = {
                        ...updatedTargeting,
                        spendB2B_8: updatedTargeting.spendB2B_4,
                        salesB2B_8: updatedTargeting.salesB2B_4,
                        impressionsB2B_8: updatedTargeting.impressionsB2B_4,
                        clicksB2B_8: updatedTargeting.clicksB2B_4,
                        ordersB2B_8: updatedTargeting.ordersB2B_4,
                    }

                    targeting.performanceB2B.forEach(performance => {
                        for (let i = 30; i < 60; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                if (isSbSd) {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_8: updatedTargeting.spendB2B_8 + performance.spend || 0,
                                        salesB2B_8: updatedTargeting.salesB2B_8 + performance.sales14d || 0,
                                    }
                                } else {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_8: updatedTargeting.spendB2B_8 + performance.spend || 0,
                                        salesB2B_8: updatedTargeting.salesB2B_8 + performance.sales14d || 0,
                                    }
                                }
                            }
                            //if (targeting.campaignId === 217187174790982)
                            //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                        }
                    })

                    updatedTargeting = {
                        ...updatedTargeting,
                        spendB2B_13: updatedTargeting.spendB2B_8,
                        salesB2B_13: updatedTargeting.salesB2B_8,
                        impressionsB2B_13: updatedTargeting.impressionsB2B_8,
                        clicksB2B_13: updatedTargeting.clicksB2B_8,
                        ordersB2B_13: updatedTargeting.ordersB2B_8,
                    }

                    targeting.performanceB2B.forEach(performance => {
                        for (let i = 60; i < 90; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                if (isSbSd) {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_13: updatedTargeting.spendB2B_13 + performance.spend || 0,
                                        salesB2B_13: updatedTargeting.salesB2B_13 + performance.sales14d || 0,
                                    }
                                } else {
                                    updatedTargeting = {
                                        ...updatedTargeting,
                                        spendB2B_13: updatedTargeting.spendB2B_13 + performance.spend || 0,
                                        salesB2B_13: updatedTargeting.salesB2B_13 + performance.sales14d || 0,
                                    }
                                }
                            }
                            //if (targeting.campaignId === 217187174790982)
                            //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                        }
                    })
                }

                updatedTargeting = {
                    ...updatedTargeting,
                    spend_4: 0,
                    sales_4: 0,
                    impressions_4: 0,
                    clicks_4: 0,
                    orders_4: 0,
                }

                targeting.performance.forEach(performance => {

                    for (let i = 0; i < 30; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            if (isSbSd) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_4: updatedTargeting.spend_4 + performance.cost || 0,
                                    sales_4: updatedTargeting.sales_4 + performance.attributedSales14d || 0,
                                    impressions_4: updatedTargeting.impressions_4 + performance.impressions || 0,
                                    clicks_4: updatedTargeting.clicks_4 + performance.clicks || 0,
                                    orders_4: updatedTargeting.orders_4 + performance.attributedConversions14d || 0,
                                }
                            } else {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_4: updatedTargeting.spend_4 + performance.spend || 0,
                                    sales_4: updatedTargeting.sales_4 + performance.sales14d || 0,
                                    impressions_4: updatedTargeting.impressions_4 + performance.impressions || 0,
                                    clicks_4: updatedTargeting.clicks_4 + performance.clicks || 0,
                                    orders_4: updatedTargeting.orders_4 + performance.purchases14d || 0,
                                }
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    spend_8: updatedTargeting.spend_4,
                    sales_8: updatedTargeting.sales_4,
                    impressions_8: updatedTargeting.impressions_4,
                    clicks_8: updatedTargeting.clicks_4,
                    orders_8: updatedTargeting.orders_4,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 30; i < 60; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            if (isSbSd) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_8: updatedTargeting.spend_8 + performance.cost || 0,
                                    sales_8: updatedTargeting.sales_8 + performance.attributedSales14d || 0,
                                    impressions_8: updatedTargeting.impressions_8 + performance.impressions || 0,
                                    clicks_8: updatedTargeting.clicks_8 + performance.clicks || 0,
                                    orders_8: updatedTargeting.orders_8 + performance.attributedConversions14d || 0,
                                }
                            } else {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_8: updatedTargeting.spend_8 + performance.spend || 0,
                                    sales_8: updatedTargeting.sales_8 + performance.sales14d || 0,
                                    impressions_8: updatedTargeting.impressions_8 + performance.impressions || 0,
                                    clicks_8: updatedTargeting.clicks_8 + performance.clicks || 0,
                                    orders_8: updatedTargeting.orders_8 + performance.purchases14d || 0,
                                }
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    spend_13: updatedTargeting.spend_8,
                    sales_13: updatedTargeting.sales_8,
                    impressions_13: updatedTargeting.impressions_8,
                    clicks_13: updatedTargeting.clicks_8,
                    orders_13: updatedTargeting.orders_8,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 60; i < 90; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            if (isSbSd) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_13: updatedTargeting.spend_13 + performance.cost || 0,
                                    sales_13: updatedTargeting.sales_13 + performance.attributedSales14d || 0,
                                    impressions_13: updatedTargeting.impressions_13 + performance.impressions || 0,
                                    clicks_13: updatedTargeting.clicks_13 + performance.clicks || 0,
                                    orders_13: updatedTargeting.orders_13 + performance.attributedConversions14d || 0,
                                }
                            } else {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    spend_13: updatedTargeting.spend_13 + performance.spend || 0,
                                    sales_13: updatedTargeting.sales_13 + performance.sales14d || 0,
                                    impressions_13: updatedTargeting.impressions_13 + performance.impressions || 0,
                                    clicks_13: updatedTargeting.clicks_13 + performance.clicks || 0,
                                    orders_13: updatedTargeting.orders_13 + performance.purchases14d || 0,
                                }
                            }
                        }
                    }
                })

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.spend_1 / updatedTargeting.clicks_1).toFixed(2),
                    cpc_4: (updatedTargeting.spend_4 / updatedTargeting.clicks_4).toFixed(2),
                    cpc_8: (updatedTargeting.spend_8 / updatedTargeting.clicks_8).toFixed(2),
                    cpc_13: (updatedTargeting.spend_13 / updatedTargeting.clicks_13).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.spend_1 / updatedTargeting.sales_1) * 100).toFixed(2),
                    acos_4: updatedTargeting.sales_4 === 0 ? 0 : ((updatedTargeting.spend_4 / updatedTargeting.sales_4) * 100).toFixed(2),
                    acos_8: updatedTargeting.sales_8 === 0 ? 0 : ((updatedTargeting.spend_8 / updatedTargeting.sales_8) * 100).toFixed(2),
                    acos_13: updatedTargeting.sales_13 === 0 ? 0 : ((updatedTargeting.spend_13 / updatedTargeting.sales_13) * 100).toFixed(2),
                    ctr_1: updatedTargeting.impressions_1 === 0 ? 0 : ((updatedTargeting.clicks_1 / updatedTargeting.impressions_1) * 100).toFixed(2),
                    ctr_4: updatedTargeting.impressions_4 === 0 ? 0 : ((updatedTargeting.clicks_4 / updatedTargeting.impressions_4) * 100).toFixed(2),
                    ctr_8: updatedTargeting.impressions_8 === 0 ? 0 : ((updatedTargeting.clicks_8 / updatedTargeting.impressions_8) * 100).toFixed(2),
                    ctr_13: updatedTargeting.impressions_13 === 0 ? 0 : ((updatedTargeting.clicks_13 / updatedTargeting.impressions_13) * 100).toFixed(2),
                    spendPerDay: updatedTargeting.spend_1 / (endIndex - startIndex),
                    isFetchingCmpList
                };
            })

            let spend0 = 0, sales0 = 0, spend1 = 0, sales1 = 0, spend2 = 0, sales2 = 0, spend3 = 0, sales3 = 0, budgetUsage = 0
            let spend0B2B = 0, sales0B2B = 0, spend1B2B = 0, sales1B2B = 0, spend2B2B = 0, sales2B2B = 0, spend3B2B = 0, sales3B2B = 0

            tableData.forEach(item => {
                spend0 = spend0 + item.spend_1;
                sales0 = sales0 + item.sales_1;
                spend0B2B = spend0B2B + item.spendB2B_1;
                sales0B2B = sales0B2B + item.salesB2B_1;

                spend1 = spend1 + item.spend_4;
                sales1 = sales1 + item.sales_4;
                spend1B2B = spend1B2B + (item.spendB2B_4 || 0);
                sales1B2B = sales1B2B + (item.salesB2B_4 || 0);

                spend2 = spend2 + item.spend_8;
                sales2 = sales2 + item.sales_8;
                spend2B2B = spend2B2B + (item.spendB2B_8 || 0);
                sales2B2B = sales2B2B + (item.salesB2B_8 || 0);

                spend3 = spend3 + item.spend_13;
                sales3 = sales3 + item.sales_13;
                spend3B2B = spend3B2B + (item.spendB2B_13 || 0);
                sales3B2B = sales3B2B + (item.salesB2B_13 || 0);

                budgetUsage = budgetUsage + item.spendPerDay
            });


            columns = [
                { label: 'Selected Date', sales: sales0, spend: spend0, salesB2B: sales0B2B, spendB2B: spend0B2B },
                { label: '30 Days', sales: sales1, spend: spend1, salesB2B: sales1B2B, spendB2B: spend1B2B },
                { label: '60 Days', sales: sales2, spend: spend2, salesB2B: sales2B2B, spendB2B: spend2B2B },
                { label: '90 Days', sales: sales3, spend: spend3, salesB2B: sales3B2B, spendB2B: spend3B2B }
            ];

            performanceContent = columns.map((column, index) => {
                return (
                    <div key={index} className="flex flex-col px-2 py-1 basis-96 mx-2 rounded-lg ">
                        <div className="flex justify-center">
                            <div className="mb-2 font-bold border border-gray-400 rounded-xl  p-1 px-1 basis-80 justify-center flex">{column.label}</div>
                        </div>
                        <div className="mb-2 text-sm">
                            <div className="flex flex-row justify-around">
                                <div className="mx-3">
                                    <div className="text-xxs text-center text-gray-600">Spent</div>
                                    <div className="text-center font-bold">${Number(column.spend.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                                    <div className="flex items-center">
                                        <div className="text-center text-3xs">${Number(column.spendB2B.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                                        <div className="text-center text-5xs ml-1">(%{Number((column.spendB2B / column.spend * 100).toFixed(2))})</div>
                                    </div>
                                </div>
                                <div className="mx-3">
                                    <div className="text-xxs text-center text-gray-600">Sales</div>
                                    <div className="text-center font-bold">${Number(column.sales.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                                    <div className="flex items-center">
                                        <div className="text-center text-3xs">${Number(column.salesB2B.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                                        <div className="text-center text-5xs ml-1">(%{Number((column.salesB2B / column.sales * 100).toFixed(2))})</div>
                                    </div>
                                </div>
                                <div className="mx-3">
                                    <div className="text-xxs text-center text-gray-600">ACOS</div>
                                    <div className="text-center font-bold">{(column.spend / column.sales * 100).toFixed(2)}%</div>
                                    <div className="text-center text-3xs">{(column.spendB2B / column.salesB2B * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })

            finalContent = <div className="flex flex-col w-full">
                <Panel >
                    <div className="flex flex-row overflow-scroll justify-around">
                        {performanceContent}
                    </div>
                </Panel >
                <CampaignTableBulkUpdate key={"bulkupdate"}
                    length={data.length} tableData={data} refetch={refetch} isFetching={isFetching}
                    campList={dataCmpList} budgetUsage={budgetUsage} portfolioId={portfolioId}
                    searchText={searchText}
                />
            </div>

        }
    } else {
        finalContent = <Panel >
            <div className="flex flex-row overflow-scroll justify-between">
                No Active Campaigns to Display
            </div>
        </Panel >
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-row px-5 py-1">
                {finalContent}
            </div>
            <div className="mx-5 my-1 text-sm">
                {tableContent}
            </div>
        </div >
    )
}