import { useState } from "react";
import { useSelector } from "react-redux";
import { useFetchUserQuery } from "../../store";
import SelectAccountDiv from "../SelectAccountDiv"
import CampaignsDropdown from "../CampaignsDropdown";
import FilterDropdown from "./FilterDropdown";
import TargetingList from "./TargetingListDaily";

function OptimizationPageCustom() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [campaignId, setCampaignId] = useState(null);
    const [filter, setFilter] = useState(null);
    const { data } = useFetchUserQuery();
    //console.log("filter: ", filter)

    let content = <div></div>;
    if (data) {
        content = (<div>
            {accountId
                ? <div>
                    <div className="flex px-5 py-1">
                        <CampaignsDropdown setCampaignId={setCampaignId} />
                        {campaignId
                            ? <div className="ml-3"><FilterDropdown setFilter={setFilter}></FilterDropdown></div>
                            : <div className="m-5 p-2 rounded-xl shadow"></div>
                        }

                    </div>
                    {campaignId
                        ? <TargetingList campaignId={campaignId} accountId={accountId} filter={filter} custom={true} />
                        : <div className="m-5 p-2 rounded-xl shadow">Select a campaign to display targeting performance</div>
                    }
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }
        </div>
        )
    }
    return (
        <div className="flex flex-col h-screen">
            {content}
        </div>
    );
}
export default OptimizationPageCustom;