import ListingPanel from "./ListingPanel";
import { BiSortAlt2 } from "react-icons/bi"
import { FiDownload } from "react-icons/fi"
import AsinDiv from "./AsinDiv";

export default function Info({ colFilter, setColFilter, accountId, newData, filter, setFilter, listingsDiv, sizeOptions, colorOptions, dateArray }) {
    let sameMethodDiv = [], sameMethodList = [], noBuyBoxDiv = [], noBuyBoxList = [], hijackerDiv = [], hijackerList = []
    newData.forEach((asin, index1) => {
        const asinDiv = <AsinDiv index1={index1} asin={asin}></AsinDiv>
        if (asin.sameMethod) {
            sameMethodDiv.push(asinDiv)
            sameMethodList.push(asin)
        }
        if (asin.noBuyBox) {
            noBuyBoxDiv.push(asinDiv)
            noBuyBoxList.push(asin)
        }
        if (asin.hijacker) {
            hijackerDiv.push(asinDiv)
            hijackerList.push(asin)
        }
    })


    const onFilterClick = () => {
        if (filter.id === "az") setFilter({ label: "Sales", id: "sales" })
        else setFilter({ label: "A-Z", id: "az" })
    }
    const onDownloadClick = () => {
        //console.log("downloading the csv file")
        let headers = ['', 'ASIN', 'UPC', 'EAN', 'SIZE', 'COLOR', 'TYPE', 'CATEGORY', 'SKUS']
        let listingInfo = newData.map(listing => {
            return { asin: listing.asin, upc: listing.upc, ean: listing.ean, size: listing.size, color: listing.color, product_type: listing.product_type, item_type_keyword: listing.item_type_keyword, skus: listing.skus }
        })
        let data1 = []

        for (let i = 0; i < headers.length; i++) {
            if (i === headers.length - 1) {
                data1.push(headers[i])
                data1.push('\n')
            }
            else data1.push(headers[i])
        }
        for (let i = 0; i < listingInfo.length; i++) {
            data1.push(listingInfo[i].asin)
            data1.push(listingInfo[i].upc)
            data1.push(listingInfo[i].ean)
            data1.push(listingInfo[i].size)
            data1.push(listingInfo[i].color)
            data1.push(listingInfo[i].product_type)
            data1.push(listingInfo[i].item_type_keyword)
            listingInfo[i].skus.forEach(sku => {
                data1.push(sku.seller_sku)
                data1.push(sku.fulfillment_channel)
            })
            data1.push('\n')
        }
        const url = window.URL.createObjectURL(new Blob([data1]))
        const link = document.createElement('a')
        link.href = url
        const date = new Date().toISOString().split('T')[0]
        const fileName = `Listings ${accountId} ${date}.csv`;
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
    let oosSales = 0, allSales = 0, oosUnitsOrdered = 0, allUnitsOrdered = 0
    newData = newData.map(asin => {
        let totalSales = 0, totalUnitsOrdered = 0
        asin.performance.forEach(performance => {
            for (let i = 0; i < 90; i++) {
                if (performance.dateRange === dateArray[i]) {
                    totalSales = totalSales + performance.orderedProductSales
                    totalUnitsOrdered = totalUnitsOrdered + performance.unitsOrdered
                }
            }
        })
        allSales = allSales + totalSales
        allUnitsOrdered = allUnitsOrdered + totalUnitsOrdered
        if (asin.totalQuantity === 0) {
            oosSales = oosSales + totalSales
            oosUnitsOrdered = oosUnitsOrdered + totalUnitsOrdered
        }
        return {
            ...asin,
            totalSales,
            totalUnitsOrdered
        }
    })
    const oosRate = oosSales / allSales * 100
    const oosUnitsRate = oosUnitsOrdered / allUnitsOrdered * 100
    let oosRateDivClass = "shadow rounded-lg px-3 py-1 mx-2"
    if (oosRate > 25) oosRateDivClass = "shadow rounded-lg px-3 py-1 bg-red-600 text-white mx-2"
    let oosUnitsRateDivClass = "shadow rounded-lg px-3 py-1 mx-2"
    if (oosUnitsRate > 25) oosUnitsRateDivClass = "shadow rounded-lg px-3 py-1 bg-red-600 text-white mx-2"
    let salesDivClass = "shadow rounded-lg border px-3 py-1 mx-2"
    let unitsDivClass = "shadow rounded-lg border px-3 py-1 mx-2"


    return <div className="flex flex-row py-2 items-center">
        <div className="mr-3">
            <div className="text-3xs break-words overflow-hidden">{"Number of Listings"}</div>
            <div className="text-xs break-words overflow-hidden font-bold">{newData.length}</div>
        </div>
        <ListingPanel div={sameMethodDiv} list={sameMethodList} panelName={"Same-Method Listings"} ></ListingPanel>
        <ListingPanel div={noBuyBoxDiv} list={noBuyBoxList} panelName={"No-BuyBox Listings"}></ListingPanel>
        <ListingPanel div={hijackerDiv} list={hijackerList} panelName={"Hijacked Listings"}></ListingPanel>
        <div className="flex flex-row justify-center rounded-lg border shadow px-2 p-1 mx-2 hover:cursor-pointer w-20 text-center" onClick={onFilterClick}>
            {filter.label}
            <div className="text-xl mx-1"><BiSortAlt2 /></div>
        </div>
        <div className={oosRateDivClass}>
            <div className="text-xxs">OOS Rate (Sales)</div>
            <div className="text-4xs">90 Days</div>
            <div className="font-bold">%{(oosRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        </div>
        <div className={oosUnitsRateDivClass}>
            <div className="text-xxs">OOS Rate (Units)</div>
            <div className="text-4xs">90 Days</div>
            <div className="font-bold">%{(oosUnitsRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        </div>
        <div className={salesDivClass}>
            <div className="text-xxs">Sales (Filtered)</div>
            <div className="text-4xs">90 Days</div>
            <div className="font-bold">${allSales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        </div>
        <div className={unitsDivClass}>
            <div className="text-xxs">Units (Filtered)</div>
            <div className="text-4xs">90 Days</div>
            <div className="font-bold">{allUnitsOrdered.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
        </div>

        <div onClick={colFilter.length > 0 ? () => { setColFilter([]); console.log("Clear Filter") } : null}
            className={colFilter.length > 0
                ? "cursor-pointer p-2 shadow rounded-xl select-none"
                : "cursor-not-allowed text-gray-200 p-2 shadow rounded-xl select-none"}>
            Clear Filter
        </div>

        <div className="flex grow justify-end items-center">
            {<div>ASINs: {newData.length}</div>}
            <button className="text-lg mx-2 flex" onClick={onDownloadClick} disabled={listingsDiv.length === 0}><FiDownload></FiDownload></button>
        </div>

    </div>
}