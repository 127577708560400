import { useFetchCampaignReportQuery } from "../../../store"
import Skeleton from "../../Skeleton"
import AcosDisplay from "../AcosDisplayDiv"
import BudgetForm from '../BudgetForm'
import PlacementForm from "../PlacementForm"
export default function DataDiv({ camp, campaignId, accountId, campaignType, pickedDate, sales,
    salesB2B, spends, spendsB2B, setSales, setSalesB2B, setSpends, setSpendsB2B
}) {
    const { data, isFetching } = useFetchCampaignReportQuery({ campaignId, accountId, campaignType })
    let dataDiv = <div>No Data</div>
    if (isFetching) {
        dataDiv = <Skeleton times={1} className="h-8 w-96" />
    } else if (data && pickedDate) {
        //console.log(data)
        const campaign = data
        const dateNow = new Date();
        const offset = new Date().getTimezoneOffset()
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 180; i++) {
            let start = new Date();
            let end = new Date();
            start.setDate(dateNow.getDate() - (i));
            end.setDate(dateNow.getDate() - (i));
            start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
            end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Day ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }
        let startIndex = 0;
        let endIndex = 30;
        startIndex = Math.floor(((dateNow.getTime() + (((offset - 360) * 60 * 1000))) - pickedDate.endDate.getTime()) / (1000 * 60 * 60 * 24));
        endIndex = Math.floor(((dateNow.getTime() + (((offset - 360) * 60 * 1000))) - pickedDate.startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
        //console.log("start index: ", startIndex, " - end index: ", endIndex)

        const isSbSd = (campaign.campaignType === "SB" || campaign.campaignType === "SD" || campaign.campaignName.includes("- SB -") || campaign.campaignName.includes("- SD -"))

        let updatedCampaign = {
            ...campaign,
            spend_1: 0,
            spendTOS_1: 0,
            spendROS_1: 0,
            spendDP_1: 0,
            spendB2B_1: 0,
            sales_1: 0,
            salesTOS_1: 0,
            salesROS_1: 0,
            salesDP_1: 0,
            salesB2B_1: 0,
            sales1_1: 0,
            sales7_1: 0,
            sales30_1: 0,
            impressions_1: 0,
            clicks_1: 0,
            orders_1: 0,
        }

        //BURAYA YENİ HESAPLAMA NOKTASI EKLE - SEÇİLEN TARİHİ İÇERECEK
        //DİĞER ZAMAN DİLİMLERİ KALMAK ZORUNDA ÇÜNKÜ ÜST TARAFTAKİ ÖZETİN BİLGİSİ ORADAN GELİYOR. GEREKSİZ NOKTALARI ÇIKARABİLİRİZ

        campaign.performance.forEach(performance => {

            for (let i = startIndex; i < endIndex; i++) {
                if (performance.dateRange === dateArray[i]) {
                    if (isSbSd) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_1: updatedCampaign.spend_1 + performance.cost || 0,
                            sales_1: updatedCampaign.sales_1 + performance.attributedSales14d || 0,
                            impressions_1: updatedCampaign.impressions_1 + performance.impressions || 0,
                            clicks_1: updatedCampaign.clicks_1 + performance.clicks || 0,
                            orders_1: updatedCampaign.orders_1 + performance.attributedConversions14d || 0,

                        }
                    } else {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_1: updatedCampaign.spend_1 + performance.spend || 0,
                            sales_1: updatedCampaign.sales_1 + performance.sales14d || 0,
                            sales1_1: updatedCampaign.sales1_1 + performance.sales1d || 0,
                            sales7_1: updatedCampaign.sales7_1 + performance.sales7d || 0,
                            sales30_1: updatedCampaign.sales30_1 + performance.sales30d || 0,
                            impressions_1: updatedCampaign.impressions_1 + performance.impressions || 0,
                            clicks_1: updatedCampaign.clicks_1 + performance.clicks || 0,
                            orders_1: updatedCampaign.orders_1 + performance.purchases14d || 0,
                        }
                    }
                }
                //if (campaign.campaignId === 217187174790982)
                //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
            }
        });

        if (campaign.performanceTOS) {
            campaign.performanceTOS.forEach(performance => {

                for (let i = startIndex; i < endIndex; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        if (isSbSd) {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendTOS_1: updatedCampaign.spendTOS_1 + performance.spend || 0,
                                salesTOS_1: updatedCampaign.salesTOS_1 + performance.sales14d || 0,
                            }
                        } else {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendTOS_1: updatedCampaign.spendTOS_1 + performance.spend || 0,
                                salesTOS_1: updatedCampaign.salesTOS_1 + performance.sales14d || 0,
                            }
                        }
                    }
                    //if (campaign.campaignId === 217187174790982)
                    //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
                }
            });
        }

        if (campaign.performanceROS) {
            campaign.performanceROS.forEach(performance => {

                for (let i = startIndex; i < endIndex; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        if (isSbSd) {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendROS_1: updatedCampaign.spendROS_1 + performance.spend || 0,
                                salesROS_1: updatedCampaign.salesROS_1 + performance.sales14d || 0,
                            }
                        } else {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendROS_1: updatedCampaign.spendROS_1 + performance.spend || 0,
                                salesROS_1: updatedCampaign.salesROS_1 + performance.sales14d || 0,
                            }
                        }
                    }
                    //if (campaign.campaignId === 217187174790982)
                    //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
                }
            });
        }

        if (campaign.performanceDP) {
            campaign.performanceDP.forEach(performance => {

                for (let i = startIndex; i < endIndex; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        if (isSbSd) {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendDP_1: updatedCampaign.spendDP_1 + performance.spend || 0,
                                salesDP_1: updatedCampaign.salesDP_1 + performance.sales14d || 0,
                            }
                        } else {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendDP_1: updatedCampaign.spendDP_1 + performance.spend || 0,
                                salesDP_1: updatedCampaign.salesDP_1 + performance.sales14d || 0,
                            }
                        }
                    }
                    //if (campaign.campaignId === 217187174790982)
                    //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
                }
            });
        }

        if (campaign.performanceB2B) {
            campaign.performanceB2B.forEach(performance => {

                for (let i = startIndex; i < endIndex; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        if (isSbSd) {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_1: updatedCampaign.spendB2B_1 + performance.spend || 0,
                                salesB2B_1: updatedCampaign.salesB2B_1 + performance.sales14d || 0,
                            }
                        } else {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_1: updatedCampaign.spendB2B_1 + performance.spend || 0,
                                salesB2B_1: updatedCampaign.salesB2B_1 + performance.sales14d || 0,
                            }
                        }
                    }
                    //if (campaign.campaignId === 217187174790982)
                    //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
                }
            })

            updatedCampaign = {
                ...updatedCampaign,
                spendB2B_4: 0,
                salesB2B_4: 0,
                impressionsB2B_4: 0,
                clicksB2B_4: 0,
                ordersB2B_4: 0,
            }

            campaign.performanceB2B.forEach(performance => {
                for (let i = 0; i < 30; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        if (isSbSd) {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_4: updatedCampaign.spendB2B_4 + performance.spend || 0,
                                salesB2B_4: updatedCampaign.salesB2B_4 + performance.sales14d || 0,
                            }
                        } else {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_4: updatedCampaign.spendB2B_4 + performance.spend || 0,
                                salesB2B_4: updatedCampaign.salesB2B_4 + performance.sales14d || 0,
                            }
                        }
                    }
                    //if (campaign.campaignId === 217187174790982)
                    //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
                }
            })

            updatedCampaign = {
                ...updatedCampaign,
                spendB2B_8: updatedCampaign.spendB2B_4,
                salesB2B_8: updatedCampaign.salesB2B_4,
                impressionsB2B_8: updatedCampaign.impressionsB2B_4,
                clicksB2B_8: updatedCampaign.clicksB2B_4,
                ordersB2B_8: updatedCampaign.ordersB2B_4,
            }

            campaign.performanceB2B.forEach(performance => {
                for (let i = 30; i < 60; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        if (isSbSd) {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_8: updatedCampaign.spendB2B_8 + performance.spend || 0,
                                salesB2B_8: updatedCampaign.salesB2B_8 + performance.sales14d || 0,
                            }
                        } else {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_8: updatedCampaign.spendB2B_8 + performance.spend || 0,
                                salesB2B_8: updatedCampaign.salesB2B_8 + performance.sales14d || 0,
                            }
                        }
                    }
                    //if (campaign.campaignId === 217187174790982)
                    //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
                }
            })

            updatedCampaign = {
                ...updatedCampaign,
                spendB2B_13: updatedCampaign.spendB2B_8,
                salesB2B_13: updatedCampaign.salesB2B_8,
                impressionsB2B_13: updatedCampaign.impressionsB2B_8,
                clicksB2B_13: updatedCampaign.clicksB2B_8,
                ordersB2B_13: updatedCampaign.ordersB2B_8,
            }

            campaign.performanceB2B.forEach(performance => {
                for (let i = 60; i < 90; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        if (isSbSd) {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_13: updatedCampaign.spendB2B_13 + performance.spend || 0,
                                salesB2B_13: updatedCampaign.salesB2B_13 + performance.sales14d || 0,
                            }
                        } else {
                            updatedCampaign = {
                                ...updatedCampaign,
                                spendB2B_13: updatedCampaign.spendB2B_13 + performance.spend || 0,
                                salesB2B_13: updatedCampaign.salesB2B_13 + performance.sales14d || 0,
                            }
                        }
                    }
                    //if (campaign.campaignId === 217187174790982)
                    //console.log("Date: " + dateArray[i] + ", Campaign Name: " + campaign.campaignName + ", Spend: " + updatedCampaign.spend_1)
                }
            })
        }

        updatedCampaign = {
            ...updatedCampaign,
            spend_4: 0,
            sales_4: 0,
            impressions_4: 0,
            clicks_4: 0,
            orders_4: 0,
        }

        campaign.performance.forEach(performance => {

            for (let i = 0; i < 30; i++) {
                if (performance.dateRange === dateArray[i]) {
                    if (isSbSd) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_4: updatedCampaign.spend_4 + performance.cost || 0,
                            sales_4: updatedCampaign.sales_4 + performance.attributedSales14d || 0,
                            impressions_4: updatedCampaign.impressions_4 + performance.impressions || 0,
                            clicks_4: updatedCampaign.clicks_4 + performance.clicks || 0,
                            orders_4: updatedCampaign.orders_4 + performance.attributedConversions14d || 0,
                        }
                    } else {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_4: updatedCampaign.spend_4 + performance.spend || 0,
                            sales_4: updatedCampaign.sales_4 + performance.sales14d || 0,
                            impressions_4: updatedCampaign.impressions_4 + performance.impressions || 0,
                            clicks_4: updatedCampaign.clicks_4 + performance.clicks || 0,
                            orders_4: updatedCampaign.orders_4 + performance.purchases14d || 0,
                        }
                    }
                }
            }
        });

        updatedCampaign = {
            ...updatedCampaign,
            spend_8: updatedCampaign.spend_4,
            sales_8: updatedCampaign.sales_4,
            impressions_8: updatedCampaign.impressions_4,
            clicks_8: updatedCampaign.clicks_4,
            orders_8: updatedCampaign.orders_4,
        }

        campaign.performance.forEach(performance => {
            for (let i = 30; i < 60; i++) {
                if (performance.dateRange === dateArray[i]) {
                    if (isSbSd) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_8: updatedCampaign.spend_8 + performance.cost || 0,
                            sales_8: updatedCampaign.sales_8 + performance.attributedSales14d || 0,
                            impressions_8: updatedCampaign.impressions_8 + performance.impressions || 0,
                            clicks_8: updatedCampaign.clicks_8 + performance.clicks || 0,
                            orders_8: updatedCampaign.orders_8 + performance.attributedConversions14d || 0,
                        }
                    } else {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_8: updatedCampaign.spend_8 + performance.spend || 0,
                            sales_8: updatedCampaign.sales_8 + performance.sales14d || 0,
                            impressions_8: updatedCampaign.impressions_8 + performance.impressions || 0,
                            clicks_8: updatedCampaign.clicks_8 + performance.clicks || 0,
                            orders_8: updatedCampaign.orders_8 + performance.purchases14d || 0,
                        }
                    }
                }
            }
        });

        updatedCampaign = {
            ...updatedCampaign,
            spend_13: updatedCampaign.spend_8,
            sales_13: updatedCampaign.sales_8,
            impressions_13: updatedCampaign.impressions_8,
            clicks_13: updatedCampaign.clicks_8,
            orders_13: updatedCampaign.orders_8,
        }

        campaign.performance.forEach(performance => {
            for (let i = 60; i < 90; i++) {
                if (performance.dateRange === dateArray[i]) {
                    if (isSbSd) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_13: updatedCampaign.spend_13 + performance.cost || 0,
                            sales_13: updatedCampaign.sales_13 + performance.attributedSales14d || 0,
                            impressions_13: updatedCampaign.impressions_13 + performance.impressions || 0,
                            clicks_13: updatedCampaign.clicks_13 + performance.clicks || 0,
                            orders_13: updatedCampaign.orders_13 + performance.attributedConversions14d || 0,
                        }
                    } else {
                        updatedCampaign = {
                            ...updatedCampaign,
                            spend_13: updatedCampaign.spend_13 + performance.spend || 0,
                            sales_13: updatedCampaign.sales_13 + performance.sales14d || 0,
                            impressions_13: updatedCampaign.impressions_13 + performance.impressions || 0,
                            clicks_13: updatedCampaign.clicks_13 + performance.clicks || 0,
                            orders_13: updatedCampaign.orders_13 + performance.purchases14d || 0,
                        }
                    }
                }
            }
        })

        updatedCampaign = {
            ...updatedCampaign,
            cpc_1: (updatedCampaign.spend_1 / updatedCampaign.clicks_1).toFixed(2),
            cpc_4: (updatedCampaign.spend_4 / updatedCampaign.clicks_4).toFixed(2),
            cpc_8: (updatedCampaign.spend_8 / updatedCampaign.clicks_8).toFixed(2),
            cpc_13: (updatedCampaign.spend_13 / updatedCampaign.clicks_13).toFixed(2),
            acos_1: updatedCampaign.sales_1 === 0 ? 0 : ((updatedCampaign.spend_1 / updatedCampaign.sales_1) * 100).toFixed(2),
            acosTOS_1: updatedCampaign.salesTOS_1 === 0 ? 0 : ((updatedCampaign.spendTOS_1 / updatedCampaign.salesTOS_1) * 100).toFixed(2),
            acosDP_1: updatedCampaign.salesDP_1 === 0 ? 0 : ((updatedCampaign.spendDP_1 / updatedCampaign.salesDP_1) * 100).toFixed(2),
            acosROS_1: updatedCampaign.salesROS_1 === 0 ? 0 : ((updatedCampaign.spendROS_1 / updatedCampaign.salesROS_1) * 100).toFixed(2),
            acosB2B_1: updatedCampaign.salesB2B_1 === 0 ? 0 : ((updatedCampaign.spendB2B_1 / updatedCampaign.salesB2B_1) * 100).toFixed(2),
            acos_4: updatedCampaign.sales_4 === 0 ? 0 : ((updatedCampaign.spend_4 / updatedCampaign.sales_4) * 100).toFixed(2),
            acos_8: updatedCampaign.sales_8 === 0 ? 0 : ((updatedCampaign.spend_8 / updatedCampaign.sales_8) * 100).toFixed(2),
            acos_13: updatedCampaign.sales_13 === 0 ? 0 : ((updatedCampaign.spend_13 / updatedCampaign.sales_13) * 100).toFixed(2),
            ctr_1: updatedCampaign.impressions_1 === 0 ? 0 : ((updatedCampaign.clicks_1 / updatedCampaign.impressions_1) * 100).toFixed(2),
            ctr_4: updatedCampaign.impressions_4 === 0 ? 0 : ((updatedCampaign.clicks_4 / updatedCampaign.impressions_4) * 100).toFixed(2),
            ctr_8: updatedCampaign.impressions_8 === 0 ? 0 : ((updatedCampaign.clicks_8 / updatedCampaign.impressions_8) * 100).toFixed(2),
            ctr_13: updatedCampaign.impressions_13 === 0 ? 0 : ((updatedCampaign.clicks_13 / updatedCampaign.impressions_13) * 100).toFixed(2),
            spendPerDay: updatedCampaign.spend_1 / (endIndex - startIndex),
        };

        /*
        setSales({
            sales:
                [
                    sales.sales[0] + updatedCampaign.sales_1,
                    sales.sales[1] + updatedCampaign.sales_4,
                    sales.sales[2] + updatedCampaign.sales_8,
                    sales.sales[3] + updatedCampaign.sales_13
                ],
            spend:
                [
                    sales.sales[0] + updatedCampaign.sales_1,
                    sales.sales[1] + updatedCampaign.sales_4,
                    sales.sales[2] + updatedCampaign.sales_8,
                    sales.sales[3] + updatedCampaign.sales_13
                ],
        }
        )

        setSpends(
            [
                spends[0] + updatedCampaign.spend_1,
                spends[1] + updatedCampaign.spend_4,
                spends[2] + updatedCampaign.spend_8,
                spends[3] + updatedCampaign.spend_13
            ]
        )

        setSalesB2B(
            [
                salesB2B[0] + updatedCampaign.salesB2B_1,
                salesB2B[1] + updatedCampaign.salesB2B_4,
                salesB2B[2] + updatedCampaign.salesB2B_8,
                salesB2B[3] + updatedCampaign.salesB2B_13
            ]
        )

        setSpendsB2B(
            [
                spendsB2B[0] + updatedCampaign.spendB2B_1,
                spendsB2B[1] + updatedCampaign.spendB2B_4,
                spendsB2B[2] + updatedCampaign.spendB2B_8,
                spendsB2B[3] + updatedCampaign.spendB2B_13
            ]
        )
        */

        const classPlacementValues = "ml-5 items-end w-20"

        dataDiv = <div className="flex">
            <div key={"pataraBidDiv"} className='flex flex-col rounded-xl p-2 mx-1 border w-fit'>
                <BudgetForm key={camp.campaignId} target={camp.currBudget} char={"$"} campaignId={camp.campaignId}
                    campaignType={camp.campaignType && camp.campaignType.toLowerCase()} isFetchingCmpList={camp.isFetchingCmpList}
                    updatedCampaign={updatedCampaign}
                />
            </div>

            <div className="flex flex-col border rounded-xl p-2 w-36 px-4 justify-center">
                <div className="flex flex-col items-end">
                    <div className="px-1 text-gray-500 text-3xs">ACOS</div>
                    <div><AcosDisplay value={updatedCampaign.acos_1} spend={updatedCampaign.spend_1} className={"w-20"} /></div>
                </div>
                <div className="flex flex-col items-end my-2">
                    <div className="text-gray-500 text-3xs">Spend</div>
                    <div className="font-bold">${updatedCampaign.spend_1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                </div>
                <div className="flex flex-col items-end">
                    <div className="text-gray-500 text-3xs">Sales</div>
                    <div className="font-bold">${updatedCampaign.sales_1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                </div>
            </div>

            <div className="flex flex-row border rounded-xl mx-1 text-xs items-center">
                <div className="flex flex-col rounded-xl p-2">
                    <div className="flex flex-col items-end">
                        <div className="text-3xs text-gray-500">Orders</div>
                        <div>{updatedCampaign.orders_1.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                    </div>
                    <div className="flex flex-col my-2 items-end">
                        <div className="text-3xs text-gray-500">CPC</div>
                        <div>${updatedCampaign.cpc_1}</div>
                    </div>
                </div>
                <div className="flex flex-col rounded-xl mx-3 p-2">
                    <div className="flex flex-col items-end">
                        <div className="text-3xs text-gray-500">Impressions</div>
                        <div>{updatedCampaign.impressions_1.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                    </div>
                    <div className="flex flex-col items-end my-2">
                        <div className="text-3xs text-gray-500">Clicks</div>
                        <div>{updatedCampaign.clicks_1.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                    </div>
                    <div className="flex flex-col items-end">
                        <div className="text-3xs text-gray-500">CTR</div>
                        <div>{updatedCampaign.ctr_1}%</div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col border rounded-xl p-2">
                <div className="flex flex-col text-xxs">
                    <div className="text-gray-500">{"Placement"}</div>
                    <div className="flex flex-row items-center border rounded-xl px-2">
                        <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={camp.bidTos} label={"TOS"} />
                        <div className={classPlacementValues}>
                            <AcosDisplay value={updatedCampaign.acosTOS_1} spend={updatedCampaign.spendTOS_1} className={"w-14"} />
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Spend</div>
                            <div>{updatedCampaign.spendTOS_1 === 0 ? "-" : "$" + Number(updatedCampaign.spendTOS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Sales</div>
                            <div>{updatedCampaign.salesTOS_1 === 0 ? "-" : "$" + Number(updatedCampaign.salesTOS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center border rounded-xl px-2 my-1">
                        <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={camp.bidPdp} label={"PDP"} />
                        <div className={classPlacementValues}>
                            <AcosDisplay value={updatedCampaign.acosDP_1} spend={updatedCampaign.spendDP_1} className={"w-14"} />
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Spend</div>
                            <div>{updatedCampaign.spendDP_1 === 0 ? "-" : "$" + Number(updatedCampaign.spendDP_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Sales</div>
                            <div>{updatedCampaign.salesDP_1 === 0 ? "-" : "$" + Number(updatedCampaign.salesDP_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center border rounded-xl px-2">
                        <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={camp.bidRest} label={"REST"} />
                        <div className={classPlacementValues}>
                            <AcosDisplay value={updatedCampaign.acosROS_1} spend={updatedCampaign.spendROS_1} className={"w-14"} />
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Spend</div>
                            <div>{updatedCampaign.spendROS_1 === 0 ? "-" : "$" + Number(updatedCampaign.spendROS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Sales</div>
                            <div>{updatedCampaign.salesROS_1 === 0 ? "-" : "$" + Number(updatedCampaign.salesROS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center border rounded-xl px-2 my-1">
                        <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={camp.bidB2B} label={"B2B"} />
                        <div className={classPlacementValues}>
                            <AcosDisplay value={updatedCampaign.acosB2B_1} spend={updatedCampaign.spendB2B_1} className={"w-14"} />
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Spend</div>
                            <div>{updatedCampaign.spendB2B_1 === 0 ? "-" : "$" + Number(updatedCampaign.spendB2B_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                        <div className={classPlacementValues}>
                            <div className="text-3xs text-gray-500">Sales</div>
                            <div>{updatedCampaign.salesB2B_1 === 0 ? "-" : "$" + Number(updatedCampaign.salesB2B_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    return <div className="w-full">
        {dataDiv}
    </div>
}