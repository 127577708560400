import { useFetchReportTypesQuery } from "../../store"
import { IoMdRefresh } from "react-icons/io"
import ReportsList from "./ReportsList";

function ReportsPage() {
    const { data, isFetching, refetch } = useFetchReportTypesQuery()

    return (
        <div>
            <div className="flex justify-center m-3 py-2 border rounded-xl font-bold shadow">Reports</div>
            <div className="flex flex-col  m-2">
                <div className="flex cursor-pointer w-fit shadow ml-5 rounded-lg p-1 bg-sky-100" onClick={refetch}>
                    <div className='ml-1 mr-2 py-2 ' >
                        Reports List
                    </div>
                    <button className='text-lg'><IoMdRefresh /></button>
                </div>
                <div className='px-5 py-2 ' >
                    <ReportsList data={data} isFetching={isFetching} refetch={refetch} />
                </div>
            </div>
        </div>
    );
}

export default ReportsPage;