import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const tasksApi = createApi({
    reducerPath: 'tasks',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            createTask: builder.mutation({
                query: ({ task, desc, accountId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/createTask',
                        method: 'POST',
                        body: {
                            task,
                            desc,
                            accountId
                        }
                    }
                }
            }),
            editTask: builder.mutation({
                query: ({ task, desc, accountId, _id }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/editTask',
                        method: 'POST',
                        body: {
                            task,
                            desc,
                            accountId,
                            _id
                        }
                    }
                }
            }),
            getTasks: builder.query({
                query: ({ accountId }) => {
                    return {
                        url: '/api/getTasks',
                        method: 'GET',
                        params: {
                            accountId
                        }
                    }
                }
            }),
            deleteTask: builder.mutation({
                query: ({ id }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/deleteTask',
                        method: 'POST',
                        body: {
                            id
                        }
                    }
                }
            }),
            createNote: builder.mutation({
                query: ({ desc, accountId, campaignId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/createNote',
                        method: 'POST',
                        body: {
                            desc,
                            accountId,
                            campaignId
                        }
                    }
                }
            }),
            editNote: builder.mutation({
                query: ({ desc, campaignId, _id }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/editNote',
                        method: 'POST',
                        body: {
                            desc,
                            campaignId,
                            _id
                        }
                    }
                }
            }),
            getNotes: builder.query({
                query: ({ campaignId }) => {
                    console.log("get note api store")
                    return {
                        url: '/api/getNotes',
                        method: 'GET',
                        params: {
                            campaignId
                        }
                    }
                }
            }),
            deleteNote: builder.mutation({
                query: ({ id, campaignId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/deleteNote',
                        method: 'POST',
                        body: {
                            id,
                            campaignId
                        }
                    }
                }
            }),
        }
    }
});

export { tasksApi };
export const { useCreateTaskMutation, useGetTasksQuery, useDeleteTaskMutation, useEditTaskMutation,
    useCreateNoteMutation, useGetNotesQuery, useEditNoteMutation, useDeleteNoteMutation
} = tasksApi;