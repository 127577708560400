import { useFetchUsersQuery, useAutorizeUserMutation } from "../../store";
import UserAccounts from "./UserAccounts";

export default function UsersPage() {
    const { data, error, refetch } = useFetchUsersQuery()
    const [authorizeUser, response] = useAutorizeUserMutation()
    let usersDiv = <div></div>
    const checkboxClass = "flex flex-row items-center justify-center w-28"
    const headerClass = "flex flex-row items-center justify-center w-28"
    const userClass = "flex p-2 m-3 w-[45rem]"
    const authClass = "flex flex-row items-center justify-center w-40"

    const onAuthorizeClick = async (user) => {
        const userId = user._id
        console.log(userId)
        await authorizeUser({ userId })
        refetch()
    }
    if (response && response.isSuccess) {
        console.log(response)
    }
    if (error) {
        console.log(error)
        if (error.data && error.data.error) {
            usersDiv = <div className="text-red-600 font-bold px-5 py-2">
                {error.data.error}
            </div>
        } else {
            usersDiv = <div className="text-red-600 font-bold px-5 py-2">
                Error!
            </div>
        }
    } else if (data) {
        //console.log(data)
        const sortedData = [...data].sort((a, b) => {
            if (a.authorized && !b.authorized) {
                return -1
            } else if (!a.authorized && b.authorized) {
                return 1
            } else {
                return 0
            }
        })
        usersDiv = sortedData.map(user => {

            let authorizeButton = <div className={authClass}>
                <button onClick={() => onAuthorizeClick(user)} className="rounded-xl p-1 px-2 bg-green-600 text-white">Authorize</button>
            </div>
            if (user.authorized) {
                authorizeButton = <div className={authClass}>Authorized</div>
            }
            return (
                <div className="flex flex-row rounded-xl w-full border m-2" key={user._id}>
                    <div className={userClass}>
                        <div className="flex p-2 m-3 items-center">
                            <img src={user.googlePhotos[0].value} alt='profile pic' className="rounded-full h-16 w-16 drop-shadow"></img>
                            <div className="mx-2 text-sm" >
                                <div>{user.googleName}</div>
                                <div>{user.googleEmails[0].value}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        {authorizeButton}
                        <div className="flex flex-row w-full">
                            <div className={checkboxClass}>
                                <input type="checkbox"></input>
                            </div>
                            <div className={checkboxClass}>
                                <input type="checkbox"></input>
                            </div>
                            <div className={checkboxClass}>
                                <input type="checkbox"></input>
                            </div>
                            <div className={checkboxClass}>
                                <input type="checkbox"></input>
                            </div>
                            <div className={checkboxClass}>
                                <input type="checkbox"></input>
                            </div>
                            <div className={checkboxClass}>
                                <input type="checkbox"></input>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-32 text-center mx-4 items-center">
                        {user.authorized && <UserAccounts user={user} refetch={refetch}></UserAccounts>}
                    </div>
                </div>
            )
        })

    }

    return (
        <div>
            <div className="flex justify-center m-3 py-2 rounded-xl font-bold shadow">Users & Permissions</div>


            <div className="flex flex-col m-2">
                {(data) &&
                    <div key={"headers"} className="flex flex-row w-full m-2">
                        <div className={userClass} >
                            Users List
                        </div>
                        <div className="flex flex-row w-full">
                            <div className={authClass} >
                                <div className="rounded-xl p-1 px-2">Authorize</div>
                            </div>
                            <div className="flex flex-row w-full">
                                <div className={headerClass}>
                                    <div className="rounded-xl p-1 px-2 mx-2">Admin</div>
                                </div>
                                <div className={headerClass}>
                                    <div className="rounded-xl p-1 px-2 mx-2">Write</div>
                                </div>
                                <div className={headerClass}>
                                    <div className="rounded-xl p-1 px-2 mx-2">Read</div>
                                </div>
                                <div className={headerClass}>
                                    <div className="rounded-xl p-1 px-2 mx-2">Client</div>
                                </div>
                                <div className={headerClass}>
                                    <div className="rounded-xl p-1 px-2 mx-2">Guest</div>
                                </div>
                                <div className={headerClass}>
                                    <div className="rounded-xl p-1 px-2 mx-2">Authorize</div>
                                </div>
                            </div>

                        </div>
                        <div className="flex w-32 text-center mx-4 items-center">
                            {<div className="p-1 px-2">Authorized Accounts</div>}
                        </div>
                    </div>
                }
                <div className="w-full">
                    {usersDiv}
                </div>
            </div>

        </div>
    );
}

