export default function SalesRank({ salesRanks, id }) {
    const rankClass = "text-xs"
    const salesRanksDiv = salesRanks.map(salesRank => {
        const classificationRanksDiv = salesRank.classificationRanks.map(rank => {
            return <div className="flex items-end">
                <div className={rankClass}>{rank.rank}</div>
                <div className="mx-1">in</div>
                <a href={rank.link} rel='noreferrer' target="_blank" className="text-sky-700 underline" >{rank.title}</a>
            </div>
        })
        const displayGroupRanksDiv = salesRank.displayGroupRanks.map((rank, index) => {
            return <div className="flex items-end" key={index + "-" + id}>
                <div className={rankClass}>{rank.rank}</div>
                <div className="mx-1">in</div>
                <a href={rank.link} rel='noreferrer' target="_blank" className="text-sky-700 underline">{rank.title}</a>
            </div>
        })

        return (
            <div className="text-xxs" key={id}>
                <div>{classificationRanksDiv}</div>
                <div>{displayGroupRanksDiv}</div>
            </div>
        )
    })

    return (
        <div>
            {salesRanksDiv}
        </div>
    )
}