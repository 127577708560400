import { useState } from 'react'
import TargetingTableBulkUpdate from '../TargetingTableBulkUpdate'
import TargetDiv from './TargetDiv'
import PataraBid from '../PataraBid'
import DataDiv from './DataDiv'
export default function OptimizeX({ data, campaignId, avgPriceCmp, onSortClick }) {
    const [selectedFlatRows, setSelectedFlatRows] = useState([])
    //console.log(selectedFlatRows)

    let tableDiv = data.map((targeting, index) => {
        const weeks = [
            {
                title: "7 Days", targeting: {
                    spend: targeting.cost_1, sales: targeting.sales_1, acos: targeting.acos_1,
                    clicks: targeting.clicks_1, orders: targeting.orders_1, impressions: targeting.impressions_1
                }
            },
            {
                title: "30 Days", targeting: {
                    spend: targeting.cost_4, sales: targeting.sales_4, acos: targeting.acos_4,
                    clicks: targeting.clicks_4, orders: targeting.orders_4, impressions: targeting.impressions_4
                }
            },
            {
                title: "60 Days", targeting: {
                    spend: targeting.cost_8, sales: targeting.sales_8, acos: targeting.acos_8,
                    clicks: targeting.clicks_8, orders: targeting.orders_8, impressions: targeting.impressions_8
                }
            },
            {
                title: "90 Days", targeting: {
                    spend: targeting.cost_13, sales: targeting.sales_13, acos: targeting.acos_13,
                    clicks: targeting.clicks_13, orders: targeting.orders_13, impressions: targeting.impressions_13
                }
            },
            {
                title: "26 Weeks", targeting: {
                    spend: targeting.cost_26, sales: targeting.sales_26, acos: targeting.acos_26,
                    clicks: targeting.clicks_26, orders: targeting.orders_26, impressions: targeting.impressions_26
                }
            },
            {
                title: "52 Weeks", targeting: {
                    spend: targeting.cost_52, sales: targeting.sales_52, acos: targeting.acos_52,
                    clicks: targeting.clicks_52, orders: targeting.orders_52, impressions: targeting.impressions_52
                }
            }
        ]
        let dataDiv = weeks.map(week => {
            return <DataDiv title={week.title} targeting={week.targeting} onSortClick={onSortClick} key={targeting.asin + "" + week.title}></DataDiv>
        })
        let divClass = 'flex flex-row p-1 bg-sky-50 shadow rounded-xl m-1 my-3'
        if (index % 2 === 0) {
            divClass = 'flex flex-row p-1 bg-slate-100 shadow rounded-xl m-1 my-3'
        }
        return <div className={divClass} key={targeting.asin}>
            <div key={"targetDiv"} className='p-2 border w-fit rounded-xl'>
                <TargetDiv targeting={targeting} selectedFlatRows={selectedFlatRows}
                    setSelectedFlatRows={setSelectedFlatRows} key={targeting.asin}></TargetDiv>
            </div>
            <div key={"pataraBidDiv"} className='flex items-center rounded-xl p-2 mx-1 border w-fit'>
                <PataraBid row={targeting} id={targeting.keywordId} currBid={targeting.bid} key={targeting.asin}></PataraBid>
            </div>
            <div key={"dataDiv"} className='flex flex-row w-full'>
                {dataDiv}
            </div>
        </div>
    })


    return <div>
        <TargetingTableBulkUpdate key={"bulkupdate"} selectedFlatRows={selectedFlatRows} setSelectedFlatRows={setSelectedFlatRows}
            campaignId={campaignId} type={'sp'} avgPriceCmp={avgPriceCmp} length={data.length} tableData={data} />
        <div className="overflow-scroll max-h-screen3/4">
            {tableDiv}
        </div>
    </div>
}