import { useState, useEffect, useRef } from "react";
import { TiDeleteOutline } from "react-icons/ti";
import Panel from "../Panel";
import Panel2 from "../Panel2";
import classNames from "classnames";

function Dropdown({ options, onChange, value, defaultText, className, padding, hidden, colFilter }) {
    const [showMenu, setShowMenu] = useState(false);
    const [selected, setSelected] = useState()
    const divEl = useRef();
    const finalClassName = classNames('relative z-30', className);
    const panelClassName = classNames('flex justify-between select-none items-center', padding);
    const optionsClassName = classNames('absolute top-full max-h-96 text-3xs w-64 overflow-scroll mt-4');

    useEffect(() => {
        const handler = (event) => {
            //if div is not visible, return earlier
            if (!divEl.current) {
                return;
            }

            //if our ref div doesnt contain click event target (so it is outside of our div)
            if (!divEl.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('click', handler, true);

        return () => {
            document.removeEventListener('click', handler);
        };

    }, []);

    useEffect(() => {
        if (colFilter.length === 0) {
            setSelected(null)
        }
    }, [colFilter])


    const onSelectClick = () => {
        setShowMenu(!showMenu);
    };

    const onOptionClick = (label, value) => {
        setShowMenu(false)
        onChange(label, value, selected)
        setSelected(label)
    };

    const onRemoveClick = () => {
        onChange(null, null, selected)
        setSelected(null)
    }

    const renderedOptions = options.map((option) => {
        let className
        if (selected && option.label === selected) {
            className = "hover:bg-yellow-200 font-bold bg-yellow-100 rounded-xl curser-pointer p-3"
        } else {
            className = "hover:bg-sky-200 rounded-xl curser-pointer p-3"
        }
        return <div
            className={className}
            key={option.label ? option.label : 0}
            onClick={() => onOptionClick(option.label, option.value)} >
            {option.label}
        </div>
    });



    return <div ref={divEl} className={finalClassName} hidden={hidden}>
        <Panel2 className={panelClassName}>
            <div>
                <div onClick={onSelectClick} className="cursor-pointer underline underline-offset-2" >{value || defaultText}</div>
                {selected && <div className="flex flex-row">
                    <div className="text-3xs text-white rounded-lg bg-sky-500 px-1">{selected}</div>
                    <div className="cursor-pointer text-base" onClick={onRemoveClick}><TiDeleteOutline /></div>
                </div>}
            </div>

        </Panel2>
        {showMenu && <Panel className={optionsClassName} >
            {renderedOptions}
        </Panel>
        }
    </div>;
}

export default Dropdown;