import Dropdown from "./ColumnDropdown";

export default function ColumnEl({ className, text, options, onChange, colFilter }) {
    options.sort((a, b) => {
        const valueA = a.label;
        const valueB = b.label;
        return valueA.localeCompare(valueB);
    })
    return (
        <div className={"flex flex-col p-2 justify-center border-x " + className}>
            <Dropdown value={text} options={options} className={className}
                colFilter={colFilter} onChange={onChange}></Dropdown>
        </div>
    )
}