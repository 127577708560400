import { SiAddthis } from "react-icons/si";
import { useState } from "react";
import Modal from "../Modal";
import NewTask from "./NewTask";
import DeleteTask from "./DeleteTask";
import DuplicateTask from "./DuplicateTask"
import EditTask from "./EditTask";
import { useGetTasksQuery } from "../../store";

function ToDoList({ accountId }) {
    const [showModal, setShowModal] = useState(false)
    const { data, refetch } = useGetTasksQuery({ accountId })

    let listDiv = <div className='my-2 px-2 py-2 rounded-lg shadow'>
        There is no task for this account yet.
    </div>

    if (data) {
        //console.log(data)
        if (data.length > 0) {
            listDiv = data.map((task, index) => {
                return <div className={task.completed ? 'mb-5 p-5 rounded-lg shadow border flex flex-row bg-green-200' : 'mb-5 p-5 rounded-lg shadow border flex flex-row'}
                    key={index}>
                    <div className="flex flex-col w-full">
                        <div className="font-bold">{task.task}</div>
                        <div className="text-xs whitespace-pre-wrap mt-2 mb-4">{task.desc}</div>
                        <div className="flex flex-row p-1 px-2 shadow rounded-xl w-fit">
                            <div>
                                <div className="text-xxs text-gray-400">Created by: {task.ownerName}</div>
                                <div className="text-xxs text-gray-400">Created at: {task.createdAt}</div>
                            </div>
                            {
                                task.editedAt &&
                                <div className="ml-6">
                                    <div className="text-xxs text-gray-400">Last Edited by: {task.editorName}</div>
                                    <div className="text-xxs text-gray-400">Last Edited at: {task.editedAt}</div>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="flex items-center h-fit shadow rounded-xl p-1 px-2">
                        <div className="flex justify-end mr-4">
                            <div className="flex flex-col">
                                <EditTask accountId={accountId} refetch={refetch} task={task}></EditTask>
                            </div>
                        </div>
                        <div className="flex justify-end mr-4">
                            <div className="flex flex-col">
                                <DuplicateTask accountId={accountId} refetch={refetch} task={task}></DuplicateTask>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <div className="flex flex-col">
                                <DeleteTask id={task._id} refetch={refetch}></DeleteTask>
                            </div>
                        </div>
                    </div>
                </div>
            }).reverse()
        }
    }

    const onCreateClick = () => {
        setShowModal(true);
        console.log("new task create screen show")
    }

    function onChange() {
        setShowModal(false);
    };

    //AŞAĞIYA FORM COMPONENT EKLENECEK
    const modal = <Modal onChange={onChange} >
        <NewTask setShowModal={setShowModal} accountId={accountId} refetch={refetch}></NewTask>
    </Modal>


    return (
        <div>
            {showModal && modal}
            <div className="flex flex-col px-5 py-2 mt-1 shadow rounded-xl border">

                <div className='mx-3 px-5 py-3 rounded-xl text-white text-center bg-sky-800 shadow-md font-bold' >
                    MEETING NOTES
                </div>
                <div className='mx-3 my-3 p-2 flex flex-row items-center justify-start bg-green-600 text-white rounded-lg w-fit select-none hover:cursor-pointer hover:bg-green-500 shadow'
                    onClick={onCreateClick}>
                    <SiAddthis className="mr-2 text-lg text-white"></SiAddthis>
                    Create new task
                </div>
                <div className='mx-3 py-1 rounded-lg ' >
                    {listDiv}
                </div>
            </div>
        </div>
    )
}

export default ToDoList;