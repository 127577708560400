import AcosDisplay from "../AcosDisplayDiv"
export default function DataDiv({ title, targeting, onSortClick }) {
    const class1 = "flex flex-col m-1 basis-32 items-center w-fit"
    const class1Title = "text-xxs cursor-pointer text-gray-500"
    const class2 = "flex flex-col m-1 basis-32 w-fit"
    const class2Title = "text-3xs cursor-pointer text-gray-500"
    const class3 = "flex flex-col m-1 basis-32 w-fit text-center"
    const class4 = "flex flex-col m-1 basis-32 w-fit text-end"
    const class4Title = "text-3xs text-gray-500"
    let divClass = "flex flex-col p-1 px-2 basis-96 border rounded-xl mx-1"
    return <div className={divClass}>
        <div className="text-xxs text-gray-300 text-center">{title}</div>
        <div className="flex flex-row bg-yellow-50 rounded-xl">
            <div className={class1}>
                <div className={class1Title} onClick={() => onSortClick("acos" + title)}>ACOS</div>
                <div className="flex flex-row font-bold">
                    <AcosDisplay value={targeting.acos}
                        spend={targeting.spend}></AcosDisplay>
                </div>
            </div>
            <div className={class1}>
                <div className={class1Title} onClick={() => onSortClick("orders" + title)}>Orders</div>
                <div className="font-bold">{targeting.orders.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
            </div>
            <div className={class1 + " cursor-default"}>
                <div className={class1Title}>CPC</div>
                <div className="font-bold">{isNaN((targeting.spend / targeting.clicks)) ? "-" : "$" + (targeting.spend / targeting.clicks).toFixed(2)}</div>
            </div>
        </div>
        <div className="flex flex-row bg-white my-1 rounded-xl justify-around">
            <div className={class2}>
                <div className={class1Title} onClick={() => onSortClick("spend" + title)}>Spend</div>
                <div className="font-bold">${targeting.spend.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
            <div className={class2}>
                <div className={class1Title} onClick={() => onSortClick("sales" + title)}>Sales</div>
                <div className="font-bold">${targeting.sales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
        </div>
        <div className="flex flex-row text-xxs justify-around">
            <div className={class2}>
                <div className={class2Title} onClick={() => onSortClick("clicks" + title)}>Clicks</div>
                <div>{targeting.clicks.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
            </div>
            <div className={class3}>
                <div className={class2Title} onClick={() => onSortClick("impressions" + title)}>Impressions</div>
                <div>{targeting.impressions.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
            </div>
            <div className={class4}>
                <div className={class4Title}>CTR</div>
                <div className="">%{(targeting.impressions && (targeting.clicks / targeting.impressions * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) || "0.00"}</div>
            </div>
        </div>
    </div>
}