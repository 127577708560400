import { useFetchProductAdsQuery } from "../../../store"
import Skeleton from "../../Skeleton"
import AdDiv from "./AdDiv"
export default function ProductAds({ campaignId, accountId }) {
    const { data, isFetching } = useFetchProductAdsQuery({ campaignId, accountId })
    //ASIN component oluştur
    //listing image ve title bilgisini ASIN component içinde databaseden al
    let adsDiv = <div>No Data to Display</div>
    if (isFetching) {
        adsDiv = <div>
            <Skeleton times={10} className={"h-4 m-1 my-3 py-2"}></Skeleton>
        </div>
    }
    if (data) {
        console.log(data)
        adsDiv = data.productAds.map(elem => {
            return <div className="flex flex-row p-2 m-1 text-xxs">
                <AdDiv accountId={accountId} asin={elem.asin} sku={elem.sku}></AdDiv>
            </div>
        })
    }
    return <div className="">
        <div className="text-center overflow-scroll">Product Ads</div>
        <div className="overflow-scroll max-h-[60vh]">
            {adsDiv}
        </div>
    </div>
}