import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ImCancelCircle, ImCheckmark, ImPencil } from "react-icons/im";
import { useUpdateCampaignBudgetMutation } from "../../store";
import { GoSync } from "react-icons/go";
import BudgetUsage from "./BudgetUsage";

import Skeleton from "../Skeleton";


function BudgetForm({ target, char, campaignId, campaignType, isFetchingCmpList, updatedCampaign }) {
    const [updateBudget, res] = useUpdateCampaignBudgetMutation();
    const [formValue, setFormValue] = useState();
    const [budget, setBudget] = useState();
    const [submittable, setSubmittable] = useState(true);
    const [updateBidContentVisibility, setUpdateBidContentVisibility] = useState('edit-button');
    const accountId = useSelector(state => state.accounts.accountId);

    const classnameMain = 'flex items-center justify-center drop-shadow w-20 mr-1 border rounded-lg px-2';
    const [inputClassMain, setInputClassMain] = useState(classnameMain + " bg-white");

    useEffect(() => {
        setFormValue(target)
        setBudget(target)
        setUpdateBidContentVisibility('edit-button')

    }, [target]);

    useEffect(() => {
        if (res.data) {
            console.log(res.data)
            if (res.data.campaigns && res.data.campaigns.success.length > 0) setInputClassMain(classnameMain + " bg-green-500 text-white")
            else if (res.data.campaigns && res.data.campaigns.error.length > 0) setInputClassMain(classnameMain + " bg-red-500 text-white")
            else if (res.data[0] && res.data[0].code === "SUCCESS") setInputClassMain(classnameMain + " bg-green-500 text-white")
            else setInputClassMain(classnameMain + " bg-red-500 text-white")
        }
    }, [res.data]);



    const classname = 'w-14 mr-1 border-r border-t border-b rounded-r-lg px-2 focus:outline-0';
    const [inputClass, setInputClass] = useState(classname);

    const classname2 = 'flex justify-center bg-gray-100 border-l border-t border-b rounded-l-lg px-1 items-center w-6';
    const [inputClass2, setInputClass2] = useState(classname2);

    const classnameDiv = 'flex mx-2 rounded-lg w-36 justify-center p-1';
    const [inputClassDiv, setInputClassDiv] = useState(classnameDiv);


    const onApproveClick = async () => {
        if (submittable) {
            //create an api to update the existing campaign budget
            //updateValue({ id: accountId, targetValue: formValue });
            setBudget(formValue)
            await updateBudget({ accountId, campaignId, oldBudget: target, budget: Number(formValue), campaignType })
            setInputClass(classname);
            setInputClass2(classname2);
            setInputClassDiv(classnameDiv)
            setUpdateBidContentVisibility('edit-button');
        } else {
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
        }
    };

    const onCancelClick = () => {
        setInputClass(classname);
        setInputClass2(classname2);
        setInputClassDiv(classnameDiv)
        setUpdateBidContentVisibility('edit-button');
        setFormValue(budget);
    };

    const onEditClick = () => {
        setUpdateBidContentVisibility('form');
        setInputClassDiv(classnameDiv + ' bg-blue-200')
    };

    const onChange = (event) => {
        const value = event.target.value;
        if (!value || value === '0' || value.slice(value.length - 1) === '.' || value < 1 || value > 5000) {
            //setDisabled(true);
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
            setSubmittable(false);
            //make input border red
        } else if (!isNaN(value) && value.length < 6) {
            //setDisabled(false);
            setInputClass((classname + ' border-green-600'));
            setInputClass2((classname2 + ' border-green-600'));
            setSubmittable(true);
            //make input border gray again
        }
        if (!isNaN(value) && value.length < 6) {
            setFormValue(value);
        }
    };

    return (
        <div className="flex flex-col justify-around h-full">
            <BudgetUsage accountId={accountId} campaignId={campaignId} campaignType={campaignType}
                isFetchingCmpList={isFetchingCmpList} res={res && res.data} />
            <div>
                <div className="text-center text-xxs">Campaign Budget</div>
                <div className={inputClassDiv} >
                    <div className="flex flex-row">
                        {updateBidContentVisibility === 'edit-button'
                            ? <div className={inputClassMain}>
                                {isFetchingCmpList
                                    ? <div className="flex flex-row p-1 items-center"><Skeleton className="w-10 h-3 mb-0"></Skeleton></div>
                                    : <div >
                                        {char === '%' ? formValue + char : char + formValue}
                                    </div>}

                            </div>
                            : <div className="flex flex-row drop-shadow">

                                <div className={inputClass2}>
                                    {char}
                                </div>
                                <input
                                    className={inputClass}
                                    value={formValue}
                                    onChange={onChange} >
                                </input>
                            </div>
                        }
                        <button
                            onClick={onEditClick}
                            className='mx-1'
                            hidden={updateBidContentVisibility !== 'edit-button'}>
                            <ImPencil />
                        </button>
                        <button
                            onClick={onApproveClick}
                            className='mx-1'
                            hidden={updateBidContentVisibility !== 'form'}>
                            {res.isLoading ? <GoSync className="animate-spin" /> : <ImCheckmark />}
                        </button>
                        <button
                            onClick={onCancelClick}
                            className='mx-1'
                            hidden={updateBidContentVisibility !== 'form'}>
                            <ImCancelCircle />
                        </button>
                    </div>
                </div>
            </div>
            <div className="justify-items-center">
                <div className='text-4xs text-gray-400'>Average Daily Spent</div>
                <div className='flex text-xxs text-gray-700'>${(updatedCampaign.spendPerDay || 0).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            </div>
        </div>

    )

}

export default BudgetForm;