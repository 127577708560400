import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const listingsApi = createApi({
    reducerPath: 'listings',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchListings: builder.query({
                query: ({ accountId }) => {
                    return {
                        url: '/api/fetchListings',
                        method: 'GET',
                        params: {
                            accountId
                        }
                    }
                }
            }),
            fetchListingsFiltered: builder.query({
                query: ({ accountId, parent }) => {
                    return {
                        url: '/api/fetchListingsFiltered',
                        method: 'GET',
                        params: {
                            accountId,
                            parent
                        }
                    }
                }
            }),
            fetchParents: builder.query({
                query: ({ accountId, isDisplayedOnly }) => {
                    return {
                        url: '/api/fetchParents',
                        method: 'GET',
                        params: {
                            accountId,
                            isDisplayedOnly
                        }
                    }
                }
            }),
            updateParentsSku: builder.mutation({

                query: ({ accountId, asin, sku, priority, portfolio }) => {
                    console.log("updateParentSku - store: ", accountId, "-", asin, "-", sku, "-", priority, "-", portfolio)
                    return {
                        url: '/api/updateParentsSku',
                        method: 'POST',
                        body: {
                            accountId,
                            asin,
                            sku,
                            priority,
                            portfolio
                        }
                    }
                }
            }),
            updateParentState: builder.mutation({
                query: ({ state, accountId, id }) => {  //>>>
                    // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    console.log('targetingApi@ parent state: ', state)
                    console.log('targetingApi@ account id: ', accountId)
                    console.log('targetingApi@ asin id: ', id)
                    return {
                        url: '/api/targetingsp/updateparentstatus',
                        method: 'POST',
                        body: {
                            state,
                            accountId,
                            id
                        }
                    }
                }
            }),
            updateSkuPrice: builder.mutation({
                query: ({ accountId, formValue, sku, asin, productType, requestType, startDate, endDate }) => {
                    return {
                        url: '/api/listings/skuPriceUpdate',
                        method: 'POST',
                        body: {
                            accountId,
                            formValue,
                            sku,
                            asin,
                            productType,
                            requestType,
                            startDate,
                            endDate
                        }
                    }
                }
            }),
            updateSkuPrice2: builder.mutation({
                query: ({ accountId, formValue, sku, asin, productType, requestType, startDate, endDate }) => {
                    return {
                        url: '/api/listings/skuPriceUpdate2',
                        method: 'POST',
                        body: {
                            accountId,
                            formValue,
                            sku,
                            asin,
                            productType,
                            requestType,
                            startDate,
                            endDate
                        }
                    }
                }
            }),
            fetchSkuAttributes: builder.query({
                query: ({ accountId, sku }) => {
                    //console.log("Fetch attributes: ", accountId, "-", sku)
                    return {
                        url: '/api/spapi/getitemattributes',
                        method: 'GET',
                        params: {
                            accountId,
                            sku
                        }
                    }
                }
            }),
        }
    }
});

export { listingsApi };
export const { useFetchListingsQuery, useFetchParentsQuery,
    useUpdateParentsSkuMutation, useUpdateParentStateMutation,
    useFetchListingsFilteredQuery, useUpdateSkuPriceMutation, useUpdateSkuPrice2Mutation,
    useFetchSkuAttributesQuery } = listingsApi;