import { useState, useEffect, useRef } from "react";
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import Panel from "./Panel";
import classNames from "classnames";

function Dropdown({ options, isDetailed, onChange, value, defaultText, className, padding, hidden, id }) {
    const [showMenu, setShowMenu] = useState(false);
    const divEl = useRef();
    const finalClassName = classNames('relative z-30 select-none text-xs', className);
    const panelClassName = classNames('flex justify-between items-center cursor-pointer', padding);
    const optionsClassName = classNames('absolute top-full max-h-96 overflow-scroll mt-1');

    useEffect(() => {
        const handler = (event) => {
            //if div is not visible, return earlier
            if (!divEl.current) {
                return;
            }

            //if our ref div doesnt contain click event target (so it is outside of our div)
            if (!divEl.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('click', handler, true);

        return () => {
            document.removeEventListener('click', handler);
        };

    }, []);

    const onSelectClick = () => {
        setShowMenu(!showMenu);
    };

    const onOptionClick = (label, value) => {
        setShowMenu(false);
        onChange(label, value);
    };

    const renderedOptions = options.map((option) => {
        let className
        if (id && option.value === id) {
            className = "hover:bg-yellow-200 font-bold bg-yellow-100 rounded-xl curser-pointer p-2 py-3"
        } else {
            className = "hover:bg-sky-200 rounded-xl curser-pointer p-2 py-3"
        }
        return <div
            className={className}
            key={option.value ? option.value : 0}
            onClick={() => onOptionClick(option.label, option.value)} >
            {option.label}
            {isDetailed && <div className="text-5xs">{option.details}</div>}
        </div>
    });

    return <div ref={divEl} className={finalClassName} hidden={hidden}>
        <Panel className={panelClassName}
            onClick={onSelectClick} >
            {value || defaultText}
            {!showMenu ? <GoChevronDown className="text-sm" /> : <GoChevronUp className="text-sm" />}
        </Panel>
        {
            showMenu && <Panel className={optionsClassName} >
                {renderedOptions}
            </Panel>
        }
    </div>;
}

export default Dropdown;