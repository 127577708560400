import { useFetchSalesTrafficReportQuery } from "../../store";

function SalesTrafficReport({ date, accountId }) {
    const { data, isFetching, refetch } = useFetchSalesTrafficReportQuery(accountId);
    let missingInfo = []
    let filteredPerformance = {
        orderedProductSales: 0,
        orderedProductSalesB2B: 0,
        unitsOrdered: 0,
        unitsOrderedB2B: 0,
        totalOrderItems: 0,
        totalOrderItemsB2B: 0,
        unitsRefunded: 0,
        claimsGranted: 0,
        claimsAmount: 0,
        shippedProductSales: 0,
        unitsShipped: 0,
        ordersShipped: 0,

        browserPageViews: 0,
        browserPageViewsB2B: 0,
        mobileAppPageViews: 0,
        mobileAppPageViewsB2B: 0,
        pageViews: 0,
        pageViewsB2B: 0,
        browserSessions: 0,
        browserSessionsB2B: 0,
        mobileAppSessions: 0,
        mobileAppSessionsB2B: 0,
        sessions: 0,
        sessionsB2B: 0,
        feedbackReceived: 0,
        negativeFeedbackReceived: 0,
    }
    if (isFetching) {
        return { filteredPerformance: "fetching", missingInfo: null }
    }
    else if (data) {

        const performanceArray = data.performance
        //console.log(performanceArray)
        const startDate = new Date(date.substring(0, 10))
        const endDate = new Date(date.substring(11, 21))
        const noOfDays = (endDate - startDate) / (24 * 60 * 60 * 1000) + 1

        for (let i = 0; i < noOfDays; i++) {
            const date1 = new Date(startDate)
            date1.setDate(startDate.getDate() + i)
            const dateStr1 = date1.toISOString().split('T')[0] + "-" + date1.toISOString().split('T')[0]
            //console.log(dateStr1)
            const performance = performanceArray.find((performance) => {
                return performance.dateRange === dateStr1
            })
            //console.log(performance)

            if (performance) {
                filteredPerformance.orderedProductSales = filteredPerformance.orderedProductSales + performance.orderedProductSales
                filteredPerformance.orderedProductSalesB2B = filteredPerformance.orderedProductSalesB2B + performance.orderedProductSalesB2B
                filteredPerformance.unitsOrdered = filteredPerformance.unitsOrdered + performance.unitsOrdered
                filteredPerformance.unitsOrderedB2B = filteredPerformance.unitsOrderedB2B + performance.unitsOrderedB2B
                filteredPerformance.totalOrderItems = filteredPerformance.totalOrderItems + performance.totalOrderItems
                filteredPerformance.totalOrderItemsB2B = filteredPerformance.totalOrderItemsB2B + performance.totalOrderItemsB2B
                filteredPerformance.unitsRefunded = filteredPerformance.unitsRefunded + performance.unitsRefunded
                filteredPerformance.claimsGranted = filteredPerformance.claimsGranted + performance.claimsGranted
                filteredPerformance.claimsAmount = filteredPerformance.claimsAmount + performance.claimsAmount
                filteredPerformance.shippedProductSales = filteredPerformance.shippedProductSales + performance.shippedProductSales
                filteredPerformance.unitsShipped = filteredPerformance.unitsShipped + performance.unitsShipped
                filteredPerformance.ordersShipped = filteredPerformance.ordersShipped + performance.ordersShipped

                filteredPerformance.browserPageViews = filteredPerformance.browserPageViews + performance.browserPageViews
                filteredPerformance.browserPageViewsB2B = filteredPerformance.browserPageViewsB2B + performance.browserPageViewsB2B
                filteredPerformance.mobileAppPageViews = filteredPerformance.mobileAppPageViews + performance.mobileAppPageViews
                filteredPerformance.mobileAppPageViewsB2B = filteredPerformance.mobileAppPageViewsB2B + performance.mobileAppPageViewsB2B
                filteredPerformance.pageViews = filteredPerformance.pageViews + performance.pageViews
                filteredPerformance.pageViewsB2B = filteredPerformance.pageViewsB2B + performance.pageViewsB2B
                filteredPerformance.browserSessions = filteredPerformance.browserSessions + performance.browserSessions
                filteredPerformance.browserSessionsB2B = filteredPerformance.browserSessionsB2B + performance.browserSessionsB2B
                filteredPerformance.mobileAppSessions = filteredPerformance.mobileAppSessions + performance.mobileAppSessions
                filteredPerformance.mobileAppSessionsB2B = filteredPerformance.mobileAppSessionsB2B + performance.mobileAppSessionsB2B
                filteredPerformance.sessions = filteredPerformance.sessions + performance.sessions
                filteredPerformance.sessionsB2B = filteredPerformance.sessionsB2B + performance.sessionsB2B
                filteredPerformance.feedbackReceived = filteredPerformance.feedbackReceived + performance.feedbackReceived
                filteredPerformance.negativeFeedbackReceived = filteredPerformance.negativeFeedbackReceived + performance.negativeFeedbackReceived
            } else missingInfo.push(date1.toISOString().split('T')[0])
        }
    }
    return { filteredPerformance, missingInfo, refetch }

}

export default SalesTrafficReport