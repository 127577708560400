import { SiAddthis } from "react-icons/si";
import { useState } from "react";
import Modal from "../../Modal";
import NewNote from "./NewNote";
import DeleteNote from "./DeleteNote";
import DuplicateNote from "./DuplicateNote"
import EditNote from "./EditNote";
import { useGetNotesQuery } from "../../../store";

export default function Notes({ accountId, campaignId }) {
    const [showModal, setShowModal] = useState(false)
    const { data, refetch } = useGetNotesQuery({ campaignId })

    let listDiv = <div className='my-2 px-2 py-2 rounded-lg text-gray-400 shadow'>
        There is not any notes for this campaign yet.
    </div>

    if (data) {
        if (data.length > 0) {
            listDiv = data.map((task, index) => {
                return <div className={task.completed ? 'mb-5 p-5 rounded-lg shadow flex flex-row bg-green-200' : 'mb-5 p-5 rounded-lg shadow flex flex-row'}
                    key={index}>
                    <div className="flex flex-col w-full">
                        <div className="text-xs whitespace-pre-wrap mt-2 mb-4">{task.note}</div>
                        <div className="flex flex-row p-1 px-2 shadow rounded-xl w-fit">
                            <div>
                                <div className="text-xxs text-gray-400">Created by: {task.ownerName}</div>
                                <div className="text-xxs text-gray-400">Created at: {task.createdAt}</div>
                            </div>
                            {
                                task.editedAt &&
                                <div className="ml-6">
                                    <div className="text-xxs text-gray-400">Last Edited by: {task.editorName}</div>
                                    <div className="text-xxs text-gray-400">Last Edited at: {task.editedAt}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex items-center h-fit shadow rounded-xl p-1 px-2">
                        <div className="flex justify-end mr-4">
                            <div className="flex flex-col">
                                <EditNote campaignId={campaignId} _id={task._id} refetch={refetch} task={task} key={campaignId}></EditNote>
                            </div>
                        </div>
                        <div className="flex justify-end mr-4">
                            <div className="flex flex-col">
                                <DuplicateNote campaignId={campaignId} refetch={refetch} task={task} key={campaignId}></DuplicateNote>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <div className="flex flex-col">
                                <DeleteNote id={task._id} campaignId={campaignId} refetch={refetch} key={campaignId}></DeleteNote>
                            </div>
                        </div>
                    </div>
                </div>
            }).reverse()
        }
    }

    const onCreateClick = () => {
        setShowModal(true);
        console.log("new task create screen show")
    }

    function onChange() {
        setShowModal(false);
    };

    //AŞAĞIYA FORM COMPONENT EKLENECEK
    const modal = <Modal onChange={onChange} >
        <NewNote setShowModal={setShowModal} accountId={accountId} refetch={refetch} campaignId={campaignId}></NewNote>
    </Modal>


    return (
        <div className=" overflow-scroll">
            {showModal && modal}
            <div>
                <div className="flex flex-col px-5 py-2 mt-1 rounded-xl ">

                    <div className='mx-3 px-5 py-3 rounded-xl text-white text-center bg-sky-800 shadow-md font-bold' >
                        CAMPAIGN NOTES
                    </div>
                    <div className='mx-3 my-3 p-2 flex flex-row items-center justify-start bg-green-600 text-white rounded-lg w-fit select-none hover:cursor-pointer hover:bg-green-500 shadow'
                        onClick={onCreateClick}>
                        <SiAddthis className="mr-2 text-lg text-white"></SiAddthis>
                        Create new note
                    </div>
                    <div className='flex flex-col mx-3 py-1 rounded-lg overflow-scroll' >
                        {listDiv}
                    </div>
                </div>
            </div>

        </div>
    )
}

