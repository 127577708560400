import { useState } from "react";
import { useFetchProfilesQuery, useSetAuthorizedProfilesMutation } from "../../store";
import Modal from "../Modal"
export default function UserAccounts({ user, refetch }) {
    const { data } = useFetchProfilesQuery();
    const [setAuthorizedAccounts] = useSetAuthorizedProfilesMutation()
    const [showModal, setShowModal] = useState(false)
    const [accounts, setAccounts] = useState(user.authorizedProfiles)
    let accountsDiv = <div>No Data</div>
    const onAccountsClick = () => {
        setShowModal(true)
    }
    const onModalClick = () => {
        setShowModal(false)
    }
    const onAccountCheck = (e) => {
        console.log(e.target.value, "-", e.target.checked)
        const accountId = e.target.value
        const checked = e.target.checked
        if (checked) {
            setAccounts([...accounts, accountId])
        } else {
            setAccounts([...accounts.filter(e => e !== accountId)])
        }

    }
    const onSaveClick = async () => {
        console.log(accounts)
        await setAuthorizedAccounts({ accounts, userId: user._id })
        refetch()
    }
    if (data) {
        accountsDiv = data.map(account => {
            const accountId = account.accountId
            const checked = user.authorizedProfiles.find(e => e === accountId.toString())
            return <div key={account.acountId}>
                <div className="flex flex-row items-center my-4 border shadow rounded-xl p-2 py-4">
                    <div className="w-48">{account.accountName}</div>
                    <div className="mx-3">
                        <input onChange={onAccountCheck} defaultChecked={checked} type="checkbox" value={accountId}></input>
                    </div>
                </div>
            </div>
        })
    }
    return (
        <div>
            {showModal && <Modal onChange={onModalClick}>
                <div className="">
                    <div key={user._id} className="p-5 shadow rounded-xl border text-center font-bold">Authorized Accounts: {user.googleName}</div>
                    <div className=" overflow-scroll max-h-screen50 py-2">
                        {accountsDiv}
                    </div>
                    <button onClick={onSaveClick} className="p-2 px-4 mt-3 rounded-lg bg-green-500 hover:bg-green-600 text-white">Save</button>
                </div>
            </Modal>}
            <button onClick={onAccountsClick} className="rounded-xl border p-1 px-2 hover:bg-gray-50 text-xs">Accounts</button>

        </div>

    )
}