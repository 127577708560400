import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HiMagnifyingGlass } from "react-icons/hi2";
import PortfoliosDropdown from "../PortfoliosDropdown";
import Dropdown from "../Dropdown";
import CampaignsList from "./CampaignsListAll";
import DateRangePickerCustom from "../DateRangePickerCustom";
import SelectAccountDiv from "../SelectAccountDiv"
import { updateSelectedDate } from "../../store";

function CampaignsPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [portfolioId, setPortfolioId] = useState(0);
    const [buttonColor, setButtonColor] = useState(0);
    const [searchText, setSearchText] = useState()
    const [type, setType] = useState(0)

    const dispatch = useDispatch()
    const selectedDate = useSelector(state => state.accounts.selectedDateCampaigns);
    const setSelectedDate = (selectedDate) => dispatch(updateSelectedDate(selectedDate))

    const dropdownOptions = [{ label: "All", value: 0 }, { label: "SP", value: "sp" }, { label: "SB", value: "sb" }, { label: "SD", value: "sd" }]

    const dateNow = new Date();
    let start = new Date();
    let end = new Date();
    if (selectedDate) {
        start = new Date(selectedDate.endDate)
        end = new Date(selectedDate.startDate)
    } else {
        const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (0));
        end.setDate(dateNow.getDate() - (29));
        start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
        end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
    }

    const [pickedDate, setPickedDate] = useState([
        {
            startDate: end,
            endDate: start,
            key: 'selection'
        }
    ]);
    //console.log(pickedDate[0]);

    const onTypeChange = (item, id) => {
        setType(id)
    }


    //butonlar için tarih hesaplama - karşılaştırma için CampaignList sayfasına bak
    const onClick = (days) => {
        let start = new Date();
        let end = new Date();
        const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (0));
        end.setDate(dateNow.getDate() - (days + -1));
        start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
        end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
        setButtonColor(days)
        setPickedDate([{ startDate: end, endDate: start, key: "selection" }])
        setSelectedDate({ startDate: end.toString(), endDate: start.toString(), key: "selection" })
    }

    const onSearchSubmit = (e) => {
        e.preventDefault()
        console.log(e.target[0].value)
        setSearchText(e.target[0].value)
    }

    const buttonsDivClass = "flex flex-row items-center px-1 mx-2 shadow rounded-xl border divide-x text-xs"
    const buttonClass = "rounded-lg px-2 h-8 w-10 border border-gray-50"
    const buttonSelectedClass = "rounded-lg px-2 w-10 h-8 shadow font-bold bg-sky-500 text-white"
    const searchClass = "rounded-xl border px-2 py-2 w-64"

    return (
        <div>
            {accountId
                ? <div className="flex flex-col h-screen">
                    < div className="flex px-5 py-1" >
                        <PortfoliosDropdown portfolioId={portfolioId} setPortfolioId={setPortfolioId} />
                        <Dropdown options={dropdownOptions} className="w-20 mr-3" defaultText="All"
                            onChange={onTypeChange} id={type} value={dropdownOptions.find(e => e.value === type).label}></Dropdown>
                        <DateRangePickerCustom setSelectedDate={setSelectedDate} setPickedDate={setPickedDate} pickedDate={pickedDate} setButtonColor={setButtonColor} defaultText="Pick a Date"></DateRangePickerCustom>
                        <div className={buttonsDivClass} id="timeframe-buttons">
                            <div className="p-1">
                                {buttonColor === 1
                                    ? <button onClick={() => onClick(1)} className={buttonSelectedClass}>1</button>
                                    : <button onClick={() => onClick(1)} className={buttonClass}>1</button>
                                }
                            </div>
                            <div className="p-1">
                                {buttonColor === 7
                                    ? <button onClick={() => onClick(7)} className={buttonSelectedClass}>7</button>
                                    : <button onClick={() => onClick(7)} className={buttonClass}>7</button>
                                }
                            </div>
                            <div className="p-1">
                                {buttonColor === 15
                                    ? <button onClick={() => onClick(15)} className={buttonSelectedClass}>15</button>
                                    : <button onClick={() => onClick(15)} className={buttonClass}>15</button>
                                }
                            </div>
                            <div className="p-1">
                                {buttonColor === 30
                                    ? <button onClick={() => onClick(30)} className={buttonSelectedClass}>30</button>
                                    : <button onClick={() => onClick(30)} className={buttonClass}>30</button>
                                }
                            </div>
                            <div className="p-1">
                                {buttonColor === 60
                                    ? <button onClick={() => onClick(60)} className={buttonSelectedClass}>60</button>
                                    : <button onClick={() => onClick(60)} className={buttonClass}>60</button>
                                }
                            </div>
                            <div className="p-1">
                                {buttonColor === 90
                                    ? <button onClick={() => onClick(90)} className={buttonSelectedClass}>90</button>
                                    : <button onClick={() => onClick(90)} className={buttonClass}>90</button>
                                }
                            </div>
                        </div>
                        <div className="flex items-center">
                            <form onSubmit={onSearchSubmit} className="flex flex-row">
                                <div className="flex flex-col">
                                    <input id="searchbar" className={searchClass} type="text" placeholder={"type to search"}></input>
                                </div>
                                <button className="mx-1 text-lg" type="submit"><HiMagnifyingGlass /></button>
                            </form>
                        </div>
                    </div >
                    <CampaignsList portfolioId={portfolioId} pickedDate={pickedDate[0]} accountId={accountId} searchText={searchText} type={type} />
                </div >
                : <SelectAccountDiv></SelectAccountDiv>
            }
        </div>

    )
}

export default CampaignsPage;