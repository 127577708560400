import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const usersApi = createApi({
    reducerPath: 'users',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            autorizeUser: builder.mutation({
                query: ({ userId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/users/authorizeUser',
                        method: 'POST',
                        body: {
                            userId
                        }
                    }
                }
            }),
            setAuthorizedProfiles: builder.mutation({
                query: ({ accounts, userId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/users/setAuthorizedProfiles',
                        method: 'POST',
                        body: {
                            accounts,
                            userId
                        }
                    }
                }
            }),
        }
    }
});

export { usersApi };
export const {
    useAutorizeUserMutation, useSetAuthorizedProfilesMutation
} = usersApi;