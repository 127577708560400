import { useSelector } from "react-redux";
import { useState } from "react";
import SelectAccountDiv from "../SelectAccountDiv"
import ListingsList from "./ListingsList"
import ParentDropdown from "./ParentDropdown";

export default function ListingPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [parent, setParent] = useState()

    //BURAYA PARENT DROPDOWN GELECEK VE PARENT SEÇİLDİKTEN SONRA ListingList gösterilecek


    return (
        <div>
            <div className="flex justify-center m-3 py-2 border rounded-xl font-bold shadow">Catalog Listings</div>
            {accountId
                ? <div>
                    <ParentDropdown parent={parent} setParent={setParent} />
                    {parent
                        ? <div className="my-1">
                            <div className="p-3">
                                <ListingsList accountId={accountId} parent={parent}></ListingsList>
                            </div>
                        </div >
                        : null
                    }
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }
        </div>
    )
}