import Link from "./Link";
import { useFetchUserQuery } from "../store";
import { MdOutlineContentPasteSearch, MdListAlt, MdBackupTable } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { AiOutlineDollar, AiOutlineFundView, AiOutlineDatabase, AiOutlineAmazon } from "react-icons/ai";
import { useSelector } from "react-redux";

function Sidebar() {
    const accountId = useSelector(state => state.accounts.accountId);
    const { data, error } = useFetchUserQuery();
    let renderedLinks;
    if (error) {
        console.log('fetch user error!!!');
        renderedLinks = <div className="mx-4 text-xxs">User not authorized</div>
        if (error.data && error.data.error === "Not Logged in") {
            //navigate("/admin")
        }
    } else if (data) {
        const links = [
            /*
            {
                label: 'Optimize SP', path: '/targeting', icon: <AiOutlineFundView />
            },
            */
            {
                label: 'OptimizeX SP', path: '/targetingx', icon: <AiOutlineFundView />
            },
            {
                label: 'Optimize SB', path: '/targetingsb', icon: <AiOutlineFundView />
            },
            {
                label: 'Optimize SD', path: '/targetingsd', icon: <AiOutlineFundView />
            },
            {
                label: 'CampaignX', path: '/campaignx', icon: <AiOutlineDollar />
            },
            {
                label: 'Campaigns', path: '/campaigns', icon: <AiOutlineDollar />
            },
            {
                label: 'Search Terms', path: '/searchterms', icon: <MdOutlineContentPasteSearch />
            },
            {
                label: 'Dashboard', path: '/dashboard', icon: <MdBackupTable />
            },
            {
                label: 'Sales', path: '/salestraffic', icon: <VscGraph />
            },
            {
                label: 'Listings', path: '/listings', icon: <MdListAlt />
            },
            {
                label: 'Parents', path: '/parents', icon: <AiOutlineDatabase />
            },
            {
                label: 'Reports', path: '/reports', icon: <HiOutlineDocumentReport />
            },
            {
                label: 'Users', path: '/users', icon: <FaUsers />
            },
            {
                label: 'Accounts', path: '/accounts', icon: <AiOutlineAmazon />
            },
            //{ label: 'Patarians', path: '/patarians' }
        ];

        renderedLinks = links.map((link) => {
            return <Link key={link.path} to={(accountId && link.path + "?accountid=" + accountId) || link.path} className='flex flex-col mx-1 items-center h-14 p-1 w-24 rounded-xl ' activeClassName='font-bold text-yellow-500 px-2 p-1 rounded-xl ' >
                <div className="flex text-3xl items-end ">
                    {link.icon}
                </div>
                <div className="text-3xs text-center">
                    {link.label}
                </div>
            </Link>
        });
    }


    return (<div className="px-1 pt-2 py-1 mb-3 overflow-y-scroll flex flex-row items-center border-b" >
        {renderedLinks}
    </div>);
};

export default Sidebar;