import { useRequestReportsMutation } from "../../store"
import Skeleton from "../Skeleton"

export default function ReportsList({ data, isFetching, refetch }) {
    const [requestReport] = useRequestReportsMutation()
    let finalDiv = <div></div>
    const onReportClick = async (value) => {
        await requestReport({ reportType: value })
        refetch()
    }
    if (isFetching) {
        finalDiv = <Skeleton times={7} key={1} className="h-20 w-full my-2" />
    } else if (data) {
        console.log(data)
        finalDiv = data.map(report => {
            let reportClassName = "font-bold cursor-pointer w-fit text-blue-600"
            let reportIsDisabled = false
            if (report.lastRequested) {
                const now = new Date()
                const minutesSinceLastRequest = (now - new Date(report.lastRequested)) / 1000 / 60
                console.log(minutesSinceLastRequest)
                if (minutesSinceLastRequest < 60) {
                    reportIsDisabled = true
                    reportClassName = "font-bold w-fit select-none text-gray-400"
                }

            }
            return <div className="flex flex-col shadow border rounded-md p-2 my-3" key={report.label}>
                {reportIsDisabled
                    ? <div className={reportClassName} title="Wait 60 minutes after last request">{report.label}</div>
                    : <div onClick={() => onReportClick(report.value)} className={reportClassName}>{report.label}</div>}
                <div className="mt-2">
                    <div className="text-3xs text-gray-500">Last Request Time</div>
                    <div className="flex">
                        <div className="text-xs">{(report.lastRequested && new Date(report.lastRequested).toISOString().split("T")[0]) || "Never"}</div>
                        <div className="text-xs mx-2">{(report.lastRequested && new Date(report.lastRequested).toISOString().split("T")[1].substring(0, 5)) || null}</div>
                    </div>
                </div>
            </div>
        })
    }
    return <div>
        <div>{finalDiv}</div>
    </div>

}