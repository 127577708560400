import Skeleton from '../Skeleton';
import { useFetchCampaignBudgetQuery } from "../../store";
import { useEffect } from 'react';

function pause(duration) {
    return new Promise((resolve) => {
        setTimeout(resolve, duration);
    });
};

export default function BudgetUsage({ accountId, campaignId, campaignType, isFetchingCmpList, res }) {
    const { data, isSuccess, isFetching, refetch } = useFetchCampaignBudgetQuery({ accountId, campaignId, campaignType });
    //const { data: dataSb, isSuccess: isSuccessSb, isFetching: isFetchingSb } = useFetchSbCampaignBudgetQuery({ accountId, campaignId });
    //const { data: dataSd, isSuccess: isSuccessSd, isFetching: isFetchingSd } = useFetchSdCampaignBudgetQuery({ accountId, campaignId });
    useEffect(() => {
        async function func() {
            if (res && res.campaigns.success.length > 0) {
                await pause(2000)
                refetch()
            }
        }
        func()
    }, [refetch, res])
    let budgetUsage
    let className = ""
    let outOfBudget
    let budgetUsageDiv = <Skeleton times={1} className="h-4 w-10 mx-1" />
    //if (isFetching || isFetchingSb || isFetchingSd) {
    if (isFetching) {
        <Skeleton times={1} className="h-4 w-10 mx-1" />
        //} else if (data && isSuccess && data.success && dataSb && isSuccessSb && dataSb.success && dataSd && isSuccessSd && dataSd.success) {
    } else if (data && isSuccess && data.success && data.success[0]) {
        //console.log(data)
        budgetUsage = data.success[0].budgetUsagePercent
        if (budgetUsage > 97) {
            className = " bg-red-600 text-white"
            outOfBudget = 1
        }
        else if (budgetUsage > 80) {
            className = " bg-orange-500 text-white"
            outOfBudget = 2
        }
        else if (budgetUsage > 60) {
            className = " bg-yellow-100 text-gray-900"
        }
        budgetUsageDiv = <div className="justify-items-center mb-1">
            {outOfBudget === 1 ? <div className="text-center bg-red-600 text-white text-4xs px-1 my-0.5 rounded-md">Out of Budget</div> :
                outOfBudget === 2 ? <div className="text-center bg-orange-500 text-white text-4xs px-1 my-1 rounded-md">Almost Out of Budget</div> : ""}
            <div className={"text-center text-xxs w-fit px-1 text-gray-700 rounded-md" + className}>% {budgetUsage.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        </div>
    } else if (data) {
        <div className='text-xxs'>Error!</div>
    }

    return <div className="justify-items-center">
        <div className='text-4xs text-gray-400'>Daily Budget Usage</div>
        {budgetUsageDiv}
    </div>
}