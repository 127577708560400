import BudgetForm from "./BudgetForm";
import AcosDisplay from "./AcosDisplayDiv";
import { useSelector } from "react-redux";
import PlacementForm from "./PlacementForm";
import Notes from "../Targetings/Notes/Notes"
import Modal from "../Modal";
import { useState } from "react";

export const GROUPED_COLUMNS = [

    {
        Header: 'Campaign Name',
        Footer: ({ column }) => {
            //console.log(column)
            return <div>Total Info Div</div>
        },
        accessor: 'campaignName',
        Cell: ({ row, value }) => {
            const [showNotesModal, setShowNotesModal] = useState(false)
            const lastUpdated = row.original.lastUpdated
            const budgetUpdated = row.original.budgetUpdated
            const accountId = useSelector(state => state.accounts.accountId);
            const campaignId = row.original.campaignId
            const campaignType = row.original.campaignType
            const notes = row.original.notes
            let notesButtonClass = "border rounded-xl shadow px-2 p-1 mx-1 text-gray-500"
            if (notes && notes.length > 0) {
                notesButtonClass = "border rounded-xl shadow px-2 p-1 mx-1 bg-sky-100"
            }
            const state = row.original.campaignStatus || row.original.state
            const stateDisplay = state === "PAUSED" || state === "paused" || state === "ARCHIVED" || state === "archived"
            const onNotesClick = () => {
                setShowNotesModal(true)
            }

            function onNotesModalChange() {
                setShowNotesModal(false);
            };
            let typeClassName = "flex items-center ml-2 text-white text-3xs text-center px-1 rounded-full"
            let url = "/targetingx"
            if (campaignType === "SP") {
                typeClassName = typeClassName + " bg-yellow-500"
                url = "/targetingx"
            } else if (campaignType === "SB") {
                typeClassName = typeClassName + " bg-blue-500"
                url = "/targetingsb"
            } else if (campaignType === "SD") {
                typeClassName = typeClassName + " bg-green-500"
                url = "/targetingsd"
            }
            return (
                <div className="pl-1 break-words">
                    <div className="flex border p-2 rounded-xl shadow bg-white">
                        <a href={url + "?campaignid=" + row.original.campaignId + "&accountid=" + accountId} rel='noreferrer' target="_blank" className='hover:text-sky-600'>
                            {value}
                        </a>
                        {campaignType && <div className={typeClassName}>{campaignType}</div>}
                    </div>
                    {stateDisplay && <div className="text-red-600">{state.toUpperCase()}</div>}
                    <div className="flex flex-row items-center my-2">
                        <div>
                            <div className="text-gray-300 text-xxs">{"Campaign ID: " + row.original.campaignId}</div>
                            <div className="text-gray-300 text-xxs">{"Portfolio ID: " + row.original.portfolioId}</div>
                        </div>
                        <div className="mx-3">
                            <div className="rounded-lg">
                                <div className="text-gray-700 text-xxs">{lastUpdated ? "Last Updated: " + lastUpdated.date : "Last Updated: Never"}</div>
                            </div>
                            <div className="rounded-lg">
                                <div className="text-gray-700 text-xxs">{budgetUpdated ? "Budget Updated: " + budgetUpdated.date + " ----- $" + budgetUpdated.oldBudget + " >>> $" + budgetUpdated.newBudget : "Budget Updated: Never"}</div>
                            </div>
                        </div>
                        <div className="flex items-center mx-3">
                            {showNotesModal && <Modal onChange={onNotesModalChange}>
                                <Notes campaignId={campaignId} accountId={accountId}></Notes>
                            </Modal>}
                            <button onClick={onNotesClick} className={notesButtonClass}>Notes</button>
                            {(notes && notes.length > 0) && <div className="text-3xs text-gray-500">{notes.length}</div>}
                        </div>
                    </div>


                </div>
            )
        }
    },
    {
        Header: 'Budget',
        Footer: 'Budget',
        accessor: 'currBudget',
        Cell: ({ row, value }) => {
            const campaignType = row.original.campaignType
            return (
                <div className='flex justify-center'>
                    <BudgetForm key={row.original.campaignId} target={value} char={"$"} campaignId={row.original.campaignId}
                        campaignType={campaignType && campaignType.toLowerCase()} isFetchingCmpList={row.original.isFetchingCmpList}
                        updatedCampaign={row.original}
                    />
                </div>
            )
        }
    },
    {
        Header: 'Placement',
        Footer: 'Placement',
        accessor: 'bidTos',
        Cell: ({ row }) => {
            const accountId = useSelector(state => state.accounts.accountId);
            const campaignId = row.original.campaignId
            const campaignType = row.original.campaignType

            return <div className="flex flex-col text-xxs">
                <div className="pl-3">{"TOTAL"}</div>
                <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={row.original.bidTos} label={"TOS"} />
                <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={row.original.bidPdp} label={"PDP"} />
                <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={row.original.bidRest} label={"REST"} />
                <PlacementForm accountId={accountId} campaignId={campaignId} campaignType={campaignType} defaultValue={row.original.bidB2B} label={"B2B"} />
            </div>
        }
    },
    {
        Header: 'ACOS',
        Footer: 'ACOS',
        accessor: 'acos_1',
        sortDescFirst: true,
        Cell: ({ value, row }) => {
            const target = useSelector(state => state.accounts.targetAcos);
            const spendTOS_1 = row.original.spendTOS_1
            const spendROS_1 = row.original.spendROS_1
            const spendDP_1 = row.original.spendDP_1
            const spendB2B_1 = row.original.spendB2B_1
            const salesTOS_1 = row.original.salesTOS_1
            const salesROS_1 = row.original.salesROS_1
            const salesDP_1 = row.original.salesDP_1
            const salesB2B_1 = row.original.salesB2B_1
            //const acosClass = "text-3xs text-gray-600 text-center"

            const placementAcos = [
                { spend: spendTOS_1, sales: salesTOS_1 },
                { spend: spendDP_1, sales: salesDP_1 },
                { spend: spendROS_1, sales: salesROS_1 },
                { spend: spendB2B_1, sales: salesB2B_1 },
            ]
            const placementAcosDiv = placementAcos.map(e => {
                let classname = "text-3xs text-gray-600 text-center rounded-xl m-[1px] mx-2"
                if (!isNaN(e.spend / e.sales) && ((e.spend / e.sales * 100) < (target * 0.8))) {
                    classname = classname + " bg-green-100"
                }
                return <div>
                    <div className={classname}>
                        {
                            isNaN(e.spend / e.sales)
                                ? "-"
                                : (e.spend > 0 && (e.sales === 0 || isNaN(e.sales)))
                                    ? "∞%"
                                    : ((e.spend / e.sales * 100).toFixed(2) + "%")
                        }
                    </div>
                </div>

            })
            return <div>
                <AcosDisplay value={value} row={row}></AcosDisplay>
                <div className="mt-1">
                    {placementAcosDiv}
                </div>
            </div>
        }
    },
    {
        Header: 'Spend',
        Footer: 'Spend',
        accessor: 'spend_1',
        sortDescFirst: true,
        Cell: ({ value, row }) => {
            const spendTOS_1 = row.original.spendTOS_1
            const spendROS_1 = row.original.spendROS_1
            const spendDP_1 = row.original.spendDP_1
            const spendB2B_1 = row.original.spendB2B_1

            return <div>
                <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{spendTOS_1 === 0 ? "-" : "$" + Number(spendTOS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{spendDP_1 === 0 ? "-" : "$" + Number(spendDP_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{spendROS_1 === 0 ? "-" : "$" + Number(spendROS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{spendB2B_1 === 0 ? "-" : "$" + Number(spendB2B_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            </div>
        }
    },
    {
        Header: 'Orders',
        Footer: 'Orders',
        accessor: 'orders_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Sales (1d)',
        Footer: 'Sales (1d)',
        accessor: 'sales1_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Sales (7d)',
        Footer: 'Sales (7d)',
        accessor: 'sales7_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Sales (14d)',
        Footer: 'Sales (14d)',
        accessor: 'sales_1',
        sortDescFirst: true,
        Cell: ({ value, row }) => {
            const salesTOS_1 = row.original.salesTOS_1
            const salesROS_1 = row.original.salesROS_1
            const salesDP_1 = row.original.salesDP_1
            const salesB2B_1 = row.original.salesB2B_1

            return <div>
                <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{salesTOS_1 === 0 ? "-" : "$" + Number(salesTOS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{salesDP_1 === 0 ? "-" : "$" + Number(salesDP_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{salesROS_1 === 0 ? "-" : "$" + Number(salesROS_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className='flex pr-1 justify-end text-3xs text-gray-600'>{salesB2B_1 === 0 ? "-" : "$" + Number(salesB2B_1.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            </div>
        }
    },
    {
        Header: 'Sales (30d)',
        Footer: 'Sales (30d)',
        accessor: 'sales30_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Clicks',
        Footer: 'Clicks',
        accessor: 'clicks_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Impress',
        Footer: 'Impress',
        accessor: 'impressions_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'CTR %',
        Footer: 'CTR %',
        accessor: 'ctr_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{value === 0 ? '-' : (value + "%")}</div>
    },
    {
        Header: 'CPC',
        Footer: 'CPC',
        accessor: 'cpc_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
    },

    /*
    {
        Header: 'Last 10 Days',
        Footer: 'Last 10 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_4',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'spend_4',
                id: "spend_4",
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value.toFixed(0)}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: 'Last 20 Days',
        Footer: 'Last 20 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_8',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'spend_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value.toFixed(0)}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: 'Last 30 Days',
        Footer: 'Last 30 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_13',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'spend_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value.toFixed(0)}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    */
]