import { FaGoogle, FaAmazon, FaFacebookF } from "react-icons/fa";
import { FaPowerOff } from "react-icons/fa";
import AccountDropdown from "./AccountDropdown";
import patara from './photos/patara.png';
import { updateAccount, useFetchUserQuery, updateCampaignId, updatePortfolioId } from "../store";
import { useDispatch, useSelector } from "react-redux";

function Header() {
    const dispatch = useDispatch();
    const { data, error, isFetching } = useFetchUserQuery();
    const accountName = useSelector(state => state.accounts.accountName);
    const accountId = useSelector(state => state.accounts.accountId);

    const loginButtonClass = 'flex py-3 px-2 mx-2 w-36 shadow text-white text-xxs rounded-lg items-center'
    const loginTextClass = 'ml-2'

    const loginButtonGoogle = <a href="/auth/google" >
        <div className={loginButtonClass + " bg-green-600"} >
            <FaGoogle></FaGoogle>
            <div className={loginTextClass}>
                Log in with Google
            </div>
        </div>
    </a>

    const loginButtonFacebook =
        <div className={loginButtonClass + " bg-gray-600 cursor-not-allowed"} >
            <FaFacebookF></FaFacebookF>
            <div className={loginTextClass}>
                Log in with Facebook
            </div>
        </div>

    const loginButtonAmazon =
        <div className={loginButtonClass + " bg-gray-600 cursor-not-allowed"} >
            <FaAmazon></FaAmazon>
            <div className={loginTextClass}>
                Log in with Amazon
            </div>
        </div>

    const setAccountId = (account) => {
        dispatch(updateAccount(account));
    };
    const updateCampaign = (campaign) => {
        dispatch(updateCampaignId(campaign));
    };
    const updatePortfolio = (portfolio) => {
        dispatch(updatePortfolioId(portfolio));
    };

    let items = (
        <div className="flex flex-row justify-between items-center">
            {loginButtonGoogle}
            {loginButtonAmazon}
            {loginButtonFacebook}
        </div>
    );

    let dropdown = null

    if (error) {
        console.log(error);
        if (error.data.error) {
            items = (
                <div className="flex flex-row justify-between items-center">
                    <div className="mx-5 px-3 text-sm" >
                        <div>{error.data.error}</div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        {loginButtonGoogle}
                    </div>
                </div>
            );
        } else if (error.data.googleEmails) {
            items = (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row justify-between items-center mx-2 shadow rounded-xl p-2">
                        <a href="/admin">
                            <img src={error.data.googlePhotos[0].value} alt='profile pic' className="rounded-full h-14 w-14"></img>
                        </a>
                        <div className="mx-2" >
                            <div className="text-xs">{error.data.googleName}</div>
                            <div className="text-3xs">{error.data.googleEmails[0].value}</div>
                        </div>
                    </div>
                    <div className="mx-2 px-3 text-xs font-bold text-red-600" >
                        <div>Not Authorized</div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <a href="/api/logout" >
                            <div className='flex p-1 text-lg text-red-600 rounded-md justify-center' >
                                <FaPowerOff />
                            </div>
                        </a>
                    </div>
                </div>
            );
        }
    } else if (isFetching) {
        items = null
    } else if (data) {
        items = (
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row justify-between items-center mx-4 shadow rounded-xl p-2">
                    <a href="/admin">
                        <img src={data.googlePhotos[0].value} alt='profile pic' className="rounded-full h-14 w-14"></img>
                    </a>
                    <div className="mx-2" >
                        <div className="text-xs">{data.googleName}</div>
                        <div className="text-3xs">{data.googleEmails[0].value}</div>
                    </div>
                </div>
                {
                    //account dropdown old place
                }
                <div >
                    <a href="/api/logout" >
                        <div className='flex p-1 text-lg text-red-600 rounded-md justify-center' >
                            <FaPowerOff />
                        </div>
                    </a>
                </div>
            </div>
        )
        dropdown = <div className="mx-3" >
            <AccountDropdown
                setAccountId={setAccountId}
                updateCampaign={updateCampaign}
                updatePortfolio={updatePortfolio}
            />
        </div>
    }

    return (
        <div className="flex flex-row justify-between items-center px-5 py-2 pb-3" >
            <div className="flex flex-row justify-between items-center">
                <div className="w-28 h-14" >
                    <a href="/admin" ><img src={patara} href="/admin" alt="patara logo" /></a>
                </div>
                <div className='mx-10 px-2 py-1 rounded-xl' >
                    <div className="flex">
                        <h1 className='flex font-bold items-end  text-xs' >PataraWeb</h1>
                        <div className='flex items-end justify-end text-gray-400 text-4xs ml-1' >
                            v.1.23.78
                        </div>
                    </div>
                    <div className='flex items-center text-xxs' >
                        <h3>Amazon Account Management</h3>
                    </div>
                </div>
                {dropdown}
                <div className='py-1 rounded-xl'>
                    <div className="text-xs font-bold">{accountName}</div>
                    <div className="text-xxs">{accountId}</div>
                </div>
            </div>
            {items}
        </div>
    );
}

export default Header;