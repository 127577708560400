import { useState } from "react";
import { IoCheckboxOutline } from "react-icons/io5";
import Dropdown from "../Dropdown";

function FilterDropdown({ setFilter }) {
    const [selected, setSelected] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selected2, setSelected2] = useState(null);
    const [selectedId2, setSelectedId2] = useState(null);
    const [selected3, setSelected3] = useState(null);
    const [selectedId3, setSelectedId3] = useState(null);
    const [formValue, setFormValue] = useState(0);
    const [filterInvisible, setFilterInvisible] = useState(true);
    const [filterInvisible2, setFilterInvisible2] = useState(true);
    const [filterInvisible3, setFilterInvisible3] = useState(true);
    const [filterInvisible4, setFilterInvisible4] = useState(true);
    const [infoInvisible, setInfoInvisible] = useState(true);
    const [info, setInfo] = useState(null)


    const onFilterChange = (item, id) => {
        if (id) {
            //BU FILTRE ONAY TUŞUNA BASILDIĞINDA ÜST BİLEŞENE GÖNDERİLECEK
            setSelected(item)
            setSelectedId(id)
            setFormValue(0)
            setFilterInvisible(false)
        } else {
            setFilter(null)
            setInfoInvisible(true)
            setSelected(null)
            setSelectedId(null)
            setSelected2(null)
            setSelectedId2(null)
            setSelected3(null)
            setSelectedId3(null)
            setFormValue(0)
            setFilterInvisible(true)
            setFilterInvisible2(true)
            setFilterInvisible3(true)
            setFilterInvisible4(true)
        }
    };

    const onFilterChange2 = (item, id) => {
        setSelected2(item)
        setSelectedId2(id)
        setFilterInvisible2(false)
    }

    const onFilterChange3 = (item, id) => {
        setSelected3(item)
        setSelectedId3(id)
        setFilterInvisible3(false)
        setFilterInvisible4(false)
    }

    const onFormChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value) && (value > 0 || value === '0' || value === "") && ((value.length < 9 && value.slice(5, 6) === '.') || (value.length < 8 && value.slice(4, 5) === '.') || (value.length < 7))) {
            setFormValue(value);
        }
    }

    //FİLTRE TÜRÜ SEÇİMİ
    const dropdownOptions = [
        {
            label: "ACOS",
            value: "acos"
        },
        {
            label: "Spend",
            value: "spend"
        },
        {
            label: "Orders",
            value: "orders"
        },
        {
            label: "Sales",
            value: "sales"
        },
        {
            label: "Clicks",
            value: "clicks"
        },
    ]

    let sortedDropdownOptions = [...dropdownOptions].sort((a, b) => {
        const valueA = a.label;
        const valueB = b.label;
        return valueA.localeCompare(valueB);
    });

    if (selectedId) {
        sortedDropdownOptions.unshift({ label: 'Remove Filter', id: null });
    }

    let filterDropdown = <Dropdown options={sortedDropdownOptions}
        onChange={onFilterChange} className="w-40 text-sm"
        id={selectedId}
        value={selected} defaultText="Filter by.."
    />

    //FİLTRE DETAYI SEÇİMİ
    const dropdownOptions3 = [
        {
            label: "Greater than",
            value: "great"
        },
        {
            label: "Equals to",
            value: "equal"
        },
        {
            label: "Less than",
            value: "less"
        },
    ]

    let filterDropdown3 = <Dropdown options={dropdownOptions3}
        onChange={onFilterChange3} className="w-36 text-sm"
        id={selectedId3}
        value={selected3} defaultText="Filter type.."
    />

    //FİLTRE DETAYI SEÇİMİ - TIMEFRAME
    const dropdownOptions2 = [
        {
            label: "Last Week",
            value: 1
        },
        {
            label: "4 Weeks",
            value: 2
        },
        {
            label: "8 Weeks",
            value: 3
        },
        {
            label: "13 Weeks",
            value: 4
        },
        {
            label: "26 Weeks",
            value: 5
        },
        {
            label: "52 Weeks",
            value: 6
        },
    ]

    let filterDropdown2 = <Dropdown options={dropdownOptions2}
        onChange={onFilterChange2} className="w-36 text-sm"
        id={selectedId2}
        value={selected2} defaultText="Filter type.."
    />

    //FORM DETAILS
    const classname = 'flex justify-center h-full focus:outline-0 bg-white w-14 pl-2 pr-1 ml-3 drop-shadow border border-gray-300 rounded-lg'

    //APPROVE BUTTON DETAILS
    const onApproveClick = () => {
        setFilter({ filter: selectedId, filterType: selectedId3, filterTime: selectedId2, value: formValue })
        setInfo(selected + " - " + selected2 + " - " + selected3 + " - " + formValue)
        setInfoInvisible(false)
    }

    //FILTER INFO DETAILS
    let filterInfoClass
    if (infoInvisible) {
        filterInfoClass = "flex"
    } else {
        filterInfoClass = "flex items-center border rounded-lg px-2 ml-3 text-white bg-sky-500"
    }

    return (
        <div className="flex flex-row">
            <div>{filterDropdown}</div>
            <div className="ml-3" hidden={filterInvisible}>{filterDropdown2}</div>
            <div className="ml-3" hidden={filterInvisible2}>{filterDropdown3}</div>
            <div hidden={filterInvisible3}>
                <input type='number' step={1} className={classname} value={formValue} onChange={onFormChange}></input>
            </div>
            <div className="ml-2 flex justify-center text-2xl items-center">
                <div hidden={filterInvisible4}>
                    <button onClick={onApproveClick}>
                        <IoCheckboxOutline />
                    </button>
                </div>
            </div>
            <div className={filterInfoClass}>
                <div hidden={infoInvisible}>{info}</div>
            </div>
        </div>
    );
}

export default FilterDropdown;