import { useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useFetchParentsQuery } from "../../store"
import Skeleton from '../Skeleton';
import { FaExternalLinkAlt } from "react-icons/fa"
import { BiSortAlt2 } from "react-icons/bi"
import EditForm from "./EditForm";
import SalesRank from "../SalesRank";

export default function ListingsList({ accountId }) {
    const { data, isFetching } = useFetchParentsQuery({ accountId })
    const [filter, setFilter] = useState({ id: "priority", label: "Priority" })
    const [searchText, setSearchText] = useState()
    const searchClass = "rounded-xl border px-2 py-2 w-64"
    let parentDiv = <div>No Info</div>
    let totalChildAsins = 0
    let totalParentAsins = 0

    const onFilterClick = () => {
        if (filter.id === "priority") setFilter({ label: "Sales", id: "sales" })
        else setFilter({ id: "priority", label: "Priority" })
    }
    const onSearchSubmit = (e) => {
        e.preventDefault()
        console.log(e.target[0].value)
        setSearchText(e.target[0].value)
    }

    if (isFetching) {
        parentDiv = <Skeleton times={10} className="h-10 w-full" />;
    } else if (data) {
        const dateNow = new Date();
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 90; i++) {
            let start = new Date();
            let end = new Date();
            const offset = new Date().getTimezoneOffset()
            start.setDate(dateNow.getDate() - (2 + i));
            end.setDate(dateNow.getDate() - (2 + i));
            start = new Date(start.getTime() + (offset * 60 * 1000))
            end = new Date(end.getTime() + (offset * 60 * 1000))
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Week ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }
        let sortedData = [...data]
        totalParentAsins = data.length
        sortedData = sortedData.map(parent => {
            let totalSales = 0, totalUnitsOrdered = 0
            parent.performance.forEach(performance => {
                for (let i = 0; i < 30; i++) {
                    if (performance.dateRange === dateArray[i]) {
                        totalSales = totalSales + performance.orderedProductSales
                        totalUnitsOrdered = totalUnitsOrdered + performance.unitsOrdered
                    }
                }
            })
            return {
                ...parent,
                totalSales,
                totalUnitsOrdered
            }
        })
        if (filter.id === "priority")
            sortedData.sort(function (a, b) { return (a.priority || 4) - (b.priority || 4) })
        else if (filter.id === "sales")
            sortedData.sort(function (a, b) { return (b.totalSales) - (a.totalSales) })
        if (searchText) {
            sortedData = sortedData.filter(parent => {
                return (parent.customParentName && parent.customParentName.includes(searchText))
                    || parent.skus[0].seller_sku.includes(searchText)
            })
        }

        parentDiv = sortedData.map((parent, index) => {

            totalChildAsins = totalChildAsins + parent.childAsins.length
            return <div key={index} className="flex flex-row m-1 my-4 p-2 bg-sky-50 border rounded-xl shadow items-center">
                <div>
                    <a href={"https://www.amazon.com/dp/" + parent.asin} rel='noreferrer' target="_blank">
                        <div className="text-xs p-2 w-[8vw] max-h-[16vh] rounded-xl overflow-hidden"><img src={parent.main_image} alt="main_image" className="rounded-xl shadow-lg" /></div>
                    </a>
                </div>
                <div className="py-2 mx-4">
                    <div className="flex flex-row">
                        <EditForm key={parent.asin} parent={parent} accountId={accountId}></EditForm>
                        <div className="flex flex-col mx-7">
                            <div className="text-center text-gray-300 text-xxs">{dateStartArray[29]} - {dateStartArray[0]}</div>
                            <div className="flex flex-row items-center rounded-xl p-2">
                                <div className="flex flex-col mx-1 border rounded-xl p-2 bg-white">
                                    <div className="text-3xs text-end text-gray-500">30-Day Sales</div>
                                    <div className="text-end">${parent.totalSales.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                </div>
                                <div className="flex flex-col mx-1 border rounded-xl p-2 bg-white">
                                    <div className="text-3xs text-end text-gray-500">30-Day Units</div>
                                    <div className="text-end">{parent.totalUnitsOrdered}</div>
                                </div>
                            </div>
                        </div>
                        {parent.salesRanks &&
                            <div className="flex flex-col mx-3">
                                <div className="text-gray-300 text-xxs">Sales Ranks</div>
                                <div className="flex flex-row items-center rounded-xl py-2">
                                    <SalesRank salesRanks={parent.salesRanks}></SalesRank>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="flex flex-row items-center">
                        <div className="font-bold mr-2">{parent.skus[0].seller_sku}</div>
                        <div className="mr-2">{parent.asin}</div>
                        <a href={"https://www.amazon.com/dp/" + parent.asin} rel='noreferrer' target="_blank">
                            <FaExternalLinkAlt></FaExternalLinkAlt>
                        </a>

                    </div>
                    <div className="my-1">{parent.item_name}</div>
                    <div className="text-xs">Brand: {parent.brand}</div>
                    <div className="text-xs">{parent.childAsins.length} Variations</div>
                </div>
            </div>
        })
    }
    return <div>
        <div className="flex flex-row px-2 shadow rounded-xl">
            <div className="p-2 text-xs">
                <div>{totalParentAsins} Parentage</div>
                <div>{totalChildAsins} Variations</div>
            </div>
            <div className="flex items-center">
                <div className="flex flex-row justify-center rounded-lg border shadow px-3 p-1 mx-2 hover:cursor-pointer w-24 text-center" onClick={onFilterClick}>
                    {filter.label}
                    <div className="text-xl mx-1"><BiSortAlt2 /></div>
                </div>
            </div>
            <div className="flex items-center">
                <form onSubmit={onSearchSubmit} className="flex flex-row">
                    <div className="flex flex-col">
                        <input id="searchbar" className={searchClass} type="text" placeholder={"type to search"}></input>
                    </div>
                    <button className="mx-1 text-lg" type="submit"><HiMagnifyingGlass /></button>
                </form>
            </div>

        </div>
        {parentDiv}
    </div>
}