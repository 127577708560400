import { useFetchCampaignsReportQuery, useFetchSbCampaignsReportQuery, useFetchSdCampaignsReportQuery } from "../../store";
import { useFetchPortfoliosQuery } from "../../store";

function SalesTrafficReport({ date, accountId, parent }) {
    const { data: data1, isFetching } = useFetchCampaignsReportQuery(accountId);
    const { data: dataSb1, isFetching: isFetchingSb } = useFetchSbCampaignsReportQuery(accountId);
    const { data: dataSd1, isFetching: isFetchingSd } = useFetchSdCampaignsReportQuery(accountId);
    const resPrtf = useFetchPortfoliosQuery(accountId)

    let data = data1
    let dataSb = dataSb1
    let dataSd = dataSd1

    let dateStr = []
    if (isFetching || isFetchingSb || isFetchingSd) return "fetching"
    if (data && dataSb && dataSd) {
        if (parent && resPrtf.data) {
            data = data1.filter(campaign => {
                //console.log("Campaign portfolio name: ", resPrtf)
                return campaign.portfolioId && (
                    resPrtf.data.find(portfolio => portfolio.portfolioId === campaign.portfolioId).name
                        .includes(parent.portfolioName || parent.customParentName) ||
                    campaign.campaignName.includes(parent.portfolioName || parent.customParentName)
                )
            })
            dataSb = dataSb1.filter(campaign => {
                //console.log("Campaign portfolio name: ", resPrtf)
                return campaign.portfolioId && (
                    resPrtf.data.find(portfolio => portfolio.portfolioId === campaign.portfolioId).name
                        .includes(parent.portfolioName || parent.customParentName) ||
                    campaign.campaignName.includes(parent.portfolioName || parent.customParentName)
                )
            })
            dataSd = dataSd1.filter(campaign => {
                //console.log("Campaign portfolio name: ", resPrtf)
                return campaign.portfolioId && (
                    resPrtf.data.find(portfolio => portfolio.portfolioId === campaign.portfolioId).name
                        .includes(parent.portfolioName || parent.customParentName) ||
                    campaign.campaignName.includes(parent.portfolioName || parent.customParentName)
                )
            })
        }
        const startDate = new Date(date.substring(0, 10))
        const endDate = new Date(date.substring(11, 21))
        const noOfDays = (endDate - startDate) / (24 * 60 * 60 * 1000) + 1

        for (let i = 0; i < noOfDays; i++) {
            const date1 = new Date(startDate)
            date1.setDate(startDate.getDate() + i)
            dateStr.push(date1.toISOString().split('T')[0] + "-" + date1.toISOString().split('T')[0])
        }
        //console.log(dateStr)

        const newData = data.map(campaign => {
            let updatedCampaign = {
                ...campaign,
                sales: 0,
                spend: 0
            }
            for (let i = 0; i < noOfDays; i++) {
                //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                const day = campaign.performance.find(day => day.dateRange === dateStr[i])
                //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                if (day) {
                    updatedCampaign = {
                        ...updatedCampaign,
                        sales: updatedCampaign.sales + day.sales7d,
                        spend: updatedCampaign.spend + day.spend
                    }
                }
            }
            return updatedCampaign
        })

        const newDataSb = dataSb.map(campaign => {
            let updatedCampaign = {
                ...campaign,
                sales: 0,
                spend: 0
            }
            for (let i = 0; i < noOfDays; i++) {
                //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                const day = campaign.performance.find(day => day.dateRange === dateStr[i])
                //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                if (day) {
                    updatedCampaign = {
                        ...updatedCampaign,
                        sales: updatedCampaign.sales + day.attributedSales14d,
                        spend: updatedCampaign.spend + day.cost
                    }
                }
            }
            return updatedCampaign
        })

        const newDataSd = dataSd.map(campaign => {
            let updatedCampaign = {
                ...campaign,
                sales: 0,
                spend: 0
            }
            for (let i = 0; i < noOfDays; i++) {
                //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                const day = campaign.performance.find(day => day.dateRange === dateStr[i])
                //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                if (day) {
                    updatedCampaign = {
                        ...updatedCampaign,
                        sales: updatedCampaign.sales + day.attributedSales14d,
                        spend: updatedCampaign.spend + day.cost
                    }
                }
            }
            return updatedCampaign
        })

        let sumData = {
            sales: 0, spend: 0
        }
        newData.forEach(updatedCampaign => {
            sumData.sales = sumData.sales + updatedCampaign.sales
            sumData.spend = sumData.spend + updatedCampaign.spend
        })
        newDataSb.forEach(updatedCampaign => {
            sumData.sales = sumData.sales + updatedCampaign.sales
            sumData.spend = sumData.spend + updatedCampaign.spend
        })
        newDataSd.forEach(updatedCampaign => {
            sumData.sales = sumData.sales + updatedCampaign.sales
            sumData.spend = sumData.spend + updatedCampaign.spend
        })

        return sumData
    }
}

export default SalesTrafficReport