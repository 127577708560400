import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const profilesApi = createApi({
    reducerPath: 'profiles',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            updateAcos: builder.mutation({
                query: ({ id, targetValue }) => {
                    return {
                        url: '/api/updateAcos',
                        method: 'POST',
                        body: {
                            id,
                            targetValue
                        }
                    }
                }
            }),
            updateAvgPrice: builder.mutation({
                query: ({ id, targetValue }) => {
                    return {
                        url: '/api/updateAvgPrice',
                        method: 'POST',
                        body: {
                            id,
                            targetValue
                        }
                    }
                }
            }),
            updateMaxBid: builder.mutation({
                query: ({ id, targetValue }) => {
                    return {
                        url: '/api/updateMaxBid',
                        method: 'POST',
                        body: {
                            id,
                            targetValue
                        }
                    }
                }
            }),
            addAdsAccount: builder.mutation({
                query: ({ accountId, accountName, marketplaceId, sellerId }) => {
                    return {
                        url: '/api/profiles/addAdsAccount',
                        method: 'POST',
                        body: {
                            accountId,
                            accountName,
                            marketplaceId,
                            sellerId
                        }
                    }
                }
            }),
            updateAdsAccount: builder.mutation({
                query: ({ accountId }) => {
                    return {
                        url: '/api/profiles/updateAdsAccount',
                        method: 'POST',
                        body: {
                            accountId
                        }
                    }
                }
            }),
            unarchieveAdsAccount: builder.mutation({
                query: ({ accountId }) => {
                    return {
                        url: '/api/profiles/unarchieveAdsAccount',
                        method: 'POST',
                        body: {
                            accountId
                        }
                    }
                }
            }),
            addSellerAccount: builder.mutation({
                query: ({ spapi_oauth_code, state, selling_partner_id, addedUserGoogleId,
                    addedUserGoogleName, addedUserGoogleEmail }) => {
                    console.log("add seller account api")
                    return {
                        url: '/oauth/addselleraccount',
                        method: 'POST',
                        body: {
                            spapi_oauth_code,
                            state,
                            selling_partner_id,
                            addedUserGoogleId,
                            addedUserGoogleName,
                            addedUserGoogleEmail
                        }
                    }
                }
            }),
            addAccount: builder.query({
                query: ({ spapi_oauth_code, state, selling_partner_id, addedUserGoogleId,
                    addedUserGoogleName, addedUserGoogleEmail }) => {
                    console.log("add seller account api: get")
                    return {
                        url: '/oauth/addselleraccount',
                        method: 'GET',
                        params: {
                            spapi_oauth_code,
                            state,
                            selling_partner_id,
                            addedUserGoogleId,
                            addedUserGoogleName,
                            addedUserGoogleEmail
                        }
                    }
                }
            }),
        }
    }
});

export { profilesApi };
export const { useUpdateAcosMutation, useUpdateAvgPriceMutation, useUpdateMaxBidMutation,
    useAddSellerAccountMutation, useAddAccountQuery, useAddAdsAccountMutation, useUpdateAdsAccountMutation,
    useUnarchieveAdsAccountMutation
} = profilesApi;