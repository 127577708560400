import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { targetingApi } from './apis/targetingApi';
import { campaignApi } from './apis/campaignApi';
import { retrieveApi } from './apis/retrieveApi';
import { reportingApi } from './apis/reportingApi';
import { accountsReducer } from './slices/accountsSlice';
import { pataraBidsReducer } from './slices/pataraBidsSlice';
import { searchTermApi } from './apis/searchTermApi';
import { salesReportApi } from './apis/spSalesReportApi';
import { profilesApi } from './apis/profilesApi';
import { tasksApi } from './apis/tasksApi';
import { listingsApi } from './apis/listingsApi';
import { usersApi } from './apis/usersApi';

export const store = configureStore({
    reducer: {
        [targetingApi.reducerPath]: targetingApi.reducer,
        [retrieveApi.reducerPath]: retrieveApi.reducer,
        [reportingApi.reducerPath]: reportingApi.reducer,
        [campaignApi.reducerPath]: campaignApi.reducer,
        [searchTermApi.reducerPath]: searchTermApi.reducer,
        [salesReportApi.reducerPath]: salesReportApi.reducer,
        [profilesApi.reducerPath]: profilesApi.reducer,
        [tasksApi.reducerPath]: tasksApi.reducer,
        [listingsApi.reducerPath]: listingsApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        accounts: accountsReducer,
        patarabids: pataraBidsReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(targetingApi.middleware)
            .concat(retrieveApi.middleware)
            .concat(reportingApi.middleware)
            .concat(campaignApi.middleware)
            .concat(searchTermApi.middleware)
            .concat(salesReportApi.middleware)
            .concat(profilesApi.middleware)
            .concat(tasksApi.middleware)
            .concat(listingsApi.middleware)
            .concat(usersApi.middleware)
    }
});

setupListeners(store.dispatch);

export {
    updateAccount, updateCampaignId, updatePortfolioId, updateAcosState, updateAvgPriceState,
    updateMaxBidState, updateSelectedDate, updateIsEnbCmpOnly
} from './slices/accountsSlice';

export { updatePataraBids } from './slices/pataraBidsSlice';

export {
    useFetchCampaignsReportQuery, useFetchAllCampaignsReportQuery,
    useFetchAllSbCampaignsReportQuery, useFetchSbCampaignsReportQuery, useFetchCampaignLastUpdatedQuery,
    useFetchSdCampaignsReportQuery, useFetchAllSdCampaignsReportQuery, useUpdateCampaignBudgetMutation,
    useUpdateCampaignPlacementMutation, useFetchProductAdsQuery, useFetchCampaignsListQuery,
    useFetchCampaignReportQuery
} from './apis/campaignApi';

export {
    useFetchTargetingsQuery, useFetchTargetingsSbQuery,
    useFetchTargetingsSdQuery, useFetchTargetingsListQuery,
    useFetchTargetingsListSbQuery, useFetchTargetingsListSdQuery,
    useUpdateBidMutation, useUpdateBidSbMutation, useUpdateBidSdMutation,
    useGetPataraBidQuery, useUpdateStateMutation
} from './apis/targetingApi';

export {
    useFetchProfilesQuery, useFetchCampaignsQuery, useFetchSbCampaignsQuery,
    useFetchSdCampaignsQuery, useFetchUserQuery, useFetchPortfoliosQuery,
    useFetchQuoteQuery, useFetchCampaignBudgetQuery, useFetchSbCampaignBudgetQuery, useFetchSdCampaignBudgetQuery,
    useFetchUser2Query, useFetchUsersQuery, useFetchCampaignsFromDatabaseQuery,
    useFetchAdsAccountsQuery, useFetchListingInfoQuery
} from './apis/retrieveApi';

export {
    useRequestReportsMutation, useRequestReports12Mutation, useFetchReportTypesQuery
} from './apis/reportingApi';

export {
    useUpdateAcosMutation, useUpdateAvgPriceMutation, useUpdateMaxBidMutation,
    useAddSellerAccountMutation, useAddAccountQuery, useAddAdsAccountMutation,
    useUpdateAdsAccountMutation, useUnarchieveAdsAccountMutation
} from './apis/profilesApi';

export {
    useFetchSearchTermsQuery
} from './apis/searchTermApi';

export {
    useFetchSalesReportQuery, useFetchAllSalesReportQuery, useFetchSalesTrafficReportQuery
} from './apis/spSalesReportApi';

export {
    useCreateTaskMutation, useGetTasksQuery, useDeleteTaskMutation, useEditTaskMutation,
    useCreateNoteMutation, useGetNotesQuery, useDeleteNoteMutation, useEditNoteMutation
} from './apis/tasksApi'

export {
    useFetchListingsQuery, useFetchParentsQuery, useUpdateParentsSkuMutation, useUpdateParentStateMutation,
    useFetchListingsFilteredQuery, useUpdateSkuPriceMutation, useUpdateSkuPrice2Mutation,
    useFetchSkuAttributesQuery
} from './apis/listingsApi'

export {
    useAutorizeUserMutation, useSetAuthorizedProfilesMutation
} from './apis/usersApi'
