import EditForm from "./EditForm"
import EditForm2 from "./EditForm2"
export default function SkusDiv({ asin }) {
    let skusDiv = asin.skus.filter(sku => {
        return sku.fulfillment_channel === "DEFAULT" || sku.fba_condition === "NewItem" || (!sku.fba_condition && sku.status === "Active")
    }
    ).map((listing, index2) => {
        return (
            <div key={index2} className="flex flex-row">
                <div className="text-xs p-2 basis-15p break-words overflow-hidden select-all">{listing.seller_sku}</div>
                <div className="text-xs p-1 basis-12p break-words overflow-hidden flex items-center">
                    <EditForm target={listing.price} char={"$"} sku={listing.seller_sku}
                        productType={asin.product_type} asin={asin.asin} requestType={"priceUpdate"}>
                    </EditForm>
                </div>
                <div className="text-xs p-1 basis-20p break-words overflow-hidden flex items-center">
                    <EditForm2 char={"$"} sku={listing.seller_sku}
                        productType={asin.product_type} asin={asin.asin} requestType={"salePriceUpdate"}>
                    </EditForm2>
                </div>
                <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <input readOnly className="w-[4vw] p-1 px-3 border rounded-xl" value={listing.quantity}></input>
                        : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.fulfillableQuantity) || (0)}</div>}
                </div>
                <div className="text-xs p-2 basis-5p break-words overflow-hidden flex justify-center ">{listing.fulfillment_channel === 'DEFAULT' ? "FBM" : "FBA"}</div>
                {
                    listing.status === "Active"
                        ? <div className="text-xs p-2 basis-5p break-words overflow-hidden justify-center text-green-700 font-bold">{listing.status}</div>
                        : <div className="text-xs p-2 basis-5p break-words overflow-hidden justify-center font-bold">{listing.status}</div>
                }

                {//<div className="text-xs p-2 basis-8p break-words overflow-hidden justify-center">{listing.listing_id}</div>
                }
                <div className="text-xs p-1 basis-6p overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <div className="px-1">-</div>
                        : <div className="w-[4vw] p-1 px-1 rounded-xl">{listing.fba_condition}</div>}
                </div>
                <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <div className="px-3">-</div>
                        : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.totalReservedQuantity) || (0)}</div>}
                </div>
                <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <div className="px-3">-</div>
                        : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.inboundShippedQuantity) || (0)}</div>}
                </div>
                <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <div className="px-3">-</div>
                        : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.inboundReceivingQuantity) || (0)}</div>}
                </div>
                <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <div className="px-3">-</div>
                        : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.reservedQuantity.fcProcessingQuantity) || (0)}</div>}
                </div>
                <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <div className="px-3">-</div>
                        : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.reservedQuantity.pendingCustomerOrderQuantity) || (0)}</div>}
                </div>
                <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                    {listing.fulfillment_channel === 'DEFAULT'
                        ? <div className="px-3">-</div>
                        : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.reservedQuantity.pendingTransshipmentQuantity) || (0)}</div>}
                </div>
            </div>
        )
    })
    skusDiv.unshift(
        <div key={-1} className="flex flex-row py-1 bg-white">
            <div className="flex text-xxs p-2 basis-15p">SKU</div>
            <div className="flex text-xxs p-2 basis-12p ">Price</div>
            <div className="flex text-xxs p-2 basis-20p ">Sale Price</div>
            <div className="flex text-xxs p-2 basis-5p ">Quantity</div>
            <div className="flex text-xxs p-2 basis-5p justify-center ">Fulfill.</div>
            <div className="flex text-xxs p-2 basis-5p ">Status</div>
            {//<div className="flex text-xxs p-2 basis-8p ">Listing Id</div>
            }
            <div className="flex text-xxs p-2 basis-6p ">FBA Condition</div>
            <div className="flex text-xxs p-2 basis-5p ">Reserved</div>
            <div className="flex text-xxs p-2 basis-5p ">Inbound Shp</div>
            <div className="flex text-xxs p-2 basis-5p ">Inbound Rcv</div>
            <div className="flex text-xxs p-2 basis-5p ">FC Pross.</div>
            <div className="flex text-xxs p-2 basis-5p ">Pend.Order</div>
            <div className="flex text-xxs p-2 basis-5p ">Pend.Trnsshp</div>
        </div>
    )
    return skusDiv
}