import { useFetchUser2Query, useFetchQuoteQuery } from "../../store";

function WelcomePage() {
    const { data, error } = useFetchUser2Query()
    const { data: dataQuote } = useFetchQuoteQuery()
    const oauthURI = "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.7e774af6-2a4b-4daa-a48b-fe3ef49d489a&state=amz2501x4652jci2115sVptr&version=beta"
    //const oauthURI = "/admin"
    let renderedContent
    if (error) { //eğer kullanıcı giriş yapmadıysa
        console.log('fetch user error!!!');
        renderedContent = <div>
            <div className="mx-4 text-red-700">User not logged in! Log in with Google to authorize your account.</div>
        </div>
    } else if (data) { //eğer giriş yapıldıysa
        renderedContent =
            <div className="flex flex-row">
                <div className="flex m-2 rounded-xl shadow">
                    <div className="flex p-2 m-3 items-center">
                        <img src={data.googlePhotos[0].value} alt='profile pic' className="rounded-full h-16 w-16 drop-shadow"></img>
                        <div className="mx-2 text-sm" >
                            <div>{data.googleName}</div>
                            <div>{data.googleEmails[0].value}</div>
                        </div>
                    </div>
                    <div className="flex flex-col p-2 m-3">
                        <div>
                            {renderedContent}
                        </div>
                        <div className="text-yellow-500">
                            <div className="font-bold">Welcome!</div>"Have a great day and have fun!"
                        </div>
                        <div className="mt-3 max-w-prose">
                            {dataQuote ? <div><div className="font-bold">Quote of the Day:</div>"{dataQuote[0].q}"  {dataQuote[0].a}</div> : ""}
                        </div>
                        <div className="mt-3 max-w-prose">
                            {dataQuote ? <div><div className="font-bold">Cat Fact: </div>"{dataQuote[1].fact}"</div> : ""}
                        </div>
                    </div>
                </div>
                <div className="flex bg-sky-50 m-2 rounded-xl shadow">
                </div>
                <div className="m-2 mt-10">
                    <div className="mx-2">Please make sure you are signed in to your account before proceeding!</div>
                    <div className="mx-2 my-1">Click below button to authorize PataraWeb to access your Amazon Seller Account</div>
                    <div className="flex flex-row justify-between items-center mx-1">
                        <a key={'/authorize'} href={oauthURI} referrerPolicy="no-referrer"
                            className='flex py-3 px-2 w-32 shadow text-white rounded-md bg-orange-600 justify-center' >{'Authorize'}
                        </a>
                    </div>
                </div>
            </div>
    }

    return (
        <div className="px-5 py-3">
            <div className="flex justify-center m-3 py-2 border rounded-xl font-bold shadow">PataraWeb</div>
            {renderedContent}
            <div className="w-fit">
                <a href="/games" ><div className="px-3 text-gray-200 text-xxs">1234</div></a>
            </div>
        </div>
    )
}
export default WelcomePage