import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const campaignApi = createApi({
    reducerPath: 'campaign',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchCampaignReport: builder.query({
                query: ({ accountId, campaignId, campaignType }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/campaignReport',
                        method: 'GET',
                        params: {
                            accountId,
                            campaignId,
                            campaignType
                        }
                    }
                }
            }),
            fetchCampaignsReport: builder.query({
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/campaignsReport',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),
            fetchSbCampaignsReport: builder.query({
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/sbCampaignsReport',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),
            fetchSdCampaignsReport: builder.query({
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/sdCampaignsReport',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),
            fetchAllCampaignsReport: builder.query({
                query: () => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/allCampaignsReport',
                        method: 'GET',
                        params: {}
                    }
                }
            }),
            fetchAllSbCampaignsReport: builder.query({
                query: () => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/allSbCampaignsReport',
                        method: 'GET',
                        params: {}
                    }
                }
            }),
            fetchAllSdCampaignsReport: builder.query({
                query: () => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/allSdCampaignsReport',
                        method: 'GET',
                        params: {}
                    }
                }
            }),
            fetchCampaignLastUpdated: builder.query({
                query: ({ id, type }) => {
                    return {
                        url: '/api/campaignLastUpdated',
                        method: 'GET',
                        params: {
                            id,
                            type
                        }
                    }
                }
            }),
            updateCampaignBudget: builder.mutation({
                query: ({ accountId, campaignId, budget, oldBudget, campaignType }) => {
                    return {
                        url: '/api/updateCampaignBudget',
                        method: 'POST',
                        body: {
                            accountId,
                            campaignId,
                            budget,
                            oldBudget,
                            campaignType
                        }
                    }
                }
            }),
            updateCampaignPlacement: builder.mutation({
                query: ({ accountId, campaignId, newValue, oldValue, campaignType, placementType }) => {
                    return {
                        url: '/api/updateCampaignPlacement',
                        method: 'POST',
                        body: {
                            accountId,
                            campaignId,
                            newValue,
                            oldValue,
                            campaignType,
                            placementType
                        }
                    }
                }
            }),
            fetchProductAds: builder.query({
                query: ({ campaignId, accountId }) => {
                    return {
                        url: '/api/productAds/fetchProductAds',
                        method: 'GET',
                        params: {
                            campaignId,
                            accountId
                        }
                    }
                }
            }),
            fetchCampaignsList: builder.query({
                //INCLUDES ALL CAMPAIGN TYPES - SP,SB,SD
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/campaignsList',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),
        }
    }
});

export { campaignApi };
export const { useFetchCampaignsReportQuery, useFetchAllCampaignsReportQuery, useFetchAllSbCampaignsReportQuery,
    useFetchSbCampaignsReportQuery, useFetchCampaignLastUpdatedQuery, useFetchSdCampaignsReportQuery,
    useFetchAllSdCampaignsReportQuery, useUpdateCampaignBudgetMutation, useUpdateCampaignPlacementMutation,
    useFetchProductAdsQuery, useFetchCampaignsListQuery, useFetchCampaignReportQuery
} = campaignApi;