import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { useFetchSdCampaignsQuery, useFetchPortfoliosQuery, updateCampaignId, updatePortfolioId } from "../store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useNavigation from '../hooks/use-navigation';

function CampaignsDropdown({ setCampaignId }) {
    const accountId = useSelector(state => state.accounts.accountId);
    const campaignId = useSelector(state => state.accounts.campaignId);
    const campaignName = useSelector(state => state.accounts.campaignName);
    const portfolioId = useSelector(state => state.accounts.portfolioId);
    const portfolioName = useSelector(state => state.accounts.portfolioName);
    console.log(campaignName, " - ", campaignId)
    console.log(portfolioName, " - ", portfolioId)
    const { navigate, currentPath } = useNavigation();
    const [search, setSearch] = useState(window.location.search);
    let searchCampaignId

    const { data, error, isFetching } = useFetchSdCampaignsQuery(accountId);
    const resPrtf = useFetchPortfoliosQuery(accountId);

    useEffect(() => {
        setCampaignId(campaignId);
    }, [accountId, setCampaignId, campaignId]);

    const dispatch = useDispatch();
    const updateCampaign = (account) => {
        dispatch(updateCampaignId(account));
    };
    const updatePortfolio = (account) => {
        dispatch(updatePortfolioId(account));
    };

    const onCmpgChange = (item, id) => {
        if (search) {
            navigate(currentPath)
            setSearch(null)
        }
        setCampaignId(id);
        updateCampaign({ campaignId: id, campaignName: item })
    };

    const onPrtfChange = (item, id) => {
        updatePortfolio({ portfolioId: id, portfolioName: item })
    };

    let portfolioDropdown;
    if (resPrtf.isFetching) {
        portfolioDropdown = <Dropdown options={[]}

            className="w-64 text-sm" defaultText="Loading..." />
    } else if (resPrtf.error) {
        console.log(error);
        portfolioDropdown = <Dropdown options={[]}
            className="w-64 text-sm" defaultText="Error!" />
    } else if (resPrtf.data) {
        const dropdownOptions = resPrtf.data
            .filter(portfolio => {
                return portfolio.state === "enabled";
            })
            .map(portfolio => {
                return {
                    label: portfolio.name,
                    value: portfolio.portfolioId
                }
            });

        let sortedDropdownOptions = [...dropdownOptions].sort((a, b) => {
            const valueA = a.label;
            const valueB = b.label;
            return valueA.localeCompare(valueB);
        });

        sortedDropdownOptions.unshift({ label: 'All Portfolios', id: null });

        portfolioDropdown = <Dropdown options={sortedDropdownOptions}
            onChange={onPrtfChange} className="w-64 text-sm"
            id={portfolioId}
            value={portfolioName} defaultText="All Portfolios" />
    }

    let campaignDropdown;
    if (isFetching) {
        campaignDropdown = <Dropdown options={[]}
            className="w-96 text-sm" defaultText="Loading..." />
    } else if (error) {
        console.log(error);
        campaignDropdown = <Dropdown options={[]}
            className="w-96 text-sm" defaultText="Error!" />
    } else if (data) {
        console.log(data)
        const dropdownOptions = data
            .filter(campaign => {
                return (campaign.state === "enabled" && (!portfolioId || campaign.portfolioId === portfolioId.toString()));
            })
            .map(campaign => {
                return {
                    label: campaign.name,
                    value: campaign.campaignId
                }
            });

        let sortedDropdownOptions = [...dropdownOptions].sort((a, b) => {
            const valueA = a.label;
            const valueB = b.label;
            return valueA.localeCompare(valueB);
        });

        campaignDropdown = <Dropdown options={sortedDropdownOptions}
            onChange={onCmpgChange} className="w-96 text-sm"
            id={campaignId}
            value={campaignName} defaultText="Select Campaign" />
        if (search && search.includes("campaignid=") && (search.split("accountid=")[1].split("&")[0] === accountId.toString() || !accountId)) {
            searchCampaignId = search.split("campaignid=")[1].split("&")[0]
            const campaign = data.find((campaign) => {
                return campaign.campaignId.toString() === searchCampaignId
            })
            setCampaignId(searchCampaignId);
            if (campaign) updateCampaign({ campaignId: searchCampaignId, campaignName: campaign.name })
            else updateCampaign({ campaignId: searchCampaignId, campaignName: "Auto-Selected" })
        }
    }

    return (
        <div className="flex flex-row">
            <div className="mr-3">{portfolioDropdown}</div>
            <div>{campaignDropdown}</div>
        </div>
    );
}

export default CampaignsDropdown;