import { useState } from "react";
import { useFetchListingsFilteredQuery } from "../../store"
import Skeleton from '../Skeleton';
import ColumnEl from "./ColumnEl";
import Info from "./InfoDiv";
import AsinDiv from "./AsinDiv";

export default function ListingsList({ accountId, parent }) {
    const { data, isFetching } = useFetchListingsFilteredQuery({ accountId, parent })
    const [filter, setFilter] = useState({ label: "Sales", id: "sales" })
    const [colFilter, setColFilter] = useState([])

    let listingsDiv = <div className="shadow p-2 rounded-xl border">No Data Received</div>
    let infoDiv = <div></div>
    let colorOptions = []
    let sizeOptions = []
    let modelOptions = []

    const dateNow = new Date();
    let dateArray = [], dateStartArray = [], dateEndArray = [];
    for (let i = 0; i < 90; i++) {
        let start = new Date();
        let end = new Date();
        const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (1 + i));
        end.setDate(dateNow.getDate() - (1 + i));
        start = new Date(start.getTime() + (offset * 60 * 1000))
        end = new Date(end.getTime() + (offset * 60 * 1000))
        dateStartArray[i] = start.toISOString().split('T')[0];
        dateEndArray[i] = end.toISOString().split('T')[0];
        dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
    }

    const onColChange = (label, value, selected) => {
        if (label) {
            if (selected) {
                setColFilter([...colFilter.filter(e => e.label !== selected), { label, value }])
            } else {
                setColFilter([...colFilter, { label, value }])
            }
        } else {
            setColFilter([...colFilter.filter(e => e.label !== selected)])
        }
    }

    if (isFetching) {
        listingsDiv = <Skeleton times={10} className="h-10 w-full" />;
    } else if (data) {
        if (data.length > 0) {
            let newData = data.map((asin) => {
                asin = {
                    ...asin,
                    sameMethod: false
                }
                let totalQuantity = 0
                asin.skus.forEach((sku1, index2) => {
                    if (index2 === 1 && sku1.fulfillment_channel === asin.skus[0].fulfillment_channel) {
                        //console.log("same method: ", asin.asin)
                        asin.sameMethod = true
                    } else if (index2 > 1 && asin.sameMethod) {
                        asin.skus.forEach((sku2, index3) => {
                            if (index3 !== index2 && sku1.fulfillment_channel !== sku2.fulfillment_channel) {
                                //console.log("changed sameMethod: ", asin.asin)
                                asin.sameMethod = false
                            }
                        })
                    }
                    if (sku1.fulfillment_channel === "DEFAULT" || sku1.fba_condition === "NewItem") {
                        if (sku1.inventory_details) totalQuantity = totalQuantity + sku1.inventory_details.fulfillableQuantity
                        else totalQuantity = totalQuantity + sku1.quantity
                    }
                })
                let totalSales = 0, totalUnitsOrdered = 0
                asin.performance.forEach(performance => {
                    for (let i = 0; i < 30; i++) {
                        if (performance.dateRange === dateArray[i]) {

                            totalSales = totalSales + performance.orderedProductSales
                            totalUnitsOrdered = totalUnitsOrdered + performance.unitsOrdered
                        }
                    }
                })
                asin = {
                    ...asin,
                    totalQuantity,
                    totalSales,
                    totalUnitsOrdered,
                    startDate: dateArray[29],
                    endDate: dateArray[0]
                }
                return asin
            })

            newData.forEach((asin) => {
                //COLUMN FILTER OPTIONS ------------------
                //value --> database object value name - if the label is null, assign --- as the label
                if (!sizeOptions.find(option => option.label === (asin.size || "---"))) {
                    sizeOptions.push({ label: asin.size || "---", value: "size" })
                }
                if (!colorOptions.find(option => option.label === (asin.color || "---"))) {
                    colorOptions.push({ label: asin.color || "---", value: "color" })
                }
                if (!modelOptions.find(option => option.label === (asin.modelNumber || "---"))) {
                    modelOptions.push({ label: asin.modelNumber || "---", value: "modelNumber" })
                }
            })

            //SORT LISTINGS --------------------------
            if (filter.id === "az") {
                newData.sort(function (a, b) {
                    let x = a.skus.find(sku => !sku.seller_sku.includes("amzn.gr.")).seller_sku.toLowerCase();
                    let y = b.skus.find(sku => !sku.seller_sku.includes("amzn.gr.")).seller_sku.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                })
            } else if (filter.id === "sales") {
                newData.sort(function (a, b) {
                    return b.totalSales - a.totalSales
                })
            }

            // COLUMN FILTER --------------------------------
            if (colFilter) {
                colFilter.forEach(colFilter => {
                    let text = colFilter.value
                    newData = newData.filter(asin => asin[text] === colFilter.label)
                })
            }

            listingsDiv = newData.map((asin, index1) => {
                return <AsinDiv index1={index1} asin={asin} ></AsinDiv>
            })
            infoDiv = <Info colFilter={colFilter} setColFilter={setColFilter} accountId={accountId}
                filter={filter} setFilter={setFilter} newData={newData} listingsDiv={listingsDiv}
                sizeOptions={sizeOptions} colorOptions={colorOptions} dateArray={dateArray}>
            </Info>
        }
        else {
            listingsDiv = <div className="shadow p-2 rounded-xl border">No Data Received from Database</div>
        }
    }

    return (

        <div className="min-h-[50vh]">
            <div className="z-10 sticky top-0 bg-white">
                {infoDiv}
                <div className="flex flex-row font-bold py-1 bg-white shadow rounded-xl border">
                    <div className={"flex flex-col p-2 justify-center border-x basis-6p"}>Main Image</div>
                    <div className={"flex flex-col p-2 justify-center border-x basis-8p"}>ASIN</div>
                    <div className={"flex flex-col p-2 justify-center border-x basis-30p"}>Title</div>
                    <div className={"flex flex-col p-2 justify-center border-x basis-7p"}>Parent</div>
                    <ColumnEl key="size" className="basis-7p" text="Size" options={sizeOptions} onChange={onColChange} colFilter={colFilter}></ColumnEl>
                    <ColumnEl key="color" className="basis-7p" text="Color" options={colorOptions} onChange={onColChange} colFilter={colFilter}></ColumnEl>
                    <ColumnEl key="model" className="basis-7p" text="Model" options={modelOptions} onChange={onColChange} colFilter={colFilter}></ColumnEl>
                </div>
            </div>
            <div className="max-h-[80vh] overflow-scroll ">
                {listingsDiv}
            </div>
        </div>
    )
}