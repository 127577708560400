import { useState } from "react";
import { useFetchTargetingsQuery, useFetchTargetingsListQuery } from "../../store";
import TargetingTable from './TargetingTable';
import Skeleton from '../Skeleton';
import Panel from "../Panel";
import OptimizeX from "./OptimizeX/OptimizeXTable";

function TargetingList({ campaignId, accountId, filter, custom }) {
    const { data, error, isFetching } = useFetchTargetingsQuery(campaignId);
    const { data: dataList } = useFetchTargetingsListQuery({ id: campaignId, accountId })
    const [sort, setSort] = useState("spend30 Days")

    let columns = [{ label: '4 Weeks', content: null }, { label: '8 Weeks', content: null }, { label: '13 Weeks', content: null }];
    let tableContent, performanceContent, finalContent;
    const onSortClick = (e) => {
        //console.log(e)
        setSort(e)
    }
    if (isFetching) {
        finalContent = columns.map((column, index) => {
            return (
                <Skeleton times={1} key={index} className="h-12 w-96 mx-4" />
            );
        })
        tableContent = <Skeleton times={5} className="h-12 w-full" />;
    } else if (error) {
        tableContent = <div>Error!!! Please contact Patara</div>
    } else if (data && dataList) {
        //console.log(dataList)
        //13 adet hafta başlangıç ve bitiş tarihleri
        const dateNow = new Date();
        const daysSinceLastWeek = dateNow.getDay();
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 52; i++) {
            let start = new Date();
            let end = new Date();
            const offset = new Date().getTimezoneOffset()
            start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * i));
            end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * i));
            //console.log(start)
            start = new Date(start.getTime() - (offset * 60 * 1000))
            end = new Date(end.getTime() - (offset * 60 * 1000))
            //console.log(start)
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Week ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }

        let dayArray = [], dayStartArray = [], dayEndArray = [];
        for (let i = 0; i < 95; i++) {
            let start = new Date();
            let end = new Date();
            const offset = new Date().getTimezoneOffset()
            start.setDate(dateNow.getDate() - (i));
            end.setDate(dateNow.getDate() - (i));
            start = new Date(start.getTime() + (offset * 60 * 1000))
            end = new Date(end.getTime() + (offset * 60 * 1000))
            dayStartArray[i] = start.toISOString().split('T')[0];
            dayEndArray[i] = end.toISOString().split('T')[0];
            dayArray[i] = dayStartArray[i] + "-" + dayEndArray[i];
            //console.log('Week ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }
        //console.log("Day End: ", dayArray[0])
        //console.log("Day Start 7: ", dayArray[6])
        //console.log("Day Start 30: ", dayArray[29])
        //console.log("Since Last Week: ", daysSinceLastWeek)

        //PAUSED OLAN TARGETINGLERI DATA ARRAYİ İÇİNDEN ÇIKART. AŞAĞIDA YENİ ARRAY İLE İŞLEM YAP
        /*
        const dataEnabled = data.filter(targeting => {
            return targeting.adKeywordStatus === "ENABLED";
        });
        */
        const dataEnabled = data


        if (dataEnabled.length > 0) {
            //console.log(data);
            const tableData = dataEnabled.map(targeting => {
                //console.log(targeting)
                const apiTarget = dataList.find(target => Number(target.targetId) === targeting.keywordId)
                const apiKeyword = dataList.find(target => Number(target.keywordId) === targeting.keywordId)
                let bid = 0
                if (apiTarget) {
                    bid = apiTarget.bid
                } else if (apiKeyword) {
                    bid = apiKeyword.bid
                }

                //console.log("bid from api: ", bid)
                let updatedTargeting = {
                    ...targeting,
                    cost_1: 0,
                    sales_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                    bid
                }
                if (targeting.performanceDaily) {
                    targeting.performanceDaily.forEach(performance => {

                        for (let i = 0; i < 7; i++) {
                            if (performance.dateRange === dayArray[i]) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    cost_1: updatedTargeting.cost_1 + performance.cost,
                                    sales_1: updatedTargeting.sales_1 + performance.sales14d,
                                    impressions_1: updatedTargeting.impressions_1 + performance.impressions,
                                    clicks_1: updatedTargeting.clicks_1 + performance.clicks,
                                    orders_1: updatedTargeting.orders_1 + performance.purchases14d,
                                }
                            }
                        }
                    });
                }

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_4: updatedTargeting.cost_1,
                    sales_4: updatedTargeting.sales_1,
                    impressions_4: updatedTargeting.impressions_1,
                    clicks_4: updatedTargeting.clicks_1,
                    orders_4: updatedTargeting.orders_1,
                }

                if (targeting.performanceDaily) {
                    targeting.performanceDaily.forEach(performance => {

                        for (let i = 7; i < 30; i++) {
                            if (performance.dateRange === dayArray[i]) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    cost_4: updatedTargeting.cost_4 + performance.cost,
                                    sales_4: updatedTargeting.sales_4 + performance.sales14d,
                                    impressions_4: updatedTargeting.impressions_4 + performance.impressions,
                                    clicks_4: updatedTargeting.clicks_4 + performance.clicks,
                                    orders_4: updatedTargeting.orders_4 + performance.purchases14d,
                                }
                            }
                        }
                    });
                }

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_8: updatedTargeting.cost_4,
                    sales_8: updatedTargeting.sales_4,
                    impressions_8: updatedTargeting.impressions_4,
                    clicks_8: updatedTargeting.clicks_4,
                    orders_8: updatedTargeting.orders_4,
                }

                if (targeting.performanceDaily) {
                    targeting.performanceDaily.forEach(performance => {
                        for (let i = 30; i < 60; i++) {
                            if (performance.dateRange === dayArray[i]) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    cost_8: updatedTargeting.cost_8 + performance.cost,
                                    sales_8: updatedTargeting.sales_8 + performance.sales14d,
                                    impressions_8: updatedTargeting.impressions_8 + performance.impressions,
                                    clicks_8: updatedTargeting.clicks_8 + performance.clicks,
                                    orders_8: updatedTargeting.orders_8 + performance.purchases14d,
                                }
                            }
                        }
                    });
                }

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_13: updatedTargeting.cost_8,
                    sales_13: updatedTargeting.sales_8,
                    impressions_13: updatedTargeting.impressions_8,
                    clicks_13: updatedTargeting.clicks_8,
                    orders_13: updatedTargeting.orders_8,
                }

                if (targeting.performanceDaily) {
                    targeting.performanceDaily.forEach(performance => {
                        for (let i = 60; i < 90; i++) {
                            if (performance.dateRange === dayArray[i]) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    cost_13: updatedTargeting.cost_13 + performance.cost,
                                    sales_13: updatedTargeting.sales_13 + performance.sales14d,
                                    impressions_13: updatedTargeting.impressions_13 + performance.impressions,
                                    clicks_13: updatedTargeting.clicks_13 + performance.clicks,
                                    orders_13: updatedTargeting.orders_13 + performance.purchases14d,
                                }
                            }
                        }
                    });
                }

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_26: 0,
                    sales_26: 0,
                    impressions_26: 0,
                    clicks_26: 0,
                    orders_26: 0,
                }

                if (targeting.performanceDaily) {
                    targeting.performance.forEach(performance => {
                        for (let i = 0; i < 26; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    cost_26: updatedTargeting.cost_26 + performance.cost,
                                    sales_26: updatedTargeting.sales_26 + performance.sales14d,
                                    impressions_26: updatedTargeting.impressions_26 + performance.impressions,
                                    clicks_26: updatedTargeting.clicks_26 + performance.clicks,
                                    orders_26: updatedTargeting.orders_26 + performance.purchases14d,
                                }
                            }
                        }
                    });
                }

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_52: updatedTargeting.cost_26,
                    sales_52: updatedTargeting.sales_26,
                    impressions_52: updatedTargeting.impressions_26,
                    clicks_52: updatedTargeting.clicks_26,
                    orders_52: updatedTargeting.orders_26,
                }

                if (targeting.performanceDaily) {
                    targeting.performance.forEach(performance => {
                        for (let i = 26; i < 52; i++) {
                            if (performance.dateRange === dateArray[i]) {
                                updatedTargeting = {
                                    ...updatedTargeting,
                                    cost_52: updatedTargeting.cost_52 + performance.cost,
                                    sales_52: updatedTargeting.sales_52 + performance.sales14d,
                                    impressions_52: updatedTargeting.impressions_52 + performance.impressions,
                                    clicks_52: updatedTargeting.clicks_52 + performance.clicks,
                                    orders_52: updatedTargeting.orders_52 + performance.purchases14d,
                                }
                            }
                        }
                    });
                }

                const histLeng = targeting.history.length
                let lastUpdate
                if (histLeng > 0) {
                    lastUpdate = targeting.history[histLeng - 1]
                }

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.cost_1 / updatedTargeting.clicks_1).toFixed(2),
                    cpc_4: (updatedTargeting.cost_4 / updatedTargeting.clicks_4).toFixed(2),
                    cpc_8: (updatedTargeting.cost_8 / updatedTargeting.clicks_8).toFixed(2),
                    cpc_13: (updatedTargeting.cost_13 / updatedTargeting.clicks_13).toFixed(2),
                    cpc_26: (updatedTargeting.cost_26 / updatedTargeting.clicks_26).toFixed(2),
                    cpc_52: (updatedTargeting.cost_52 / updatedTargeting.clicks_52).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.cost_1 / updatedTargeting.sales_1) * 100).toFixed(2),
                    acos_4: updatedTargeting.sales_4 === 0 ? 0 : ((updatedTargeting.cost_4 / updatedTargeting.sales_4) * 100).toFixed(2),
                    acos_8: updatedTargeting.sales_8 === 0 ? 0 : ((updatedTargeting.cost_8 / updatedTargeting.sales_8) * 100).toFixed(2),
                    acos_13: updatedTargeting.sales_13 === 0 ? 0 : ((updatedTargeting.cost_13 / updatedTargeting.sales_13) * 100).toFixed(2),
                    acos_26: updatedTargeting.sales_26 === 0 ? 0 : ((updatedTargeting.cost_26 / updatedTargeting.sales_26) * 100).toFixed(2),
                    acos_52: updatedTargeting.sales_52 === 0 ? 0 : ((updatedTargeting.cost_52 / updatedTargeting.sales_52) * 100).toFixed(2),
                    lastUpdate
                };
            })

            let spend = [0, 0, 0, 0, 0, 0], sales = [0, 0, 0, 0, 0, 0],
                orders5 = 0, sales5 = 0;

            tableData.forEach(item => {
                spend[0] = spend[0] + item.cost_1;
                sales[0] = sales[0] + item.sales_1;
                spend[1] = spend[1] + item.cost_4;
                sales[1] = sales[1] + item.sales_4;
                spend[2] = spend[2] + item.cost_8;
                sales[2] = sales[2] + item.sales_8;
                spend[3] = spend[3] + item.cost_13;
                sales[3] = sales[3] + item.sales_13;
                spend[4] = spend[4] + item.cost_26;
                sales[4] = sales[4] + item.sales_26;
                spend[5] = spend[5] + item.cost_52;
                sales[5] = sales[5] + item.sales_52;
                orders5 = orders5 + item.orders_52;
                sales5 = sales5 + item.sales_52;
            });

            //console.log(spend)

            let avgPriceCmp
            if (orders5 > 3) avgPriceCmp = sales5 / orders5

            tableData.forEach(item => {
                item.avgPriceCmp = avgPriceCmp
            });

            //BURADA FİLTRE ÇALIŞACAK
            let filteredTableData
            if (filter) {
                //console.log(filter)
                filteredTableData = filterIt({ filter, tableData })
            } else {
                //console.log("Filtre yok")
                filteredTableData = tableData
            }

            //-------------__SORT FUNCTION
            sortIt(filteredTableData, sort)

            //TABLE CONTENT OLUŞTURULMADAN ÖNCE ORT FİYAT HESAPLAMASI YAPILACAK.
            //TOPLAM SATIŞ / SİPARİŞ ADEDİ
            if (custom) {
                tableContent = (
                    <div>
                        <div className="text-xxs text-yellow-500">A new look for Optimization: OptimizeX</div>
                        <OptimizeX data={filteredTableData} campaignId={campaignId} avgPriceCmp={avgPriceCmp} onSortClick={onSortClick}></OptimizeX>
                    </div>
                    //<StickyTable data={tableData} />
                );
            } else {
                tableContent = (
                    <TargetingTable data={filteredTableData} campaignId={campaignId} avgPriceCmp={avgPriceCmp} />
                    //<StickyTable data={tableData} />
                )
            }

            const summaryClass = "flex flex-row justify-between"
            const summaryItemClass = "mx-3 font-bold"
            const summaryItemClass2 = "mx-3 text-3xs"

            const summary = spend.map((e, index) => {
                //console.log("index: ", index, "-", spend[index])

                return <div className={summaryClass}>
                    <div>
                        <div className={summaryItemClass2}>Spent</div>
                        <div className={summaryItemClass}>${Number(spend[index].toFixed(2)).toLocaleString()}</div>
                    </div>
                    <div>
                        <div className={summaryItemClass2}>Sales</div>
                        <div className={summaryItemClass}>${Number(sales[index].toFixed(2)).toLocaleString()}</div>

                    </div>
                    <div>
                        <div className={summaryItemClass2}>ACOS</div>
                        <div className={summaryItemClass}>{Number((spend[index] / sales[index] * 100).toFixed(2)).toLocaleString()}%</div>
                    </div>
                </div>
            })

            columns = [
                { label: '1 Week', content: summary[0] },
                { label: '4 Weeks', content: summary[1] },
                { label: '8 Weeks', content: summary[2] },
                { label: '13 Weeks', content: summary[3] },
                { label: '26 Weeks', content: summary[4] },
                { label: '52 Weeks', content: summary[5] },
            ];

            performanceContent = columns.map((column, index) => {
                return (
                    <div key={index} className="px-3 my-1 shadow rounded-xl">
                        <div className="mb-2 pb-1 text-xxs font-bold border-b-2 justify-center flex">{column.label}</div>
                        <div className="text-3xs">{column.content}</div>
                    </div>
                );
            })

            finalContent = <Panel >
                <div className="flex flex-row overflow-scroll justify-around">
                    {performanceContent}
                </div>
            </Panel >
        }
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-row px-5 py-1">
                {finalContent}
            </div>
            <div className="mx-5 my-1 text-sm">
                {tableContent}
            </div>
        </div >
    );
}

function filterIt({ filter, tableData }) {

    return tableData.filter(targeting => {
        let targetValue
        if (filter.filterTime === 1) {
            switch (filter.filter) {
                case "acos":
                    targetValue = targeting.acos_1
                    break;

                case "spend":
                    targetValue = targeting.cost_1
                    break;

                case "orders":
                    targetValue = targeting.orders_1
                    break;

                case "sales":
                    targetValue = targeting.sales_1
                    break;

                case "clicks":
                    targetValue = targeting.clicks_1
                    break;

                default:
                    break;
            }
        }
        else if (filter.filterTime === 2) {
            switch (filter.filter) {
                case "acos":
                    targetValue = targeting.acos_4
                    break;

                case "spend":
                    targetValue = targeting.cost_4
                    break;

                case "orders":
                    targetValue = targeting.orders_4
                    break;

                case "sales":
                    targetValue = targeting.sales_4
                    break;

                case "clicks":
                    targetValue = targeting.clicks_4
                    break;

                default:
                    break;
            }
        }
        else if (filter.filterTime === 3) {
            switch (filter.filter) {
                case "acos":
                    targetValue = targeting.acos_8
                    break;

                case "spend":
                    targetValue = targeting.cost_8
                    break;

                case "orders":
                    targetValue = targeting.orders_8
                    break;

                case "sales":
                    targetValue = targeting.sales_8
                    break;

                case "clicks":
                    targetValue = targeting.clicks_8
                    break;

                default:
                    break;
            }
        }
        else if (filter.filterTime === 4) {
            switch (filter.filter) {
                case "acos":
                    targetValue = targeting.acos_13
                    break;

                case "spend":
                    targetValue = targeting.cost_13
                    break;

                case "orders":
                    targetValue = targeting.orders_13
                    break;

                case "sales":
                    targetValue = targeting.sales_13
                    break;

                case "clicks":
                    targetValue = targeting.clicks_13
                    break;

                default:
                    break;
            }
        }
        else if (filter.filterTime === 5) {
            switch (filter.filter) {
                case "acos":
                    targetValue = targeting.acos_26
                    break;

                case "spend":
                    targetValue = targeting.cost_26
                    break;

                case "orders":
                    targetValue = targeting.orders_26
                    break;

                case "sales":
                    targetValue = targeting.sales_26
                    break;

                case "clicks":
                    targetValue = targeting.clicks_26
                    break;

                default:
                    break;
            }
        }
        else if (filter.filterTime === 6) {
            switch (filter.filter) {
                case "acos":
                    targetValue = targeting.acos_52
                    break;

                case "spend":
                    targetValue = targeting.cost_52
                    break;

                case "orders":
                    targetValue = targeting.orders_52
                    break;

                case "sales":
                    targetValue = targeting.sales_52
                    break;

                case "clicks":
                    targetValue = targeting.clicks_52
                    break;

                default:
                    break;
            }
        }

        targetValue = Number(targetValue)

        if (filter.filterType === "great") {
            return filter.value < targetValue
        }
        else if (filter.filterType === "equal") {
            return filter.value === targetValue
        }
        else if (filter.filterType === "less") {
            return filter.value > targetValue
        } else return false
    })
}

function sortIt(data, sort) {
    //console.log("sort: ", sort)
    if (sort) {
        let sortFunction
        switch (sort) {
            case "spend7 Days":
                sortFunction = (a, b) => a.cost_1 - b.cost_1
                break
            case "spend30 Days":
                sortFunction = (a, b) => a.cost_4 - b.cost_4
                break
            case "spend60 Days":
                sortFunction = (a, b) => a.cost_8 - b.cost_8
                break
            case "spend90 Days":
                sortFunction = (a, b) => a.cost_13 - b.cost_13
                break
            case "spend26 Weeks":
                sortFunction = (a, b) => a.cost_26 - b.cost_26
                break
            case "spend52 Weeks":
                sortFunction = (a, b) => a.cost_52 - b.cost_52
                break
            case "sales7 Days":
                sortFunction = (a, b) => a.sales_1 - b.sales_1
                break
            case "sales30 Days":
                sortFunction = (a, b) => a.sales_4 - b.sales_4
                break
            case "sales60 Days":
                sortFunction = (a, b) => a.sales_8 - b.sales_8
                break
            case "sales90 Days":
                sortFunction = (a, b) => a.sales_13 - b.sales_13
                break
            case "sales26 Weeks":
                sortFunction = (a, b) => a.sales_26 - b.sales_26
                break
            case "sales52 Weeks":
                sortFunction = (a, b) => a.sales_52 - b.sales_52
                break
            case "orders7 Days":
                sortFunction = (a, b) => a.orders_1 - b.orders_1
                break
            case "orders30 Days":
                sortFunction = (a, b) => a.orders_4 - b.orders_4
                break
            case "orders60 Days":
                sortFunction = (a, b) => a.orders_8 - b.orders_8
                break
            case "orders90 Days":
                sortFunction = (a, b) => a.orders_13 - b.orders_13
                break
            case "orders26 Weeks":
                sortFunction = (a, b) => a.orders_26 - b.orders_26
                break
            case "orders52 Weeks":
                sortFunction = (a, b) => a.orders_52 - b.orders_52
                break
            case "clicks7 Days":
                sortFunction = (a, b) => a.clicks_1 - b.clicks_1
                break
            case "clicks30 Days":
                sortFunction = (a, b) => a.clicks_4 - b.clicks_4
                break
            case "clicks60 Days":
                sortFunction = (a, b) => a.clicks_8 - b.clicks_8
                break
            case "clicks90 Days":
                sortFunction = (a, b) => a.clicks_13 - b.clicks_13
                break
            case "clicks26 Weeks":
                sortFunction = (a, b) => a.clicks_26 - b.clicks_26
                break
            case "clicks52 Weeks":
                sortFunction = (a, b) => a.clicks_52 - b.clicks_52
                break
            case "impressions7 Days":
                sortFunction = (a, b) => a.impressions_1 - b.impressions_1
                break
            case "impressions30 Days":
                sortFunction = (a, b) => a.impressions_4 - b.impressions_4
                break
            case "impressions60 Days":
                sortFunction = (a, b) => a.impressions_8 - b.impressions_8
                break
            case "impressions90 Days":
                sortFunction = (a, b) => a.impressions_13 - b.impressions_13
                break
            case "impressions26 Weeks":
                sortFunction = (a, b) => a.impressions_26 - b.impressions_26
                break
            case "impressions52 Weeks":
                sortFunction = (a, b) => a.impressions_52 - b.impressions_52
                break
            case "acos7 Days":
                sortFunction = (a, b) => a.acos_1 - b.acos_1
                break
            case "acos30 Days":
                sortFunction = (a, b) => a.acos_4 - b.acos_4
                break
            case "acos60 Days":
                sortFunction = (a, b) => a.acos_8 - b.acos_8
                break
            case "acos90 Days":
                sortFunction = (a, b) => a.acos_13 - b.acos_13
                break
            case "acos26 Weeks":
                sortFunction = (a, b) => a.acos_26 - b.acos_26
                break
            case "acos52 Weeks":
                sortFunction = (a, b) => a.acos_52 - b.acos_52
                break
            default: break
        }
        return data.sort(sortFunction).reverse()
    } else {
        return data
    }
}

export default TargetingList;