import { useState, useEffect } from "react";
import HistoryPanel from "../HistoryPanel";
import PauseToggle from "../PauseToggle";
export default function TargetDiv({ targeting, selectedFlatRows, setSelectedFlatRows, }) {
    const [selected, setSelected] = useState()
    let text = targeting.targeting;
    let value = targeting.targeting;
    //console.log(targeting)
    let category, brand, rating, prime;
    useEffect(() => {
        setSelected(selectedFlatRows && selectedFlatRows.find(e => e.keywordId === targeting.keywordId))
    }, [selectedFlatRows, targeting])
    const onSelectClick = ({ targeting }) => {
        if (selected) {
            setSelectedFlatRows([...selectedFlatRows.filter(e => e.keywordId !== targeting.keywordId)])
            setSelected(false)
        } else {
            setSelectedFlatRows([...selectedFlatRows, targeting])
            setSelected(true)
        }
    }
    const lastUpdate = targeting.lastUpdate
    const matchType = targeting.matchType
    let updatedDate = "Never", updatedTime = "", updatedFrom = "", updatedTo = "", updatedBy = ""
    if (lastUpdate) {
        const date = new Date(lastUpdate.updatedDate)
        updatedDate = date.toISOString().split('T')[0]
        updatedTime = date.toISOString().slice(11, 16)
        updatedFrom = lastUpdate.updatedFrom
        updatedTo = lastUpdate.updatedTo
        updatedBy = lastUpdate.updatedBy
    }
    if (value.includes('category="')) {
        const index = value.indexOf('"', 10);
        text = value.slice(10, index);
        category = value.slice(10, index);
        if (value.includes('brand="')) {
            const indexBrStart = value.indexOf('brand="') + 7;
            const indexBrEnd = value.indexOf('"', indexBrStart);
            brand = value.slice(indexBrStart, indexBrEnd);
        }
        if (value.includes('rating=')) {
            const indexRating = value.indexOf('rating=') + 7;
            rating = value.slice(indexRating);
        }
        if (value.includes('prime-shipping-eligible="')) {
            const indexPrime = value.indexOf('prime-shipping-eligible="') + 25;
            prime = value.slice(indexPrime, indexPrime + 5);
        }
    }
    let keywordIdDiv = <div className="text-4xs text-gray-300">{targeting.keywordId}</div>
    let matchTypeDiv = <div className="mb-1 text-4xs text-gray-300">{targeting.matchType}</div>
    let statusDiv = <div className="mx-1 text-4xs text-gray-400">{targeting.adKeywordStatus}</div>

    let targetingDiv
    if (value.includes('asin="')) {
        targetingDiv = <div>
            <a href={"https://www.amazon.com/dp/" + value.slice(6, 16)} rel='noreferrer' target="_blank">
                <div className='flex hover:text-sky-600 hover:cursor-pointer'>{value.slice(6, 16)}</div>
            </a>
        </div>
    } else if (value.includes('asin-expanded="')) {
        targetingDiv = <div>
            <a href={"https://www.amazon.com/dp/" + value.slice(15, 25)} rel='noreferrer' target="_blank">
                <div className='flex hover:text-sky-600 hover:cursor-pointer'>{value.slice(15, 25)}</div>
            </a>
        </div>
    } else if (value.includes('category="')) {
        targetingDiv = <div>
            <a href={"https://www.amazon.com/s?k=" + category} rel='noreferrer' target="_blank">
                <div className='flex hover:text-sky-600 hover:cursor-pointer'>{text}</div>
            </a>
            <div className="flex flex-row text-xxs mt-1">
                {brand && <div className="mr-3">Brand: {brand}</div>}
                {rating && <div>Rating: {rating}</div>}
                {prime && <div>Prime: {prime}</div>}
            </div>
        </div>
    } else if (matchType === 'EXACT' || matchType === 'PHRASE' || matchType === 'BROAD') {
        targetingDiv = <div>
            <div className='flex items-center'>
                <a href={"https://www.amazon.com/s?k=" + value} rel='noreferrer' target="_blank" className='flex hover:text-sky-600'>
                    {value}
                </a>
                {/*<div className="bg-sky-500 text-white rounded-full w-4 h-4 flex justify-center text-xxs m-1 lowercase font-bold">
                                    {targeting.matchType.slice(0, 1)}
                                </div>*/
                }
            </div>
        </div>
    } else {
        targetingDiv = <div>
            <div className='flex'>{value}</div>
        </div>
    }

    return (
        <div className="pl-1 break-words">
            <div className="flex flex-row bg-sky-100 px-2 py-1 rounded items-center">
                <input type="checkbox" onChange={() => onSelectClick({ targeting })} checked={selected}></input>
                <div className="flex items-center mx-1 w-full">{targetingDiv}</div>
                {//<div className="flex items-center text-3xs">{targeting.adKeywordStatus}</div>
                }

            </div>
            <div className="flex flex-row items-center">
                {keywordIdDiv}
                {statusDiv}

            </div>
            <div className="flex flex-row items-center">
                {matchTypeDiv}
                <div className="flex h-3 w-8 mx-1 justify-self-end">
                    <PauseToggle isChecked={targeting.adKeywordStatus} keywordId={targeting.keywordId} keyword={targeting.keyword} type={targeting.matchType || targeting.targetingType}></PauseToggle>
                </div>
            </div>

            {
                lastUpdate
                    ? <div className="flex flex-col text-xxs text-gray-700 bg-yellow-50 rounded-lg px-1 py-1 w-48">

                        <div className="flex flex-row items-center">
                            <div>{updatedDate} - {updatedTime}</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="font-bold">{updatedFrom + " >>> " + updatedTo}</div>
                            <div className="ml-2 text-gray-400">{updatedBy}</div>
                        </div>
                        {targeting.history && <div className="shadow rounded-md">
                            <HistoryPanel history={targeting.history}></HistoryPanel>
                        </div>}
                    </div>
                    : <div className="text-xxs text-gray-700 bg-gray-100 rounded-lg px-1 py-1">Last Update: Never</div>
            }

        </div>
    )
}
