import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GoSync } from "react-icons/go";
import { FcOk, FcHighPriority } from "react-icons/fc";
import { useUpdateSkuPrice2Mutation, useFetchSkuAttributesQuery } from "../../store";
import { ImCancelCircle, ImCheckmark, ImPencil } from "react-icons/im";

function EditForm({ target, char, sku, asin, productType, requestType }) {
    const [updateSkuPrice, response] = useUpdateSkuPrice2Mutation()
    const accountId = useSelector(state => state.accounts.accountId);
    //console.log(accountId, "-", sku)
    const { data } = useFetchSkuAttributesQuery({ sku, accountId })

    const [formValue, setFormValue] = useState(target)
    const [startDate, setFormValue2] = useState()
    const [endDate, setFormValue3] = useState()
    const [showError, setShowError] = useState(false)

    const [submittable, setSubmittable] = useState(false);
    const [submittable2, setSubmittable2] = useState(false);
    const [submittable3, setSubmittable3] = useState(false);
    const [updateBidContentVisibility, setUpdateBidContentVisibility] = useState('edit-button');

    useEffect(() => {
        if (target) {
            setFormValue(target)
            setUpdateBidContentVisibility('edit-button')
        }
    }, [target])

    const classname = 'w-[6vw] border-r border-t border-b rounded-r-xl mr-1 px-2 p-1 text-center focus:outline-0';
    const [inputClass, setInputClass] = useState(classname);

    const classname2 = 'flex justify-center bg-gray-100 border-l border-t border-b rounded-l-xl px-1 items-center w-[2vw]';
    const [inputClass2, setInputClass2] = useState(classname2);

    const onErrorClick = () => {
        console.log("error clicked")
        setShowError(!showError)
    }

    const onApproveClick = () => {
        console.log(startDate, "-", endDate, "-", submittable2, submittable3)
        if (submittable && submittable2 && submittable3) {
            //create an api to update the existing target acos
            updateSkuPrice({ accountId, formValue, sku, asin, productType, requestType, startDate, endDate });
            setInputClass(classname);
            setInputClass2(classname2);
            setUpdateBidContentVisibility('edit-button');
        } else {
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
        }
    };

    const onCancelClick = () => {
        setInputClass(classname);
        setInputClass2(classname2);
        setUpdateBidContentVisibility('edit-button');
        setFormValue(target);
    };

    const onEditClick = () => {
        setUpdateBidContentVisibility('form');
    };

    const onChange = (event) => {
        const value = event.target.value;

        if (!value || value === '0' || value.slice(value.length - 1) === '.' || value < 10 || value > 9999) {
            //setDisabled(true);
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
            setSubmittable(false);
            //make input border red
        } else if (!isNaN(value) && value.length < 7) {
            //setDisabled(false);
            setInputClass((classname + ' border-green-600'));
            setInputClass2((classname2 + ' border-green-600'));
            setSubmittable(true);
            //make input border gray again
        }
        if (!isNaN(value) && value.length < 7) {
            setFormValue(value);
        }
    };

    const onChange2 = (event) => {
        const value = event.target.value;

        if (!value || value.length < 10) {
            //setDisabled(true);
            setSubmittable2(false);
            //make input border red
        } else if (value.length === 10 && value.slice(4, 5) === '-' && value.slice(7, 8) === '-') {
            //setDisabled(false);
            setSubmittable2(true);
            //make input border gray again
        }
        if (value.length < 11) {
            setFormValue2(value);
        }
    };

    const onChange3 = (event) => {
        const value = event.target.value;

        if (!value || value.length < 10) {
            //setDisabled(true);
            setSubmittable3(false);
            //make input border red
        } else if (value.length === 10 && value.slice(4, 5) === '-' && value.slice(7, 8) === '-') {
            //setDisabled(false);
            setSubmittable3(true);
            //make input border gray again
        }
        if (value.length < 11) {
            setFormValue3(value);
        }
    };

    let saleStart, saleEnd, salePrice
    let saleDateClass = "flex items-center justify-center drop-shadow w-[5vw] h-3 ml-1 border rounded-xl bg-white px-2 p-1 mb-1 text-gray-300"
    let salePriceClass = "flex items-center justify-center w-[8vw] h-7 drop-shadow mr-1 border rounded-xl bg-white px-2 p-1 text-gray-300"
    if (data) {
        //console.log(data)
        if (data.purchasable_offer && data.purchasable_offer[0]) {
            const offer = data.purchasable_offer[0]
            if (offer.discounted_price && offer.discounted_price[0]) {
                saleStart = offer.discounted_price[0].schedule[0].start_at.split("T")[0]
                saleEnd = offer.discounted_price[0].schedule[0].end_at.split("T")[0]
                salePrice = offer.discounted_price[0].schedule[0].value_with_tax
                saleDateClass = saleDateClass + " text-gray-900"
                salePriceClass = salePriceClass + " text-gray-900"
            }
        }
        console.log(saleStart, "-", saleEnd, "-", salePrice)

    }

    return (
        <div>
            <div className="flex flex-row h-7">
                {updateBidContentVisibility === 'edit-button'
                    ? <div className="flex flex-row">
                        <div className={salePriceClass}>
                            <div >
                                {char === '%' ? (formValue || salePrice || "") + "" + char : (formValue && char + "" + formValue) || (salePrice && char + "" + salePrice) || "Sale Price"}
                            </div>
                        </div>
                        <div className="text-4xs">
                            <div className={saleDateClass}>
                                <div >
                                    {startDate || saleStart || "Start Date"}
                                </div>
                            </div>
                            <div className={saleDateClass}>
                                <div >
                                    {endDate || saleEnd || "End Date"}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="flex flex-row h-7">
                        <div className="flex flex-row drop-shadow">
                            <div className={inputClass2}>
                                {char}
                            </div>
                            <input
                                type="number"
                                className={inputClass}
                                value={formValue}
                                placeholder="Sale Price"
                                onChange={onChange} >
                            </input>
                        </div>
                        <div className="text-4xs">
                            <input
                                className="flex items-center justify-center drop-shadow w-[5vw] ml-1 h-3 border rounded-xl bg-white px-2 p-1 focus:outline-0"
                                value={startDate}
                                placeholder="Start Date"
                                onChange={onChange2} >
                            </input>
                            <input
                                className="flex items-center justify-center drop-shadow w-[5vw] ml-1 h-3 mt-1 border rounded-xl bg-white px-2 p-1 focus:outline-0"
                                value={endDate}
                                placeholder="End Date"
                                onChange={onChange3} >
                            </input>
                        </div>

                    </div>
                }
                <div className="flex items-center ml-1">
                    <button
                        onClick={onEditClick}
                        className='mx-1'
                        hidden={updateBidContentVisibility !== 'edit-button'}>
                        <ImPencil />
                    </button>
                    <button
                        onClick={onApproveClick}
                        className='mx-1'
                        hidden={updateBidContentVisibility !== 'form'}>
                        <ImCheckmark />
                    </button>
                    <button
                        onClick={onCancelClick}
                        className='mx-1'
                        hidden={updateBidContentVisibility !== 'form'}>
                        <ImCancelCircle />
                    </button>
                    {
                        response.isLoading
                            ? <div className="flex items-center ml-1"><GoSync className="animate-spin" /></div>
                            :
                            (response.isSuccess && response.data && response.data.status === "ACCEPTED")
                                ? <div className="flex items-center ml-1"><FcOk /></div>
                                : (response.isError || (response.data && (response.data.errors || response.data.status === "INVALID")))
                                    ? <div title={(response.data.errors && response.data.errors[0].message) || (response.data.issues && response.data.issues[0].message)} className="flex items-center ml-1 cursor-help" onClick={onErrorClick}><FcHighPriority /></div>
                                    : null
                    }
                </div>
            </div>
        </div >
    )
}

export default EditForm