import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useFetchParentsQuery } from "../../store"
import Dropdown from "../Dropdown";

function ParentDropdown({ parent, setParent }) {
    const accountId = useSelector(state => state.accounts.accountId);
    const { data, isFetching } = useFetchParentsQuery({ accountId })
    const [parentName, setParentName] = useState();

    const onChange = (item, id) => {
        setParentName(item);
        setParent(id)
    };

    useEffect(() => {
        setParentName(null)
    }, [accountId])

    let parentDropdown;

    if (isFetching) {
        parentDropdown = <Dropdown options={[]}
            onChange={onChange} className="w-64 text-sm"
            value={parentName} defaultText="Loading..." />
    } else if (data) {
        const dropdownOptions = data
            .map(parent => {
                return {
                    label: parent.customParentName || parent.skus[0].seller_sku,
                    value: parent.asin,
                    priority: parent.priority,
                    isDisplayed: parent.isDisplayed
                }
            });

        let sortedDropdownOptions = [...dropdownOptions].filter(option => option.isDisplayed).sort((a, b) => {
            const valueA = a.label;
            const valueB = b.label;
            return valueA.localeCompare(valueB);
        });

        sortedDropdownOptions.sort(function (a, b) {
            return a.priority - b.priority
        })

        sortedDropdownOptions.unshift({
            label: "ALL LISTINGS",
            value: "all",
            priority: 1,
            isDisplayed: true
        })

        parentDropdown = <Dropdown options={sortedDropdownOptions} id={parent}
            onChange={onChange} className="w-64 text-sm"
            value={parentName} defaultText="Select Parent" />
    }


    return (
        <div className="flex flex-row h-12">
            <div className="mx-3">{parentDropdown}</div>
        </div>
    );
}

export default ParentDropdown;