import { useFetchParentsQuery } from "../../store"
import SalesTrafficTableParent from "./SalesTrafficTableParent"
import Skeleton from '../Skeleton';
import SalesRank from "./SalesRank"

function SalesTrafficList({ accountId, isWeekly }) {
    const { data, isFetching } = useFetchParentsQuery({ accountId, isDisplayedOnly: true })
    let dateArray = [], dateStrArray = [], dateStartArray = [], dateEndArray = [];
    let finalDiv = <div className="flex flex-row p-3 pb-6 mx-3" >No Info</div>
    if (isFetching) {
        finalDiv = <div className="flex flex-col p-3 pb-6 mx-3" ><Skeleton times={3} className="h-10 w-full" /></div>
    } else if (data) {
        if (isWeekly) {
            const weeksToDisplay = 8
            const dateNow = new Date();
            //-7 saat PST ile eşitlemek için
            const offset = new Date().getTimezoneOffset() - (7 * 60)
            const daysSinceLastWeek = new Date(dateNow.getTime() + (offset * 60 * 1000)).getDay();
            //console.log(daysSinceLastWeek)
            for (let i = 0; i < weeksToDisplay; i++) {
                let start = new Date();
                let end = new Date();

                if (daysSinceLastWeek > 2) {
                    if (i === 0) {
                        //console.log("offset ", offset)
                        start.setDate(dateNow.getDate() - (daysSinceLastWeek));
                        end.setDate(dateNow.getDate() - (2));
                        start = new Date(start.getTime() + (offset * 60 * 1000))
                        end = new Date(end.getTime() + (offset * 60 * 1000))
                        dateStartArray[i] = start.toISOString().split('T')[0];
                        dateEndArray[i] = end.toISOString().split('T')[0];
                        dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                        //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                        dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
                            + " - "
                            + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
                    } else {
                        //console.log("offset ", offset)
                        start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * (i - 1)));
                        end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * (i - 1)));
                        start = new Date(start.getTime() + (offset * 60 * 1000))
                        end = new Date(end.getTime() + (offset * 60 * 1000))
                        dateStartArray[i] = start.toISOString().split('T')[0];
                        dateEndArray[i] = end.toISOString().split('T')[0];
                        dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                        //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                        dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
                            + " - "
                            + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
                    }
                } else {
                    start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * (i)));
                    end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * (i)));
                    start = new Date(start.getTime() + (offset * 60 * 1000))
                    end = new Date(end.getTime() + (offset * 60 * 1000))
                    dateStartArray[i] = start.toISOString().split('T')[0];
                    dateEndArray[i] = end.toISOString().split('T')[0];
                    dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                    //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                    dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
                        + " - "
                        + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
                }
            }
        } else {
            const monthsToDisplay = 6
            const dateNow = new Date();
            const monthNow = dateNow.getMonth()
            const dayNow = dateNow.getDate()
            const yearNow = dateNow.getFullYear()
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let lastDayofMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if ((yearNow % 4 === 0) || ((yearNow % 4 === 1) && (monthNow === 1))) {
                lastDayofMonth[1] = 29
            }
            let monthLength = []

            let lastYearIndex
            console.log("Day: ", dayNow, " ,Month: ", monthNow, " ", months[monthNow], " ,Year: ", yearNow)
            for (let i = 0; i < monthsToDisplay; i++) {
                let start = new Date();
                let end = new Date();
                const month = ((monthNow - i) % 12 + 12) % 12

                //SET FULL YEAR --- DESIGN
                if (lastYearIndex) {
                    start.setFullYear(yearNow - ((((i + 1) - lastYearIndex) / 12)))
                    end.setFullYear(yearNow - ((((i + 1) - lastYearIndex) / 12)))
                }
                if (i > monthNow && !lastYearIndex) {
                    lastYearIndex = i
                    start.setFullYear(yearNow - 1)
                    end.setFullYear(yearNow - 1)
                }

                start.setDate(1);
                end.setDate(1);
                start.setMonth(month)
                end.setMonth(month)
                if (i === 0) {
                    console.log("end date: ", dayNow)
                    if (dayNow > 2)
                        end.setDate(dayNow - 2);
                }
                else {
                    end.setDate(lastDayofMonth[month]);
                }
                start = new Date(start.getTime())
                end = new Date(end.getTime())
                monthLength[i] = end.getDate()
                dateStartArray[i] = start.toISOString().split('T')[0];
                dateEndArray[i] = end.toISOString().split('T')[0];
                dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " "
                    + start.getDate()
                    + " - "
                    + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
            }
        }

        let columnCount = dateArray.length

        let parentsDiv = []
        //map from parent array
        const sortedData = [...data]
        sortedData.sort((a, b) => (a.priority || 4) - (b.priority || 4))
        parentsDiv = sortedData.map((parent, index) => {
            let infoDiv = <div key={-1} className="sticky left-0 bg-white z-10" >
                <div className="w-56 mx-3 ml-7">
                    <div className="flex flex-col items-center">
                        <div className="w-fit">
                            <a href={"https://www.amazon.com/dp/" + parent.asin} rel='noreferrer' target="_blank">
                                <div className="text-xs w-[12vw] max-h-[24vh] rounded-xl overflow-hidden"><img src={parent.main_image} alt="main_image" className="rounded-xl shadow-lg" /></div>
                            </a>
                        </div>
                    </div>
                    <div className="text-center mt-3 shadow rounded-xl p-2 border">
                        <div className="font-bold text-yellow-500">{parent.customParentName || parent.skus[0].seller_sku}</div>
                        <div>{parent.asin}</div>
                    </div>
                    <div className="mt-3 text-xxs text-gray-500">Sales Rank</div>
                    {parent.salesRanks && <SalesRank salesRanks={parent.salesRanks}></SalesRank>}
                    <div className="mt-3 text-xxs text-gray-500">Brand</div>
                    <div className="text-xs">{parent.brand}</div>
                </div>
            </div>

            let tableDiv = []
            tableDiv.push(infoDiv)
            for (let i = 0; i < columnCount; i++) {
                tableDiv.push(<div className="mx-3 py-1" key={i}>
                    <SalesTrafficTableParent date={dateArray[i]} dateStr={dateStrArray[i]} accountId={accountId} parent={parent}></SalesTrafficTableParent>
                </div>)
            }
            return <div className="flex flex-row pt-3 pb-6" key={index}>{tableDiv}</div>
        })
        //map end

        /*
        parentsDiv = data.map((parent, index) => {
            return <div className="flex flex-row p-3 pb-6 m-3 bg-sky-50 rounded-xl" key={index}>{parent.asin} - {parent.skus[0].seller_sku}</div>
        })
        */

        finalDiv = <div>{parentsDiv}</div>
    }
    return finalDiv
}

export default SalesTrafficList