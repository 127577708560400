export default function SalesRank({ salesRanks }) {
    const rankClass = "text-xs font-bold"
    const salesRanksDiv = salesRanks.map(salesRank => {
        const classificationRanksDiv = salesRank.classificationRanks.map(rank => {
            return <div className="flex items-end">
                <div className={rankClass}>{rank.rank}</div>
                <div className="mx-1">in</div>
                <a href={rank.link} rel='noreferrer' target="_blank" className="text-sky-700 underline" >{rank.title}</a>
            </div>
        })
        const displayGroupRanksDiv = salesRank.displayGroupRanks.map(rank => {
            return <div className="flex items-end">
                <div className={rankClass}>{rank.rank}</div>
                <div className="mx-1">in</div>
                <a href={rank.link} rel='noreferrer' target="_blank" className="text-sky-700 underline">{rank.title}</a>
            </div>
        })

        return (
            <div className="text-xxs">
                <div>{classificationRanksDiv}</div>
                <div>{displayGroupRanksDiv}</div>
            </div>
        )
    })

    return (
        <div>
            {salesRanksDiv}
        </div>
    )
}