import { useState } from "react"
//import { RiRefreshFill } from "react-icons/ri";

export default function NGForm({ onSubmit1, guesses, answers, key1, setKey }) {
    const [number, setNumber] = useState("")
    const [className, setClassName] = useState("")
    const [numbers, setNumbers] = useState([])
    const [hidden, setHidden] = useState(true)
    let className1 = "border w-36 text-xl rounded-xl px-2 text-center tracking-[0.2em]"
    const onChange = (event) => {
        if (!isNaN(event.target.value) && event.target.value.length < 5) {
            setNumber(event.target.value)
            setNumbers([event.target.value.charAt(0), event.target.value.charAt(1), event.target.value.charAt(2), event.target.value.charAt(3)])
        }
    }
    const onClick = () => {
        setHidden(true)
        setKey(key1 + 1)
    }
    //console.log("1: ", number1, ", 2: ", number2, ", 3: ", number3, ", 4: ", number4)
    //console.log(numbers)
    const onSubmit = (event) => {
        event.preventDefault()
        const len = number.length === 4
        const num = !isNaN(number)
        if (len && num) {
            if (numbers[0] !== numbers[1] && numbers[0] !== numbers[2] && numbers[0] !== numbers[3] && numbers[1] !== numbers[2] && numbers[1] !== numbers[3] && numbers[2] !== numbers[3]) {
                let plus = 0
                let minus = 0
                let result = ""
                for (let i = 0; i < numbers.length; i++) {
                    if (numbers[i] === answers[i]) {
                        plus++
                    } else if (answers.find(answer => { return numbers[i] === answer })) {
                        minus++
                    }
                }
                for (let i = 0; i < plus; i++) {
                    result = result + "+"
                }
                for (let i = 0; i < minus; i++) {
                    result = result + "-"
                }


                if (result === "++++") {
                    setClassName(" bg-green-600 rounded-xl text-white p-1")
                    console.log(result)
                    setHidden(false)
                    onSubmit1({ number, result })
                    setNumber("")
                } else {
                    setClassName("")
                    onSubmit1({ number, result })
                    setNumber("")
                }
            }
        }
    }

    const guessesDiv = guesses.map((guess, index) => {
        if (index === guesses.length - 1) {
            return <div key={index} className={"p-1" + className}>{guess.number + "  " + guess.result}</div>
        }
        else return <div key={index} className="p-1">{guess.number + "  " + guess.result}</div>
    })

    return <div className="justify-items-center mt-10">
        <div onClick={onClick}
            className="absolute top-24 right-8 items-center m-3 hover:cursor-pointer bg-white text-green-800 font-bold text-xs w-fit shadow rounded-xl border p-2">
            New Game
        </div>
        <div className="p-1 ">
            <form onSubmit={onSubmit}>
                <input className={className1} value={number} disabled={!hidden} onChange={onChange}></input>
            </form>
        </div>
        <div className="flex flex-col-reverse m-3 items-center w-64 max-h-96 px-10 overflow-scroll">
            <div className="">{guessesDiv}</div>
        </div>
        {hidden ? null
            : < div hidden={true} onClick={onClick}
                className="flex flex-row items-center mt-8 hover:cursor-pointer text-green-800 font-bold text-xs w-fit shadow rounded-xl border p-2">
                New Game
            </div>

        }
    </div>
}
