import { useState } from "react"
import NGForm from "./NumberGuessForm"
export default function NumberGuess() {
    const [key, setKey] = useState(0)
    let guesses = []
    let answer, answers
    do {
        answer = "" + Math.floor((Math.random() * 9000 + 1000))
        answers = [answer.charAt(0), answer.charAt(1), answer.charAt(2), answer.charAt(3)]
    } while ((answers[0] === answers[1] || answers[0] === answers[2] || answers[0] === answers[3] || answers[1] === answers[2] || answers[1] === answers[3] || answers[2] === answers[3]))
    console.log("answer: ", answer)
    const onSubmit = ({ number, result }) => {
        if (result === "++++") {
            guesses.push({ number, result, win: "Congrats! Correct guess!" })
            //guesses.push(<button onClick={onClick}></button>)
        } else guesses.push({ number, result })
    }

    console.log("key: ", key)
    return <div>
        <div className="">
            <div className="p-2 m-3 shadow rounded-xl border text-center">Number Guess Game</div>
            <div className="text-xxs absolute">
                <div>Rules</div>
                <div>Guess 4-digit number. Digits do not repeat.</div>
                <div>For each correct number in the correct place you get a "+"</div>
                <div>For each correct number in the wrong place you get a "-"</div>
                <div>Ex: target number: 1234, your guess 1293, result: ++-</div>
            </div>
            <div className="p-2 m-3">
                <NGForm key={key} onSubmit1={onSubmit} guesses={guesses} answers={answers} key1={key} setKey={setKey}></NGForm>
            </div>
        </div>
    </div>
}