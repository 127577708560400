import { useState } from 'react'
import { useSelector } from 'react-redux'
import DataDiv from './DataDiv'
import CampaignDiv from './CampaignDiv'

export default function CampaignX({ data, onSortClick, refetch, isFetching, pickedDate }) {
    const accountId = useSelector(state => state.accounts.accountId);
    const [selectedFlatRows, setSelectedFlatRows] = useState([])

    let tableDiv = data.map((campaign, index) => {
        let divClass = 'flex flex-row p-1 bg-sky-50 shadow rounded-xl m-1 my-3'
        if (index % 2 === 0) {
            divClass = 'flex flex-row p-1 bg-slate-100 shadow rounded-xl m-1 my-3'
        }
        return (
            <div className={divClass} key={campaign.campaignId}>
                <div key={"targetDiv"} className='flex flex-row p-2 border w-screen3/5 rounded-xl'>
                    <CampaignDiv campaign={campaign} selectedFlatRows={selectedFlatRows}
                        setSelectedFlatRows={setSelectedFlatRows} key={campaign.campaignId} />
                </div>
                <DataDiv campaignId={campaign.campaignId} campaignType={campaign.campaignType}
                    accountId={accountId} onSortClick={onSortClick} pickedDate={pickedDate} camp={campaign}
                />
            </div>
        )
    })
    return <div>

        <div className="overflow-scroll max-h-screen70 rounded-xl shadow">
            {tableDiv}
        </div>
    </div>
}