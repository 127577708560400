import { useFetchListingInfoQuery } from "../../../store"
export default function AdDiv({ accountId, asin, sku }) {
    const { data } = useFetchListingInfoQuery({ accountId, asin })

    let adDiv = <div>No Data</div>
    if (data) {
        adDiv = <div className="flex flex-row">
            <div className="w-16 h-12 rounded-md overflow-hidden">
                <img src={data.main_image} alt="main_image" />
            </div>
            <div className="mx-3 w-24">{asin}</div>
            <div className="w-36 words-break">{sku}</div>
            <div className="mx-3">{data.item_name}</div>
        </div>
    }

    return adDiv
}