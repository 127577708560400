import { useEffect, useState } from "react";
import { useUpdateCampaignPlacementMutation } from "../../store";
import { ImCancelCircle, ImCheckmark, ImPencil } from "react-icons/im";

export default function PlacementForm({ accountId, campaignId, defaultValue, label, campaignType, }) {
    const classname = 'border text-end w-[2vw] px-2 rounded-xl focus:outline-0';
    const [inputClass, setInputClass] = useState(classname + " bg-white");
    const [submittable, setSubmittable] = useState(true);
    const [value, setValue] = useState(defaultValue)
    const [showForm, setShowForm] = useState(false)
    const [updatePlacement, res] = useUpdateCampaignPlacementMutation()

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (res.data) {
            console.log(res.data)
            if (res.data.campaigns && res.data.campaigns.success.length > 0) setInputClass(classname + " bg-green-500 text-white")
            else if (res.data.campaigns && res.data.campaigns.error.length > 0) setInputClass(classname + " bg-red-500 text-white")
            else if (res.data[0] && res.data[0].code === "SUCCESS") setInputClass(classname + " bg-green-500 text-white")
            else setInputClass(classname + " bg-red-500 text-white")
        }
    }, [res.data]);

    const onChange = (e) => {
        const value = e.target.value
        if (showForm) {
            if ((!value || value.slice(value.length - 1) === '.') && !isNaN(value) && (value.length < 3 || (value.length < 4 && value.substring(0, 1) === "0"))) {
                //setDisabled(true);
                setInputClass((classname + ' border-red-500'))
                setSubmittable(false)
                //make input border red
            } else if (!isNaN(value) && (value.length < 3 || value === "100")) {
                //setDisabled(false)
                setInputClass((classname + ' border-green-600'))
                setSubmittable(true)
                //make input border gray again
            }
            if (!isNaN(value) && (value.length < 3 || value === "100")) {
                if (value.substring(0, 1) === "0" && value.length > 1) {
                    setValue(value.substring(1))
                } else {
                    setValue(value)
                }
            }
        }
    }

    const onSubmitClick = () => {
        if (submittable) {
            console.log("Update Placement for ", label, ", value: ", Number(value))
            updatePlacement({ accountId, campaignId, newValue: Number(value), oldValue: defaultValue, campaignType, placementType: label })
            setShowForm(false)
        }
    }

    return <div className="flex flex-row text-3xs justify-center items-center mt-0.5">
        <div className="w-7">{label}</div>
        <div>
            <input min={0} max={100}
                className={inputClass} value={value} onChange={onChange}>
            </input>
        </div>
        <div className="ml-1 text-3xs w-6">
            {showForm ?
                <div>
                    <button onClick={onSubmitClick}><ImCheckmark></ImCheckmark> </button>
                    <button className="ml-1" onClick={() => setShowForm(false)}><ImCancelCircle></ImCancelCircle></button>
                </div> : <button onClick={() => setShowForm(true)}><ImPencil></ImPencil></button>}
        </div>
    </div>
}