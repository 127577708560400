import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const retrieveApi = createApi({
    reducerPath: 'retrieve',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchUser: builder.query({
                query: () => {
                    return {
                        url: '/api/user',
                        method: 'GET',
                    }
                }
            }),
            fetchUser2: builder.query({
                query: () => {
                    return {
                        url: '/api/user2',
                        method: 'GET',
                    }
                }
            }),
            fetchUsers: builder.query({
                query: () => {
                    return {
                        url: '/api/fetchUsers',
                        method: 'GET',
                    }
                }
            }),
            fetchProfiles: builder.query({
                query: () => {
                    return {
                        url: '/api/profiles',
                        method: 'GET',
                    }
                }
            }),
            fetchCampaigns: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/campaigns',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchSbCampaigns: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/campaignssb',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchSdCampaigns: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/campaignssd',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchCampaignsFromDatabase: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/campaignsdatabase',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchCampaignBudget: builder.query({
                query: ({ accountId, campaignId, campaignType }) => {
                    return {
                        url: '/api/campaignSpBudget',
                        method: 'GET',
                        params: {
                            accountId,
                            campaignId,
                            campaignType
                        },
                    }
                }
            }),
            fetchSbCampaignBudget: builder.query({
                query: ({ accountId, campaignId }) => {
                    return {
                        url: '/api/campaignSbBudget',
                        method: 'GET',
                        params: {
                            accountId,
                            campaignId
                        },
                    }
                }
            }),
            fetchSdCampaignBudget: builder.query({
                query: ({ accountId, campaignId }) => {
                    return {
                        url: '/api/campaignSdBudget',
                        method: 'GET',
                        params: {
                            accountId,
                            campaignId
                        },
                    }
                }
            }),
            fetchPortfolios: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/portfolios',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchQuote: builder.query({
                query: () => {
                    return {
                        url: '/api/getQuote',
                        method: 'GET',
                        params: {

                        },
                    }
                }
            }),
            fetchAdsAccounts: builder.query({
                query: () => {
                    return {
                        url: '/api/accounts/fetchAdsAccounts',
                        method: 'GET',
                        params: {

                        }
                    }
                }
            }),
            fetchListingInfo: builder.query({
                query: ({ accountId, asin }) => {
                    return {
                        url: "/api/listings/fetchListingInfo",
                        method: "GET",
                        params: {
                            accountId,
                            asin
                        }
                    }
                }
            })
        }
    }
});

export { retrieveApi };
export const { useFetchProfilesQuery, useFetchCampaignsQuery,
    useFetchSdCampaignsQuery, useFetchSbCampaignsQuery, useFetchUserQuery,
    useFetchPortfoliosQuery, useFetchQuoteQuery, useFetchCampaignBudgetQuery,
    useFetchSbCampaignBudgetQuery, useFetchSdCampaignBudgetQuery,
    useFetchUser2Query, useFetchUsersQuery, useFetchCampaignsFromDatabaseQuery,
    useFetchAdsAccountsQuery, useFetchListingInfoQuery
} = retrieveApi;