import { useState } from "react"
import { useCreateNoteMutation } from "../../../store"

export default function NewNote({ setShowModal, refetch, taskDefault, campaignId }) {
    //console.log(taskDefault)
    let descDefault = ""
    if (taskDefault) {
        descDefault = taskDefault.note
    }
    const [desc, setDesc] = useState(descDefault)
    let descClassName = "rounded-xl border px-2 py-1 my-2"
    const [descClass, setDescClass] = useState(descClassName)
    const [createNote] = useCreateNoteMutation()

    async function onChange() {
        //send form data to database
        console.log("Desc: ", desc)
        if (desc.length > 10) {
            await createNote({ desc, campaignId })
            refetch()
            setShowModal(false);
        } else if (desc.length < 11) {
            setDescClass(descClassName + " border-red-500")
            console.log("please provide a description")
        } else {
            console.log("bir hata var gibi")
        }
    };

    function onDescChange(e) {
        setDescClass(descClassName)
        setDesc(e.target.value)
    }

    function onCancel() {
        setShowModal(false);
    };

    return (
        <div>
            <div className="flex mb-5 font-bold justify-center">Add a new note</div>
            <div className="flex flex-col my-8">
                <div className="text-gray-500 text-xs">Notes</div>
                <textarea className={descClass} rows={15} defaultValue={descDefault} placeholder={"Provide a detailed explanation here (at least 10 characters)"} onChange={e => onDescChange(e)}></textarea>
            </div>
            <div className="flex justify-end">
                <button className="rounded-xl shadow p-2 text-white bg-red-600 mx-2" onClick={onCancel} >Cancel</button>
                <button className="rounded-xl shadow p-2 text-white bg-green-600" onClick={onChange} >Add Now</button>
            </div>

        </div>
    )
}